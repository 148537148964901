// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".offers_OffersModalBody__3NEh5 {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start; }\n\n.offers_OffersModalBodyRow__2iGs5 {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  grid-gap: 10px;\n  gap: 10px; }\n", "",{"version":3,"sources":["webpack://src/pages/forms/offer/OfferComponents/offers.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B,EAAA;;AAE7B;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,cAAS;EAAT,SAAS,EAAA","sourcesContent":[".OffersModalBody {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n}\n.OffersModalBodyRow {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OffersModalBody": "offers_OffersModalBody__3NEh5",
	"OffersModalBodyRow": "offers_OffersModalBodyRow__2iGs5"
};
export default ___CSS_LOADER_EXPORT___;

import React, { Fragment, useState } from "react";

import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import Select, { components } from "react-select";
import { useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  CustomInput,
  Input,
  Form,
} from "reactstrap";

import "eva-icons/style/eva-icons.css";

const AddBuildingSidebar = (props) => {
  const {
    store,
    dispatch,
    open,
    handleAddItem,
    refetchItems,
    addItem,
    selectItem,
    updateItem,
  } = props;

  const selectedItem = store.selectedBuilding;
  const { register, errors, handleSubmit } = useForm();

  const [name, setName] = useState("");
  const [facility_id, setFacilityID] = useState("");
  const [code, setCode] = useState("");

  const isObjEmpty = (obj) => Object.keys(obj).length === 0;

  const handleAddEvent = () => {
    const obj = {
      name,
      facility_id,
      code,
    };
    dispatch(addItem(obj));
    refetchItems();
    handleAddItem();
  };

  const handleSelectedEvent = () => {
    if (!isObjEmpty(selectedItem)) {
      setName(selectedItem.name || name);
      setFacilityID(selectedItem.facility_id || facility_id);
      setCode(selectedItem.code || code);
    }
  };

  const handleResetInputValues = () => {
    dispatch(selectItem({}));

    setName("");
    setFacilityID("");
  };

  const handleUpdateEvent = () => {
    const itemToUpdate = {
      id: selectedItem.id,
      name,
      facility_id,
      code,
    };

    dispatch(updateItem(itemToUpdate));
    handleAddItem();
  };

  const EventActions = () => {
    if (
      isObjEmpty(selectedItem) ||
      (!isObjEmpty(selectedItem) && !selectedItem.name.length)
    ) {
      return (
        <Fragment>
          <Button className="mr-3 btn-rounded" type="submit" color="primary">
            Add
          </Button>
          <Button
            className="btn-rounded"
            color="secondary"
            type="reset"
            onClick={handleAddItem}
            outline
          >
            Cancel
          </Button>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Button
            className="mr-3 btn-rounded"
            color="primary"
            onClick={handleUpdateEvent}
          >
            Update
          </Button>
          <Button
            className="btn-rounded"
            color="secondary"
            type="reset"
            onClick={handleAddItem}
            outline
          >
            Cancel
          </Button>
        </Fragment>
      );
    }
  };

  const CloseBtn = (
    <i className="eva eva-close cursor-pointer" onClick={handleAddItem} />
  );

  return (
    <Modal
      isOpen={open}
      toggle={handleAddItem}
      onOpened={handleSelectedEvent}
      onClosed={handleResetInputValues}
      className="sidebar-lg"
      contentClassName="p-0"
      modalClassName="modal-slide-in event-sidebar"
    >
      <ModalHeader
        className="mb-1"
        toggle={handleAddItem}
        close={CloseBtn}
        tag="div"
      >
        <h5 className="modal-title">
          {selectedItem && selectedItem.title && selectedItem.title.length
            ? "Update"
            : "Add"}{" "}
          Building
        </h5>
      </ModalHeader>
      <ModalBody className="flex-grow-1 pb-sm-0 pb-3">
        <Form
          onSubmit={handleSubmit((data) => {
            if (isObjEmpty(errors)) {
              if (
                isObjEmpty(selectedItem) ||
                (!isObjEmpty(selectedItem) && !selectedItem.title.length)
              ) {
                handleAddEvent();
              } else {
                handleUpdateEvent();
              }
              handleAddItem();
            }
          })}
        >
          <FormGroup>
            <Label for="name">
              Name <span className="text-danger">*</span>
            </Label>
            <Input
              id="name"
              name="name"
              placeholder="City Hall"
              value={name}
              onChange={(e) => setName(e.target.value)}
              // innerRef={register({ register: true, validate: value => value !== '' })}
              className={classnames({
                "is-invalid": errors.title,
                // add extended bootstrap class for show invalid field value
              })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="code">
              Code <span className="text-danger">*</span>
            </Label>
            <Input
              id="code"
              name="code"
              placeholder="000test"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              // innerRef={register({ register: true, validate: value => value !== '' })}
              className={classnames({
                "is-invalid": errors.code,
                // add extended bootstrap class for show invalid field value
              })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="facility_id">
              Facility ID <span className="text-danger">*</span>
            </Label>
            <Input
              id="facility_id"
              name="facility_id"
              placeholder="18"
              value={facility_id}
              onChange={(e) => setFacilityID(e.target.value)}
              // innerRef={register({ register: true, validate: value => value !== '' })}
              className={classnames({
                "is-invalid": errors.facility_id,
                // add extended bootstrap class for show invalid field value
              })}
            />
          </FormGroup>
          <FormGroup className="d-flex">
            <EventActions />
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default AddBuildingSidebar;

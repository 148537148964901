import axios from "axios";

export const fetchBuildings = () => {
  return (dispatch) => {
    axios.get("/buildings").then((response) => {
      dispatch({
        type: "FETCH_BUILDINGS",
        buildings: response.data,
      });
    });
  };
};

export const addBuilding = (building) => {
  return (dispatch, getState) => {
    axios.post("/buildings/new", building).then(() => {
      dispatch({
        type: "ADD_BUILDING",
      });
      dispatch(fetchBuildings());
    });
  };
};

export const updateBuilding = (building) => {
  return (dispatch) => {
    axios.put("/buildings/" + building.id, building).then(() => {
      dispatch({
        type: "UPDATE_BUILDING",
      });
      dispatch(fetchBuildings());
    });
  };
};

export const removeBuilding = (id) => {
  return (dispatch) => {
    axios.delete("/buildings/" + id).then(() => {
      dispatch({
        type: "REMOVE_BUILDING",
      });
      dispatch(fetchBuildings());
    });
  };
};

export const selectBuilding = (building) => {
  return (dispatch) => {
    dispatch({
      type: "SELECT_BUILDING",
      building,
    });
  };
};

import React, { Fragment, useState } from "react";

import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import Select, { components } from "react-select";
import { useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  CustomInput,
  Input,
  Form,
} from "reactstrap";

import "eva-icons/style/eva-icons.css";

const AddEventSidebar = (props) => {
  const {
    store,
    dispatch,
    open,
    handleAddItem,
    refetchItems,
    addItem,
    selectFacility,
    updateItem,
  } = props;

  const selectedItem = store.selectedUnit;
  const { register, errors, handleSubmit } = useForm();

  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [group_id, setGroupID] = useState("");
  const [ref_power, setRefPower] = useState("");
  const [open_command, setOpenCommand] = useState("");
  const [close_command, setCloseCommand] = useState("");

  const isObjEmpty = (obj) => Object.keys(obj).length === 0;

  const handleAddEvent = () => {
    const obj = {
      name,
      code,
      group_id,
      ref_power,
      open_command,
      close_command,
    };
    dispatch(addItem(obj));
    refetchItems();
    handleAddItem();
  };

  const handleSelectedEvent = () => {
    if (!isObjEmpty(selectedItem)) {
      setName(selectedItem.name || name);
      setCode(selectedItem.code || code);
      setGroupID(selectedItem.group_id || group_id);
      setRefPower(selectedItem.ref_power || ref_power);
      setOpenCommand(selectedItem.open_command || open_command);
      setCloseCommand(selectedItem.close_command || close_command);
    }
  };

  const handleResetInputValues = () => {
    dispatch(selectFacility({}));

    setName("");
    setCode("");
    setGroupID("");
    setRefPower("");
    setOpenCommand(null);
    setCloseCommand(null);
  };

  const handleUpdateEvent = () => {
    const facilityToUpdate = {
      id: selectedItem.id,
      name,
      code,
      group_id,
      ref_power,
      open_command: JSON.parse(open_command),
      close_command: JSON.parse(close_command),
    };

    dispatch(updateItem(facilityToUpdate));
    handleAddItem();
  };

  const EventActions = () => {
    if (
      isObjEmpty(selectedItem) ||
      (!isObjEmpty(selectedItem) && !selectedItem.name.length)
    ) {
      return (
        <Fragment>
          <Button className="mr-3 btn-rounded" type="submit" color="primary">
            Add
          </Button>
          <Button
            className="btn-rounded"
            color="secondary"
            type="reset"
            onClick={handleAddItem}
            outline
          >
            Cancel
          </Button>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Button
            className="mr-3 btn-rounded"
            color="primary"
            onClick={handleUpdateEvent}
          >
            Update
          </Button>
          <Button
            className="btn-rounded"
            color="secondary"
            type="reset"
            onClick={handleAddItem}
            outline
          >
            Cancel
          </Button>
        </Fragment>
      );
    }
  };

  const CloseBtn = (
    <i className="eva eva-close cursor-pointer" onClick={handleAddItem} />
  );

  return (
    <Modal
      isOpen={open}
      toggle={handleAddItem}
      onOpened={handleSelectedEvent}
      onClosed={handleResetInputValues}
      className="sidebar-lg"
      contentClassName="p-0"
      modalClassName="modal-slide-in event-sidebar"
    >
      <ModalHeader
        className="mb-1"
        toggle={handleAddItem}
        close={CloseBtn}
        tag="div"
      >
        <h5 className="modal-title">
          {selectedItem && selectedItem.title && selectedItem.title.length
            ? "Update"
            : "Add"}{" "}
          Unit
        </h5>
      </ModalHeader>
      <ModalBody className="flex-grow-1 pb-sm-0 pb-3">
        <Form
          onSubmit={handleSubmit((data) => {
            if (isObjEmpty(errors)) {
              if (
                isObjEmpty(selectedItem) ||
                (!isObjEmpty(selectedItem) && !selectedItem.title.length)
              ) {
                handleAddEvent();
              } else {
                handleUpdateEvent();
              }
              handleAddItem();
            }
          })}
        >
          <FormGroup>
            <Label for="name">
              Name <span className="text-danger">*</span>
            </Label>
            <Input
              id="name"
              name="name"
              placeholder="City Hall"
              value={name}
              onChange={(e) => setName(e.target.value)}
              innerRef={register({
                register: true,
                validate: (value) => value !== "",
              })}
              className={classnames({
                "is-invalid": errors.name,
                // add extended bootstrap class for show invalid field value
              })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="code">
              Code <span className="text-danger">*</span>
            </Label>
            <Input
              id="code"
              name="code"
              placeholder="007"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              innerRef={register({
                register: true,
                validate: (value) => value !== "",
              })}
              className={classnames({
                "is-invalid": errors.code,
                // add extended bootstrap class for show invalid field value
              })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="group_id">
              Group ID <span className="text-danger">*</span>
            </Label>
            <Input
              id="group_id"
              name="group_id"
              placeholder="18"
              value={group_id}
              onChange={(e) => setGroupID(e.target.value)}
              innerRef={register({
                register: true,
                validate: (value) => value !== "",
              })}
              className={classnames({
                "is-invalid": errors.group_id,
                // add extended bootstrap class for show invalid field value
              })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="ref_power">
              Ref Power <span className="text-danger">*</span>
            </Label>
            <Input
              id="ref_power"
              name="ref_power"
              placeholder="100"
              value={ref_power}
              onChange={(e) => setRefPower(e.target.value)}
              innerRef={register({
                register: true,
                validate: (value) => value !== "",
              })}
              className={classnames({
                "is-invalid": errors.ref_power,
                // add extended bootstrap class for show invalid field value
              })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="open_command">
              Open Command <span className="text-danger">*</span>
            </Label>
            <Input
              id="open_command"
              name="open_command"
              placeholder="100"
              value={open_command}
              onChange={(e) => setOpenCommand(e.target.value)}
              innerRef={register({
                register: true,
                validate: (value) => value !== "",
              })}
              className={classnames({
                "is-invalid": errors.open_command,
                // add extended bootstrap class for show invalid field value
              })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="close_command">
              Close Command <span className="text-danger">*</span>
            </Label>
            <Input
              id="close_command"
              name="close_command"
              placeholder="100"
              value={close_command}
              onChange={(e) => setCloseCommand(e.target.value)}
              innerRef={register({
                register: true,
                validate: (value) => value !== "",
              })}
              className={classnames({
                "is-invalid": errors.close_command,
                // add extended bootstrap class for show invalid field value
              })}
            />
          </FormGroup>
          <FormGroup className="d-flex">
            <EventActions />
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default AddEventSidebar;

// ** Initial State
const initialState = {
  buildings: [],
  selectedBuilding: {},
};

const building = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_BUILDINGS":
      return { ...state, buildings: action.buildings };
    case "ADD_BUILDING":
      return { ...state };
    case "REMOVE_BUILDING":
      return { ...state };
    case "UPDATE_BUILDING":
      return { ...state };
    case "SELECT_BUILDING":
      return { ...state, selectedBuilding: action.building };
    default:
      return state;
  }
};

export default building;

import React, { useState, useEffect } from "react";
import { Table, Button, Input } from "reactstrap";
import axios from "axios";
import moment from "moment";
import styles from "./MonitoringComponents.module.scss";

const Timeline = () => {
  const [timelineData, setTimelineData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [inputPage, setInputPage] = useState(1);
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(1);
  const totalPages = 20;

  function maxPage(totalNumberOfRows) {
    if (totalNumberOfRows > 10) {
      return Math.ceil(totalNumberOfRows / 10);
    }

    return 1;
  }

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      setInputPage(newPage);
    }
  };

  const handlePreviousPage = () => handlePageChange(currentPage - 1);

  const handleNextPage = () => handlePageChange(currentPage + 1);

  const handlePageInput = (e) => {
    if (e.key === "Enter" || e.type === "click") {
      handlePageChange(inputPage);
    }
  };

  // Get 200 rows, 10 at a time
  async function getActions(currentPage) {
    const response = await axios.get("/actions/getActions", {
      params: { page: currentPage },
    });
    if (response.status === 200) {
      setTotalNumberOfRows(response.data.count);
      setTimelineData(response.data.rows);
    }
  }

  useEffect(() => {
    getActions(currentPage);
  }, [currentPage]);

  return (
    <div className={styles.TimelineContainer}>
      {timelineData.length > 0 ? (
        <>
          <Table striped>
            <thead>
              <tr>
                <th>User</th>
                <th>Action</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {timelineData.map((item, index) => (
                <tr key={index}>
                  <td>{item.username}</td>
                  <td className={styles.TextWrap}>{item.event}</td>
                  <td>
                    {moment(item.created_at).format(
                      "dddd, MMMM Do YYYY, h:mm:ss a"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div
            className="pagination-buttons"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
              gap: "10px",
            }}
          >
            <Button
              color="primary"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </Button>

            <Input
              type="number"
              value={inputPage}
              onChange={(e) =>
                setInputPage(
                  Math.max(
                    1,
                    Math.min(Number(e.target.value), maxPage(totalNumberOfRows))
                  )
                )
              } // Ensures input stays within bounds
              onKeyPress={handlePageInput}
              min="1"
              max={maxPage(totalNumberOfRows)}
              style={{
                width: "60px",
                margin: "0 10px",
                textAlign: "center",
              }}
            />
            <Button color="primary" onClick={handlePageInput}>
              Go
            </Button>

            <Button
              color="primary"
              onClick={handleNextPage}
              disabled={currentPage === maxPage(totalNumberOfRows)}
            >
              Next
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Timeline;

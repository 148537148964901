import React from "react";
import Widget from "../../components/Widget/Widget";
import PaymentNotice from "./components/InvoicesComponents/PaymentNotice";

function Invoices() {
  return (
    <div>
      <Widget title="Monthly Payment Notice">
        <PaymentNotice />
      </Widget>
    </div>
  );
}

export default Invoices;

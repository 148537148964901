export const batteryOptions = [
  {
    value: "The Pulse",
    label: "The Pulse",

    isFixed: true,
  },
  {
    value: "Asiagreen",
    label: "Asiagreen",
    isFixed: true,
  },
];
export const dischargeRateOptions = [
  { value: 90, label: 90 },
  { value: 100, label: 100 },
  { value: 110, label: 110 },
];
export const chargeBatteryDurationOptions = [
  {
    value: 10,
    label: 10,
  },
  {
    value: 20,
    label: 20,
  },
  {
    value: 30,
    label: 30,
  },
  {
    value: 40,
    label: 40,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 60,
    label: "60 (1 Hour)",
  },
  {
    value: 70,
    label: 70,
  },
  {
    value: 80,
    label: 80,
  },
  {
    value: 90,
    label: 90,
  },
  {
    value: 100,
    label: 100,
  },
  {
    value: 110,
    label: 110,
  },
  {
    value: 120,
    label: "120 (2 Hours)",
  },
  {
    value: 130,
    label: 130,
  },
  {
    value: 140,
    label: 140,
  },
  {
    value: 150,
    label: 150,
  },
  {
    value: 160,
    label: 160,
  },
  {
    value: 170,
    label: 170,
  },
  {
    value: 180,
    label: "180 (3 Hours)",
  },
  {
    value: 190,
    label: 190,
  },
  {
    value: 200,
    label: 200,
  },
  {
    value: 200,
    label: 200,
  },
  {
    value: 210,
    label: 210,
  },
  {
    value: 100,
    label: 100,
  },
  {
    value: 220,
    label: 220,
  },
  {
    value: 230,
    label: 230,
  },
  {
    value: 240,
    label: "240 (4 Hours)",
  },
  {
    value: 250,
    label: 250,
  },
  {
    value: 260,
    label: 260,
  },
  {
    value: 270,
    label: 270,
  },
  {
    value: 280,
    label: 280,
  },
  {
    value: 290,
    label: 290,
  },
  {
    value: 300,
    label: "300 (5 Hours)",
  },
  {
    value: 310,
    label: 310,
  },
  {
    value: 320,
    label: 320,
  },
  {
    value: 330,
    label: 330,
  },
  {
    value: 340,
    label: 340,
  },
  {
    value: 350,
    label: 350,
  },
  {
    value: 360,
    label: "360 (6 Hours)",
  },
];

import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import moment from "moment";
import { Tabs, Tab } from "@material-ui/core";
import styles from "./LookaheadComponents.module.scss";

const Lookahead = ({ date }) => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  async function get() {
    setLoadingData(true);
    const response = await axios.get("/lookahead/get", {
      params: { date: date }
    });
    setLoadingData(false);
    if (response.status === 200) {
      setData(response.data);
    }
  }

  useEffect(() => {
    get();
  }, [date]);

  const columns = [
    {
      name: "period",
      label: "Period",
      options: {
        filter: false,
        sortThirdClickReset: true,
        sortDirection: "desc",
      },
    },
    {
      name: "usep",
      label: "USEP LAR ($/MWh)",
      options: {
        customBodyRender: (value) => {
          const values = value.split(",");
          return (
            <div style={{ display: "flex", flexDirection: "row" }}>
              {values.map((val, index) => (
                <div
                  key={index}
                  style={{
                    padding: "5px",
                    minWidth: "70px",
                    backgroundColor: index % 2 === 0 ? "#e0e0e0" : "#f0f0f0",
                    textAlign: "center",
                  }}
                >
                  <div style={{ color: "#d0d0d0" }}>{index + 1}</div>
                  <div>{val}</div>
                </div>
              ))}
            </div>
          );
        },
      },
    },
    {
      name: "usep_dpr",
      label: "USEP DPR ($/MWh)",
      options: {
        customBodyRender: (value) => {
          return (
            <div style={{ display: "flex", flexDirection: "row" }}>
              {value}
            </div>
          );
        },
      },
    },
    {
      name: "conresp",
      label: "CONRES Price LAR ($/MWh)",
      options: {
        customBodyRender: (value) => {
          const values = value.split(",");
          return (
            <div style={{ display: "flex", flexDirection: "row" }}>
              {values.map((val, index) => (
                <div
                  key={index}
                  style={{
                    padding: "5px",
                    minWidth: "60px",
                    backgroundColor: index % 2 === 0 ? "#e0e0e0" : "#f0f0f0",
                    textAlign: "center",
                  }}
                >
                  <div style={{ color: "#d0d0d0" }}>{index + 1}</div>
                  <div>{val}</div>
                </div>
              ))}
            </div>
          );
        },
      },
    },
    {
      name: "conresp_dpr",
      label: "CONRES Price DPR ($/MWh)",
      options: {
        customBodyRender: (value) => {
          return (
            <div style={{ display: "flex", flexDirection: "row" }}>
              {value}
            </div>
          );
        },
      },
    },
    {
      name: "totalLoadMW",
      label: "Total Load LAR (MW)",
      options: {
        customBodyRender: (value) => {
          const values = value.split(",");
          return (
            <div style={{ display: "flex", flexDirection: "row" }}>
              {values.map((val, index) => (
                <div
                  key={index}
                  style={{
                    padding: "5px",
                    minWidth: "80px",
                    backgroundColor: index % 2 === 0 ? "#e0e0e0" : "#f0f0f0",
                    textAlign: "center",
                  }}
                >
                  <div style={{ color: "#d0d0d0" }}>{index + 1}</div>
                  <div>{val}</div>
                </div>
              ))}
            </div>
          );
        },
      },
    },
    {
      name: "totalLoad_dpr",
      label: "Total Load DPR (MW)",
      options: {
        customBodyRender: (value) => {
          return (
            <div style={{ display: "flex", flexDirection: "row" }}>
              {value}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    pagination: false,
    textLabels: {
      body: {
        noMatch: loadingData ? "Loading..." : "No data.",
      },
    },
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div className={styles.compactRow}>
      <Tabs value={activeTab} onChange={handleTabChange} className={styles.activeTab}>
        <Tab label="USEP" />
        <Tab label="CONRES Price" />
        <Tab label="Total Load" />
      </Tabs>
      {activeTab === 0 && (
        <MUIDataTable
          title={`MCR Lookahead Run (` + moment(date).format("DD MMM YYYY") + `)`}
          data={data}
          columns={columns.filter(col => col.name === "period" || col.name === "usep" || col.name === "usep_dpr")}
          options={options}
        />
      )}
      {activeTab === 1 && (
        <MUIDataTable
          title={`MCR Lookahead Run (` + moment(date).format("DD MMM YYYY") + `)`}
          data={data}
          columns={columns.filter(col => col.name === "period" || col.name === "conresp" || col.name === "conresp_dpr")}
          options={options}
        />
      )}
      {activeTab === 2 && (
        <MUIDataTable
          title={`MCR Lookahead Run (` + moment(date).format("DD MMM YYYY") + `)`}
          data={data}
          columns={columns.filter(col => col.name === "period" || col.name === "totalLoadMW" || col.name === "totalLoad_dpr")}
          options={options}
        />
      )}
    </div>
  );
};

export default Lookahead;
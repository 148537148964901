import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import { Label } from "reactstrap";
import MUIDataTable from "mui-datatables";

import getQueryString from "./utils.js";

const MCRAheadTable = function () {
  const [tableData, setTableData] = useState([]);
  const [total, setTotal] = useState(0);
  const [dataOptions, setDataOptions] = useState("?download=0");
  const [downloadDataOptions, setDownloadDataOptions] = useState("?download=1");
  const [sortColumns, setSortColumns] = useState([]);

  const list = async (dataOptions) => {
    const response = await axios.get("/marketclearing/ahead_run" + dataOptions);
    // console.log(response);
    if (response.status === 200) {
      setTableData(response.data.rows);
      setTotal(response.data.total);
    }
  };

  useEffect(() => {
    list(dataOptions);
  }, [dataOptions]);

  const columns = [
    {
      name: "runType",
      label: "Run Type",
      options: {
        filter: true,
        sort: false,
        filterType: "custom",
        filterOptions: {
          names: [],
          display: (filterList, onChange, index, column) => {
            let options = ["DAR", "LAR", "WAR"];
            return (
              <div>
                <Label for="startDate">Run Type</Label>
                {options.map((option) => (
                  <div class="p-1">
                    <input
                      type="radio"
                      key={option}
                      value={option}
                      checked={filterList[index][0] === option}
                      onChange={(e) => {
                        filterList[index][0] = e.target.value;
                        onChange(filterList[index], index, column);
                      }}
                    />
                    <Label className="ml-2">{option}</Label>
                  </div>
                ))}
              </div>
            );
          },
        },
        customFilterListOptions: {
          render: (v) => {
            if (v[0].length) {
              return `Run Type: ${v}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1, "");
            return filterList;
          },
        },
      },
    },
    {
      name: "runDate",
      label: "Run Date",
      options: {
        filter: true,
        filterType: "custom",
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
            "DD MMM YYYY"
          );
        },
        customFilterListOptions: {
          render: (v) => {
            if (v[0]) {
              return `Run Date: ${moment(v[0]).format("DD MMM YYYY")}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          display: (filterList, onChange, index, column) => (
            <div>
              <Label for="startDate" className="mr-2">
                Run Date
              </Label>
              <Flatpickr
                id="startDate"
                name="startDate"
                className="col-md-4"
                onChange={(date) => {
                  filterList[index][0] = date[0];
                  onChange(filterList[index], index, column);
                }}
                value={filterList[index][0] || ""}
                options={{
                  enableTime: false,
                  dateformat: "d-M-Y",
                }}
              />
            </div>
          ),
          fullWidth: true,
        },
      },
    },
    {
      name: "runPeriod",
      label: "Run Period",
      options: {
        filter: true,
        filterType: "custom",
        sort: false,
        filterOptions: {
          names: [],
          display: (filterList, onChange, index, column) => {
            let options = [1];
            if (filterList[0]) {
              if (filterList[0][0] === "DAR") {
                options = [1, 5, 9, 13, 17, 21, 25, 29, 33, 37, 41, 45];
              } else if (filterList[0][0] === "LAR") {
                options = Array.from({ length: 48 }, (_, i) => i + 1);
              }
            }
            return (
              <div>
                <Label for="startDate">Run Period</Label>
                {options.map((option) => (
                  <div class="p-1">
                    <input
                      type="radio"
                      key={option}
                      value={option}
                      checked={
                        parseInt(filterList[index][0]) === parseInt(option)
                      }
                      onChange={(e) => {
                        filterList[index][0] = e.target.value;
                        onChange(filterList[index], index, column);
                      }}
                    />
                    <label class="ml-2">{option}</label>
                  </div>
                ))}
              </div>
            );
          },
        },
        customFilterListOptions: {
          render: (v) => {
            if (v) {
              return `Run Period: ${v}`;
            }
            return [];
          },
        },
      },
    },
    {
      name: "resultDate",
      label: "Result Date",
      options: {
        filter: false,
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
            "DD MMM YYYY"
          );
        },
      },
    },
    {
      name: "resultPeriod",
      label: "Result Period",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "uniformSingaporeEnergyPrice",
      label: "USEP ($/MWh)",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "estimatesHourlyEnergyUpliftRebate",
      label: "EHEUR ($/MWh)",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "counterfactualUniformSingaporeEnergyPrice",
      label: "CUSEP ($/MWh)",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "loadCurtailmentPrice",
      label: "LCP ($/MWh)",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "totalLoadMW",
      label: "TL (MW)",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "totalCurtailedLoad",
      label: "TCL (MW)",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "totalTransmissionLossMW",
      label: "TTL (MW)",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "regulationReservePrice",
      label: "RegulationReserve Reserve Price ($/MWh)",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "regulationReserveRequirementMW",
      label: "RegulationReserve Reserve Requirement (MW)",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "regulationRegulationShortfallMW",
      label: "RegulationReserve Reserve Requirement Shortfall (MW)",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "primaryReservePrice",
      label: "PrimaryReserve Reserve Price ($/MWh)",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "primaryReserveRequirementMW",
      label: "PrimaryReserve Reserve Requirement (MW)",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "primaryRegulationShortfallMW",
      label: "PrimaryReserve Reserve Requirement Shortfall (MW)",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "contingencyReservePrice",
      label: "ContingencyReserve Reserve Price ($/MWh)",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "contingencyReserveRequirementMW",
      label: "ContingencyReserve Reserve Requirement (MW)",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "contingencyRegulationShortfallMW",
      label: "ContingencyReserve Reserve Requirement Shortfall (MW)",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
  ];

  const downloadAllRowsAsCSV = async () => {
    const response = await axios.get("/marketclearing" + downloadDataOptions);
    const data = await response.data.rows;

    const columns = [
      "MCRID",
      "Date",
      "PERIOD",
      "USEP ($/MWh)",
      "EHEUR ($/MWh)",
      "CUSEP ($/MWh)",
      "LCP ($/MWh)",
      "TL (MW)",
      "TCL (MW)",
      "TTL (MW)",
    ];

    // Convert data to CSV format
    const header = columns.join(",") + "\n";
    const rows = data.map((row) =>
      Object.values(row)
        .map((item) => (typeof item === "string" ? item.trim() : item))
        .join(",")
    );

    const csv = header + rows.join("\n");

    // Download CSV file
    const link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
    );
    link.setAttribute("download", "dataDownload_mcr.csv");
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const options = {
    filterType: "checkbox",
    search: false,
    count: total,
    serverSide: true,
    rowsPerPage: 48,
    rowsPerPageOptions: [48, 100, 200],
    onDownload: (buildHead, buildBody, columns, data) => {
      downloadAllRowsAsCSV();
      return false;
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          setDataOptions(
            getQueryString(tableState, sortColumns, false, "mcr_ahead")
          );
          break;
        case "sort":
          if (
            sortColumns.filter(
              (sort) => sort.name === tableState.sortOrder.name
            ).length > 0
          ) {
            // update the existing sortColumn
            let index = sortColumns.findIndex(
              (sort) => sort.name === tableState.sortOrder.name
            );
            if (tableState.sortOrder.direction === "none") {
              let newSortCols = sortColumns.splice(index, 1);
              setSortColumns(newSortCols);
            } else {
              sortColumns[index] = {
                name: tableState.sortOrder.name,
                direction: tableState.sortOrder.direction,
              };
            }

            setSortColumns(sortColumns);
          } else {
            // add a new sortColumn
            sortColumns.push({
              name: tableState.sortOrder.name,
              direction: tableState.sortOrder.direction,
            });

            setSortColumns(sortColumns);
          }

          setDataOptions(
            getQueryString(tableState, sortColumns, false, "mcr_ahead")
          );
          setDownloadDataOptions(getQueryString(tableState, sortColumns, true));
          break;
        case "filterChange":
          setDataOptions(
            getQueryString(tableState, sortColumns, false, "mcr_ahead")
          );
          setDownloadDataOptions(
            getQueryString(tableState, sortColumns, true, "mcr_ahead")
          );
          break;
        case "changeRowsPerPage":
          setDataOptions(
            getQueryString(tableState, sortColumns, false, "mcr_ahead")
          );
          break;
        default:
          console.log(action, " is not handled.");
      }
    },
  };

  return (
    <div>
      <MUIDataTable
        title={`${
          sortColumns.length > 0
            ? " (sorted by : " +
              sortColumns.map(
                (col) => " " + col.name + " " + col.direction + " "
              ) +
              ")"
            : ""
        }`}
        data={tableData}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default MCRAheadTable;

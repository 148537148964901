import React from "react";

const OutlineStarIcon = () => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.47592 1.29389L10.0199 4.39989C10.0973 4.55655 10.2466 4.66589 10.4199 4.69122L13.8766 5.19055C14.0166 5.20922 14.1426 5.28255 14.2286 5.39455C14.3899 5.60455 14.3653 5.90189 14.1719 6.08255L11.6666 8.50522C11.5393 8.62522 11.4826 8.80122 11.5159 8.97255L12.1159 12.3912C12.1579 12.6746 11.9646 12.9399 11.6813 12.9859C11.5639 13.0039 11.4439 12.9852 11.3373 12.9326L8.25859 11.3186C8.10392 11.2346 7.91859 11.2346 7.76392 11.3186L4.66259 12.9412C4.40326 13.0732 4.08592 12.9752 3.94459 12.7212C3.89059 12.6186 3.87192 12.5019 3.89059 12.3879L4.49059 8.96922C4.52059 8.79855 4.46392 8.62322 4.33992 8.50255L1.82126 6.08055C1.61592 5.87655 1.61459 5.54455 1.81926 5.33922C1.81992 5.33855 1.82059 5.33722 1.82126 5.33655C1.90592 5.25989 2.00992 5.20855 2.12259 5.18855L5.57992 4.68922C5.75259 4.66189 5.90126 4.55389 5.97992 4.39722L7.52259 1.29389C7.58459 1.16789 7.69459 1.07122 7.82792 1.02722C7.96192 0.982553 8.10859 0.99322 8.23459 1.05655C8.33792 1.10789 8.42259 1.19122 8.47592 1.29389Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default OutlineStarIcon;

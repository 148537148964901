// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LookaheadComponents_compactRow__XF442 .MuiTableCell-root {\n  padding: 4px; }\n\n.LookaheadComponents_compactRow__XF442 .MuiTableRow-root {\n  height: 32px; }\n\n.LookaheadComponents_activeTab__1NdGT .MuiTabs-indicator {\n  background-color: #4d53e0; }\n", "",{"version":3,"sources":["webpack://src/pages/tables/components/LookaheadComponents/LookaheadComponents.module.scss"],"names":[],"mappings":"AAAA;EAEI,YAAY,EAAA;;AAFhB;EAKI,YAAY,EAAA;;AAIhB;EAEI,yBAAyB,EAAA","sourcesContent":[".compactRow {\n  :global(.MuiTableCell-root) {\n    padding: 4px;\n  }\n  :global(.MuiTableRow-root) {\n    height: 32px;\n  }\n}\n\n.activeTab {\n  :global(.MuiTabs-indicator) {\n    background-color: #4d53e0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"compactRow": "LookaheadComponents_compactRow__XF442",
	"activeTab": "LookaheadComponents_activeTab__1NdGT"
};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import { Button } from "reactstrap";

const CustomToolbar = (props) => {
  const { action } = props;

  return (
    <React.Fragment>
      <Button className="btn-rounded" color="info" onClick={action}>
        <span className="align-middle">Add</span>
      </Button>
    </React.Fragment>
  );
};

export default CustomToolbar;

import axios from "axios";
import { push } from "connected-react-router";

export const fetchBids = (dataOptions) => {
  return (dispatch) => {
    axios.get("/bids" + dataOptions).then((response) => {
      dispatch({
        type: "FETCH_BIDS",
        bids: response.data.rows,
        total: response.data.total,
      });
    });
  };
};

export const fetchAllBids = () => {
  return (dispatch) => {
    axios.get("/bids/all").then((response) => {
      dispatch({
        type: "FETCH_ALL_BIDS",
        all_bids: response.data.rows,
      });
    });
  };
};

export const addBid = (bid) => {
  return (dispatch, getState) => {
    axios.post("/bids/new", bid).then(() => {
      dispatch({
        type: "ADD_BID",
      });
      dispatch(push(`/resources/bids`));
      dispatch(fetchBids(""));
    });
  };
};

export const updateBid = (bid) => {
  return (dispatch) => {
    axios.put("/bids/" + bid.id, bid).then(() => {
      dispatch({
        type: "UPDATE_BID",
      });
      dispatch(fetchBids(""));
    });
  };
};

export const removeBid = (id) => {
  return (dispatch) => {
    axios.delete("/bids/" + id).then(() => {
      dispatch({
        type: "REMOVE_BID",
      });
    });
  };
};

export const selectBid = (bid) => {
  return (dispatch) => {
    dispatch({
      type: "SELECT_BID",
      bid,
    });
  };
};

export const selectBidByID = (id) => {
  return (dispatch) => {
    dispatch({
      type: "SELECT_BID_BY_ID",
      id,
    });
  };
};

export const updateFilter = (filter) => {
  return (dispatch, getState) => {
    dispatch({
      type: "UPDATE_BID_FILTERS",
      filter,
    });
  };
};

// ** Add/Remove All Filters
export const updateAllFilters = (value) => {
  return (dispatch, getState) => {
    dispatch({
      type: "UPDATE_BID_ALL_FILTERS",
      value,
    });
  };
};

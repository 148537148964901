import React, { useEffect, useRef } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function CustomChart(props) {
  const { lineChartData } = props;
  const totalNumData = lineChartData.length;

  let xAxisTime = [
    lineChartData[0]["jsTime"],
    lineChartData[Math.ceil(totalNumData / 6)]["jsTime"],
    lineChartData[Math.ceil((2 * totalNumData) / 6)]["jsTime"],
    lineChartData[Math.ceil((3 * totalNumData) / 6)]["jsTime"],
    lineChartData[Math.ceil((4 * totalNumData) / 6)]["jsTime"],
    lineChartData[Math.ceil((5 * totalNumData) / 6)]["jsTime"],
    lineChartData[totalNumData - 1]["jsTime"],
  ];

  return (
    <ResponsiveContainer width="100%" height={"100%"}>
      <LineChart
        width={500}
        height={300}
        data={lineChartData}
        margin={{
          top: 5,
          right: 55,
          left: 45,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="jsTime" ticks={xAxisTime} />
        <YAxis
          //dataKey={"msb2"}
          dataKey={"total"}
          unit={"kW"}
          domain={[
            //0
            Math.min(
              ...lineChartData.map((o) => parseFloat(o.total))
            ) * 0.85,

            Math.max(
              //...lineChartData.map((o) => o["msb1"]),
              //...lineChartData.map((o) => o["msb2"])
              ...lineChartData.map((o) => parseFloat(o.total))
            ) * 1.05,
          ]}
          //tickFormatter={(value) => value.round(value / 10) * 10}
          tickFormatter={(value) => value.toFixed(2)}
        />
        <Tooltip />
        <Legend />
        {/*<Line type="monotone" dataKey="msb1" stroke="#FF5668" dot={false} />
        <Line type="monotone" dataKey="msb2" stroke="#82ca9d" dot={false} />*/}
        <Line type="monotone" dataKey="total" stroke="#ff7300" dot={false}/>
      </LineChart>
    </ResponsiveContainer>
  );
}

import axios from "axios";

export const fetchGroups = () => {
  return (dispatch) => {
    axios.get("/groups").then((response) => {
      dispatch({
        type: "FETCH_GROUPS",
        groups: response.data,
      });
    });
  };
};

export const addGroup = (group) => {
  return (dispatch, getState) => {
    axios.post("/groups/new", group).then(() => {
      dispatch({
        type: "ADD_GROUP",
      });
      dispatch(fetchGroups());
    });
  };
};

export const updateGroup = (group) => {
  return (dispatch) => {
    axios.put("/groups/" + group.id, group).then(() => {
      dispatch({
        type: "UPDATE_GROUP",
      });
      dispatch(fetchGroups());
    });
  };
};

export const updateGroupState = (group_id, command) => {
  return (dispatch) => {
    axios.post("/groups/update_state/" + group_id, command).then(() => {
      dispatch({
        type: "UPDATE_GROUP_STATE",
      });
      dispatch(fetchGroups());
    });
  };
};

export const removeGroup = (id) => {
  return (dispatch) => {
    axios.delete("/groups/" + id).then(() => {
      dispatch({
        type: "REMOVE_GROUP",
      });
      dispatch(fetchGroups());
    });
  };
};

export const selectGroup = (group) => {
  return (dispatch) => {
    dispatch({
      type: "SELECT_GROUP",
      group,
    });
  };
};

import React, { useEffect, useState } from "react";
import { Input, Table } from "reactstrap";
import moment from "moment";

export default function DailySummaryTable(props) {
  const {
    totalAmount,
    setTotalAmount,
    cceAmount,
    setCceAmount,
    clientAmount,
    setClientAmount,
    dailyRebates,
    setDailyRebates,
    onInputChange,
  } = props;
  function onChangeDailyRebate(e, index, name) {
    const tempDailyRebates = [...dailyRebates];
    tempDailyRebates[index][name] = e.target.value;
    setDailyRebates(tempDailyRebates);
  }

  return (
    <Table bordered>
      <thead>
        <tr>
          <th>Date</th>
          <th>Total Amount in SGD (Exclusive of 9% GST)</th>
          <th>CCE's Share in SGD (Exclusive of 9% GST)</th>
          <th>Client's Share in SGD (Exclusive of 9% GST)</th>
        </tr>
      </thead>
      <tbody>
        {dailyRebates.map((dailyRebate, index) => {
          return (
            <tr key={index}>
              <th scope="row">
                {moment(dailyRebate.settlementDate).format("Do MMM YYYY")}
              </th>
              <td>
                <Input
                  type="number"
                  value={dailyRebate.totalAmount}
                  onChange={(e) => onChangeDailyRebate(e, index, "totalAmount")}
                  step={0.01}
                />
              </td>
              <td>
                <Input
                  type="number"
                  value={dailyRebate.cceAmount}
                  onChange={(e) => onChangeDailyRebate(e, index, "cceAmount")}
                  step={0.01}
                />
              </td>
              <td>
                <Input
                  type="number"
                  value={dailyRebate.clientAmount}
                  onChange={(e) =>
                    onChangeDailyRebate(e, index, "clientAmount")
                  }
                  step={0.01}
                />
              </td>
            </tr>
          );
        })}
        <tr>
          <th scope="row">Total</th>
          <td>
            <Input
              type="number"
              value={totalAmount}
              onChange={(e) => onInputChange(e, setTotalAmount)}
              step={0.01}
            />
          </td>
          <td>
            <Input
              type="number"
              value={cceAmount}
              onChange={(e) => onInputChange(e, setCceAmount)}
              step={0.01}
            />
          </td>
          <td>
            <Input
              type="number"
              value={clientAmount}
              onChange={(e) => onInputChange(e, setClientAmount)}
              step={0.01}
            />
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

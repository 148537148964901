export default {
  periods: [
    { value: 0, label: "Select Period" },
    { value: 1, label: "1 (00:00 - 00:30)" },
    { value: 2, label: "2 (00:30 - 01:00)" },
    { value: 3, label: "3 (01:00 - 01:30)" },
    { value: 4, label: "4 (01:30 - 02:00)" },
    { value: 5, label: "5 (02:00 - 02:30)" },
    { value: 6, label: "6 (02:30 - 03:00)" },
    { value: 7, label: "7 (03:00 - 03:30)" },
    { value: 8, label: "8 (03:30 - 04:00)" },
    { value: 9, label: "9 (04:00 - 04:30)" },
    { value: 10, label: "10 (04:30 - 05:00)" },
    { value: 11, label: "11 (05:00 - 05:30)" },
    { value: 12, label: "12 (05:30 - 06:00)" },
    { value: 13, label: "13 (06:00 - 06:30)" },
    { value: 14, label: "14 (06:30 - 07:00)" },
    { value: 15, label: "15 (07:00 - 07:30)" },
    { value: 16, label: "16 (07:30 - 08:00)" },
    { value: 17, label: "17 (08:00 - 08:30)" },
    { value: 18, label: "18 (08:30 - 09:00)" },
    { value: 19, label: "19 (09:00 - 09:30)" },
    { value: 20, label: "20 (09:30 - 10:00)" },
    { value: 21, label: "21 (10:00 - 10:30)" },
    { value: 22, label: "22 (10:30 - 11:00)" },
    { value: 23, label: "23 (11:00 - 11:30)" },
    { value: 24, label: "24 (11:30 - 12:00)" },
    { value: 25, label: "25 (12:00 - 12:30)" },
    { value: 26, label: "26 (12:30 - 13:00)" },
    { value: 27, label: "27 (13:00 - 13:30)" },
    { value: 28, label: "28 (13:30 - 14:00)" },
    { value: 29, label: "29 (14:00 - 14:30)" },
    { value: 30, label: "30 (14:30 - 15:00)" },
    { value: 31, label: "31 (15:00 - 15:30)" },
    { value: 32, label: "32 (15:30 - 16:00)" },
    { value: 33, label: "33 (16:00 - 16:30)" },
    { value: 34, label: "34 (16:30 - 17:00)" },
    { value: 35, label: "35 (17:00 - 17:30)" },
    { value: 36, label: "36 (17:30 - 18:00)" },
    { value: 37, label: "37 (18:00 - 18:30)" },
    { value: 38, label: "38 (18:30 - 19:00)" },
    { value: 39, label: "39 (19:00 - 19:30)" },
    { value: 40, label: "40 (19:30 - 20:00)" },
    { value: 41, label: "41 (20:00 - 20:30)" },
    { value: 42, label: "42 (20:30 - 21:00)" },
    { value: 43, label: "43 (21:00 - 21:30)" },
    { value: 44, label: "44 (21:30 - 22:00)" },
    { value: 45, label: "45 (22:00 - 22:30)" },
    { value: 46, label: "46 (22:30 - 23:00)" },
    { value: 47, label: "47 (23:00 - 23:30)" },
    { value: 48, label: "48 (23:30 - 24:00)" },
  ],
};

// ** Initial State
const initialState = {
  batteryTableData: [],
};

const battery = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_PYC_001_BAT_1_TABLE_DATA":
      return { ...state, batteryTableData: action.batteryTableData };

    default:
      return state;
  }
};

export default battery;

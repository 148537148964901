import React, { Component, useEffect } from "react";
import OfferView from "./OfferView";
import { selectOfferByID } from "../../../actions/offer";
import { connect } from "react-redux";

const OfferPage = (props) => {
  const { dispatch, match, loading, record } = props;
  useEffect(() => {
    console.log(match.params.id);
    dispatch(selectOfferByID(match.params.id));
  }, [match]);

  return (
    <>
      <OfferView loading={loading} record={record} />
    </>
  );
};

function mapStateToProps(store) {
  return {
    loading: store.users.form.loading,
    record: store.offers.selectedOffer,
  };
}

export default connect(mapStateToProps)(OfferPage);

import React, { useEffect, useState } from "react";
import UsersListTable from "./UsersListTable";

const UsersListPage = () => {
  const [promoAlert, setPromoAlert] = useState(false);

  const showPromoAlert = () => setPromoAlert(true);

  useEffect(() => {
    setTimeout(() => {
      showPromoAlert();
    }, 100);
  });

  return (
    <div>
      <div className="page-top-line">
        <h2 className="page-title">
          User <span className="fw-semi-bold">Management</span>
        </h2>
      </div>
      <UsersListTable />
    </div>
  );
};

export default UsersListPage;

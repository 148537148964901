import React, { createElement, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Button, Col, Form, FormGroup, Input, Label } from "reactstrap";
import Select from "react-select";
import { assetOptions } from "../MonitoringComponents/Constants";
import styles from "./InvoicesComponents.module.scss";
import DatePicker from "react-datepicker";
import axios from "axios";
import { jsPDF } from "jspdf";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";
import moment from "moment";
import DailySummaryTable from "./PaymentNoticeComponents/DailySummaryTable";

export default function PaymentNotice() {
  const [selectedAsset, setSelectedAsset] = useState(assetOptions[0]);
  const [selectedMonth, setSelectedMonth] = useState(new Date());

  // Form Fields

  // Recipient Details
  const [paymentNoticeNumber, setPaymentNoticeNumber] = useState(0);
  const [clientAddress1, setClientAddress1] = useState("");
  const [clientAddress2, setClientAddress2] = useState("");
  const [clientAddress3, setClientAddress3] = useState("");
  const [clientAddress4, setClientAddress4] = useState("");
  const [startBillingDate, setStartBillingDate] = useState(null);
  const [endBillingDate, setEndBillingDate] = useState(null);
  const [clientFacilityId, setClientFacilityId] = useState("");
  // Auxiliary Power
  const [showAuxiliaryPower, setShowAuxiliaryPower] = useState(true);
  const [electricityBillInclusiveGST, setElectricityBillInclusiveGST] =
    useState(0);
  const [electricityBillExclusiveGST, setElectricityBillExclusiveGST] =
    useState(0);
  const [electricityRate, setElectricityRate] = useState(0);
  const [batteryAuxPowerConsumption, setBatteryAuxPowerConsumption] =
    useState(0);
  // Daily Rebates Table
  const [dailyRebates, setDailyRebates] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [cceAmount, setCceAmount] = useState(0);
  const [clientAmount, setClientAmount] = useState(0);
  // Monthly Graph
  const [monthlyRebates, setMonthlyRebates] = useState({});
  // Payment Summary
  const [
    totalAccountReceivablesExclusiveGST,
    setTotalAccountReceivablesExclusiveGST,
  ] = useState(0);
  const [
    totalAccountReceivablesInclusiveGST,
    setTotalAccountReceivablesInclusiveGST,
  ] = useState(0);

  const onChangeBillingDate = (dates) => {
    const [start, end] = dates;
    setStartBillingDate(start);
    setEndBillingDate(end);
  };
  async function handleGeneratePaymentNotice() {
    const response = await axios.post(
      "/invoices/generateInvoice?selectedAsset=" +
        selectedAsset.value +
        "&selectedMonth=" +
        selectedMonth
    );
    if (response.status === 200) {
      setMonthlyRebates(response.data.yearGraphByMonth);
      setPaymentNoticeNumber(response.data.paymentNoticeNumber);
      setClientAddress1(response.data.clientAddress1);
      setClientAddress2(response.data.clientAddress2);
      setClientAddress3(response.data.clientAddress3);
      setClientAddress4(response.data.clientAddress4);
      setClientFacilityId(response.data.clientFacilityId);
      setBatteryAuxPowerConsumption(response.data.batteryAuxPowerConsumption);
      setStartBillingDate(new Date(response.data.startBillingPeriod));
      setEndBillingDate(new Date(response.data.endBillingPeriod));
      setElectricityRate(response.data.electricityRate);
      setElectricityBillInclusiveGST(response.data.electricityBillInclusiveGST);
      setElectricityBillExclusiveGST(response.data.electricityBillExclusiveGST);
      setDailyRebates(response.data.dailyRebates);
      setTotalAmount(response.data.totalAmount);
      setCceAmount(response.data.cceAmount);
      setClientAmount(response.data.clientAmount);
      setTotalAccountReceivablesExclusiveGST(
        response.data.totalAccountReceivablesExclusiveGST
      );
      setTotalAccountReceivablesInclusiveGST(
        response.data.totalAccountReceivablesInclusiveGST
      );
    }
  }

  async function handleConvertToPDF() {
    const response = await axios.post(
      "/invoices/generateInvoicePDF?selectedAsset=" +
        selectedAsset.value +
        "&selectedMonth=" +
        selectedMonth,
      {
        // Recipient Details
        paymentNoticeNumber: paymentNoticeNumber,
        clientAddress1: clientAddress1,
        clientAddress2: clientAddress2,
        clientAddress3: clientAddress3,
        clientAddress4: clientAddress4,
        startBillingDate: startBillingDate,
        endBillingDate: endBillingDate,
        clientFacilityId: clientFacilityId,
        // Aux Power
        showAuxiliaryPower: showAuxiliaryPower,
        batteryAuxPowerConsumption: batteryAuxPowerConsumption,
        electricityRate: electricityRate,
        electricityBillExclusiveGST: electricityBillExclusiveGST,
        electricityBillInclusiveGST: electricityBillInclusiveGST,
        // Accounts Receivables
        totalAccountReceivablesExclusiveGST:
          totalAccountReceivablesExclusiveGST,
        totalAccountReceivablesInclusiveGST:
          totalAccountReceivablesInclusiveGST,
        dailyRebates: dailyRebates,
        totalAmount: totalAmount,
        cceAmount: cceAmount,
        clientAmount: clientAmount,
      }
    );
    if (response.status === 200) {
      const svg = document.getElementById("hiddenBarChart");

      let pngData = await svgToPng(svg, 1200, 600);
      const doc = new jsPDF({
        unit: "mm",
        format: "a4",
      });

      let tempHTML = response.data.html;
      tempHTML = tempHTML.replace("image-source", pngData);
      doc.html(tempHTML, {
        callback: function (doc) {
          doc.save(
            `${selectedAsset.value} - ${moment(selectedMonth).format(
              "MMM YYYY"
            )}`
          );
        },
        x: 10,
        y: 10,
        width: 185,
        windowWidth: 850,
        autoPaging: "text",
        margin: [0, 10, 10, 0],
      });
    }

    setMonthlyRebates([]);
    setPaymentNoticeNumber(0);
    setClientAddress1("");
    setClientAddress2("");
    setClientAddress3("");
    setClientAddress4("");
    setClientFacilityId("");
    setBatteryAuxPowerConsumption(0);
    setStartBillingDate(new Date());
    setEndBillingDate(new Date());
    setElectricityRate(0);
    setElectricityBillInclusiveGST(0);
    setElectricityBillExclusiveGST(0);
    setDailyRebates([]);
    setTotalAmount(0);
    setCceAmount(0);
    setClientAmount(0);
    setTotalAccountReceivablesExclusiveGST(0);
    setTotalAccountReceivablesInclusiveGST(0);
  }

  function onInputChange(e, setStateFunction) {
    setStateFunction(e.target.value);
  }

  function onInputChangeCheckbox(e, setStateFunction) {
    setStateFunction(e.target.checked);
  }

  return (
    <div className={styles.PaymentNoticeContainer}>
      <div className={styles.GenerateContainer}>
        <div className={styles.SelectContainer}>
          <div className={styles.AssetSelectContainer}>
            <div>Facility:</div>
            <Select
              value={selectedAsset}
              options={assetOptions}
              onChange={setSelectedAsset}
              isDisabled
            />
          </div>
          <div className={styles.MonthSelectContainer}>
            <div>Month:</div>
            <DatePicker
              dateFormat="MM/yyyy"
              selected={selectedMonth}
              onChange={(date) => setSelectedMonth(date)}
              showMonthYearPicker
            />
          </div>
        </div>
        <div className={styles.BottomContainer}>
          <Button color="primary" onClick={handleGeneratePaymentNotice}>
            Generate Payment Notice
          </Button>
        </div>
      </div>

      {paymentNoticeNumber !== 0 && (
        <div className={styles.FormInputContainer}>
          <h3>
            {selectedAsset.value} @ {moment(selectedMonth).format("MMM YYYY")}
          </h3>
          <h4 className={styles.FormHeader}>Recipient's Details</h4>
          <Form className={styles.Form}>
            <FormGroup className={styles.FormGroup}>
              <div className={styles.FormInputContainer}>
                <Label>Payment Notice Number</Label>
                <Input
                  type="text"
                  value={paymentNoticeNumber}
                  onChange={(e) => onInputChange(e, setPaymentNoticeNumber)}
                />
              </div>
              <div className={styles.FormInputContainer}>
                <Label></Label>
              </div>
              <div className={styles.FormInputContainer}>
                <Label>Client Address 1</Label>
                <Input
                  type="text"
                  value={clientAddress1}
                  onChange={(e) => onInputChange(e, setClientAddress1)}
                />
              </div>
              <div className={styles.FormInputContainer}>
                <Label>Client Address 2</Label>
                <Input
                  type="text"
                  value={clientAddress2}
                  onChange={(e) => onInputChange(e, setClientAddress2)}
                />
              </div>
              <div className={styles.FormInputContainer}>
                <Label>Client Address 3</Label>
                <Input
                  type="text"
                  value={clientAddress3}
                  onChange={(e) => onInputChange(e, setClientAddress3)}
                />
              </div>
              <div className={styles.FormInputContainer}>
                <Label>Client Address 4</Label>
                <Input
                  type="text"
                  value={clientAddress4}
                  onChange={(e) => onInputChange(e, setClientAddress4)}
                />
              </div>
              <div className={styles.FormInputContainer}>
                <Label>Billing Period</Label>
                <div>
                  <DatePicker
                    selected={startBillingDate}
                    onChange={onChangeBillingDate}
                    startDate={startBillingDate}
                    endDate={endBillingDate}
                    selectsRange
                    inline
                  />
                </div>
              </div>
              <div className={styles.FormInputContainer}>
                <Label>Facility ID</Label>
                <Input
                  type="text"
                  value={clientFacilityId}
                  onChange={(e) => onInputChange(e, setClientFacilityId)}
                />
              </div>
            </FormGroup>
          </Form>
          <h4 className={styles.FormHeader}>Auxiliary Power</h4>
          <div className={styles.CheckboxContainer}>
            <Input
              type="checkbox"
              value={showAuxiliaryPower}
              onChange={(e) => onInputChangeCheckbox(e, setShowAuxiliaryPower)}
              defaultChecked
            />
            <Label check>Show Auxiliary Power Consumption</Label>
          </div>
          {showAuxiliaryPower && (
            <Form className={styles.Form}>
              <FormGroup className={styles.FormGroup}>
                <div className={styles.FormInputContainer}>
                  <Label>Battery Auxiliary Power Consumption (kWh)</Label>
                  <Input
                    type="number"
                    value={batteryAuxPowerConsumption}
                    onChange={(e) =>
                      onInputChange(e, setBatteryAuxPowerConsumption)
                    }
                    step={0.001}
                  />
                </div>
                <div className={styles.FormInputContainer}>
                  <Label>Electricity Rate ($/kWh)</Label>
                  <Input
                    type="number"
                    value={electricityRate}
                    onChange={(e) => onInputChange(e, setElectricityRate)}
                    step={0.01}
                  />
                </div>
                <div className={styles.FormInputContainer}>
                  <Label>Electricity Bill in SGD (Exclusive of 9% GST)</Label>
                  <Input
                    type="number"
                    value={electricityBillExclusiveGST}
                    onChange={(e) =>
                      onInputChange(e, setElectricityBillExclusiveGST)
                    }
                    step={0.01}
                  />
                </div>
                <div className={styles.FormInputContainer}>
                  <Label>Electricity Bill in SGD (Inclusive of 9% GST)</Label>
                  <Input
                    type="number"
                    value={electricityBillInclusiveGST}
                    onChange={(e) =>
                      onInputChange(e, setElectricityBillInclusiveGST)
                    }
                    step={0.01}
                  />
                </div>
              </FormGroup>
            </Form>
          )}
          <h4 className={styles.FormHeader}>Accounts Receivables</h4>
          <Form className={styles.Form}>
            <FormGroup className={styles.FormGroup}>
              <div className={styles.FormInputContainer}>
                <Label>Account Receivables (Exclusive of 9% GST)</Label>
                <Input
                  type="number"
                  value={totalAccountReceivablesExclusiveGST}
                  onChange={(e) =>
                    onInputChange(e, setTotalAccountReceivablesExclusiveGST)
                  }
                  step={0.01}
                />
              </div>
              <div className={styles.FormInputContainer}>
                <Label>Account Receivables (Inclusive of 9% GST)</Label>
                <Input
                  type="number"
                  value={totalAccountReceivablesInclusiveGST}
                  onChange={(e) =>
                    onInputChange(e, setTotalAccountReceivablesInclusiveGST)
                  }
                  step={0.01}
                />
              </div>
            </FormGroup>
          </Form>
          <h4 className={styles.FormHeader}>Daily Summary</h4>
          <div className={styles.Form}>
            <DailySummaryTable
              dailyRebates={dailyRebates}
              setDailyRebates={setDailyRebates}
              totalAmount={totalAmount}
              setTotalAmount={setTotalAmount}
              cceAmount={cceAmount}
              setCceAmount={setCceAmount}
              clientAmount={clientAmount}
              setClientAmount={setClientAmount}
              onInputChange={onInputChange}
            />
          </div>
          <div className={styles.ConvertButtonContainer}>
            <Button onClick={handleConvertToPDF}>Convert To PDF</Button>
          </div>
        </div>
      )}

      <div>
        <div className={styles.hiddenGraph}>
          <BarChart
            id="hiddenBarChart"
            width={1200}
            height={600}
            data={monthlyRebates}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="value"
              fill="#8884d8"
              activeBar={<Rectangle fill="pink" stroke="blue" />}
            >
              <LabelList dataKey="value" position="top" />
            </Bar>
          </BarChart>
        </div>
      </div>
    </div>
  );
}
export const svgToPng = (svg, width, height) => {
  return new Promise((resolve, reject) => {
    let canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    let ctx = canvas.getContext("2d");

    // Set background to white
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, width, height);

    let xml = new XMLSerializer().serializeToString(svg);
    let dataUrl = "data:image/svg+xml;utf8," + encodeURIComponent(xml);
    let img = new Image(width, height);

    img.onload = () => {
      ctx.drawImage(img, 0, 0);
      let imageData = canvas.toDataURL("image/png", 1.0);
      resolve(imageData);
    };

    img.onerror = () => reject();

    img.src = dataUrl;
    return dataUrl;
  });
};

import axios from "axios";
import { push } from "connected-react-router";

export const fetchOffers = (dataOptions) => {
  return (dispatch) => {
    axios.get("/offers" + dataOptions).then((response) => {
      dispatch({
        type: "FETCH_OFFERS",
        offers: response.data.rows,
        total: response.data.total,
      });
    });
  };
};

export const fetchAllOffers = () => {
  return (dispatch) => {
    axios.get("/offers/all").then((response) => {
      dispatch({
        type: "FETCH_ALL_OFFERS",
        all_offers: response.data.rows,
      });
    });
  };
};

export const addOffer = (offer) => {
  return (dispatch, getState) => {
    axios.post("/offers/new", offer).then(() => {
      dispatch({
        type: "ADD_OFFER",
      });
      dispatch(push(`/resources/offers`));
      dispatch(fetchOffers(""));
    });
  };
};

export const updateOffer = (offer) => {
  return (dispatch) => {
    axios.put("/offers/" + offer.id, offer).then(() => {
      dispatch({
        type: "UPDATE_OFFER",
      });
      dispatch(fetchOffers(""));
    });
  };
};

export const removeOffer = (id) => {
  return (dispatch) => {
    axios.delete("/offers/" + id).then(() => {
      dispatch({
        type: "REMOVE_OFFER",
      });
    });
  };
};

export const selectOffer = (offer) => {
  return (dispatch) => {
    dispatch({
      type: "SELECT_OFFER",
      offer,
    });
  };
};

export const selectOfferByID = (id) => {
  return (dispatch) => {
    dispatch({
      type: "SELECT_OFFER_BY_ID",
      id,
    });
  };
};

export const updateFilter = (filter) => {
  return (dispatch, getState) => {
    dispatch({
      type: "UPDATE_FILTERS",
      filter,
    });
  };
};

// ** Add/Remove All Filters
export const updateAllFilters = (value) => {
  return (dispatch, getState) => {
    dispatch({
      type: "UPDATE_ALL_FILTERS",
      value,
    });
  };
};

import React, { useState, useEffect } from "react";
import ChangePasswordForm from "./ChangePasswordForm";
import { push } from "connected-react-router";
import actions from "../../../actions/usersFormActions";
import { connect } from "react-redux";

const ChangePasswordFormPage = (props) => {
  const [promoAlert, setPromoAlert] = useState(false);

  const showPromoAlert = () => {
    setPromoAlert(true);
  };

  const doSubmit = (data) => {
    props.dispatch(actions.doChangePassword(data));
  };

  useEffect(() => {
    setTimeout(() => {
      showPromoAlert();
    }, 1000);
  });

  return (
    <React.Fragment>
      <div className="page-top-line">
        <h2 className="page-title">
          User <span className="fw-semi-bold">Password</span>
        </h2>
      </div>
      <ChangePasswordForm
        saveLoading={props.saveLoading}
        findLoading={props.findLoading}
        onSubmit={doSubmit}
        onCancel={() => props.dispatch(push("/admin/users"))}
      />
    </React.Fragment>
  );
};

function mapStateToProps(store) {
  return {
    findLoading: store.users.form.findLoading,
    saveLoading: store.users.form.saveLoading,
    record: store.users.form.record,
    currentUser: store.auth.currentUser,
  };
}

export default connect(mapStateToProps)(ChangePasswordFormPage);

import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default function CustomModal(props) {
  const {
    className,
    scrollable,
    size,
    children,
    backdrop,
    opened,
    modalTitle,
    toggle,
    modalStatus,
  } = props;

  const CloseBtn = (
    <i className="eva eva-close cursor-pointer" onClick={toggle} />
  );

  return (
    <Modal
      isOpen={modalStatus || opened}
      backdrop={backdrop}
      toggle={toggle}
      size={size}
      className={className}
      scrollable={scrollable}
    >
      <ModalHeader toggle={toggle} close={CloseBtn} className="headline-1">
        {modalTitle || "Use Google's location service?"}
      </ModalHeader>
      {scrollable ? (
        <ModalBody>Cras mattis consectetur purus sit amet fermentum.</ModalBody>
      ) : (
        <ModalBody>{children}</ModalBody>
      )}
      <ModalFooter>
        {
          <div className="mx-auto">
            <Button
              className="btn-rounded btn-outline-secondary mr-3"
              outline
              onClick={toggle}
            >
              OK
            </Button>
            {/* <Button className="btn-rounded" color="primary" onClick={toggle}>Disagree</Button> */}
          </div>
        }
      </ModalFooter>
    </Modal>
  );
}

// ** Initial State
const initialState = {
  offers: [],
  all_offers: [],
  total_offers: 0,
  selectedOffer: {},
  selectedStatus: [
    "pending",
    "rejected",
    "not-accepted",
    "accepted",
    "validated",
    "cleared",
    "not-cleared",
  ],
};

const calendar = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_OFFERS":
      return { ...state, offers: action.offers, total_offers: action.total };
    case "FETCH_ALL_OFFERS":
      return { ...state, all_offers: action.all_offers };
    case "ADD_OFFER":
      return { ...state };
    case "REMOVE_OFFER":
      return { ...state };
    case "UPDATE_OFFER":
      return { ...state };
    case "UPDATE_FILTERS":
      // ** Updates Filters based on action filter
      const filterIndex = state.selectedStatus.findIndex(
        (i) => i === action.filter
      );
      if (state.selectedStatus.includes(action.filter)) {
        state.selectedStatus.splice(filterIndex, 1);
      } else {
        state.selectedStatus.push(action.filter);
      }
      return { ...state };
    case "UPDATE_ALL_FILTERS":
      // ** Updates All Filters based on action value
      const value = action.value;
      let selected = [];
      if (value === true) {
        selected = [
          "pending",
          "rejected",
          "not-accepted",
          "accepted",
          "validated",
          "cleared",
          "not-cleared",
        ];
      } else {
        selected = [];
      }
      return { ...state, selectedStatus: selected };
    case "SELECT_OFFER":
      return { ...state, selectedOffer: action.offer };
    case "SELECT_OFFER_BY_ID":
      let selectedOffer = state.offers.filter(
        (offer) => offer.id === parseInt(action.id)
      )[0];
      return { ...state, selectedOffer: selectedOffer };
    default:
      return state;
  }
};

export default calendar;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import { Collapse, Badge } from "reactstrap";
import { Route } from "react-router";
import classnames from "classnames";

import s from "./LinksGroup.module.scss";

const LinksGroup = (props) => {
  const [headerLinkClicked, setHeaderLinkClicked] = useState(true);

  const togglePanelCollapse = (link, e) => {
    props.onActiveSidebarItemChange(link);
    setHeaderLinkClicked(
      !headerLinkClicked ||
        (props.activeItem && !props.activeItem.includes(props.index))
    );
    e.preventDefault();
  };

  const isOpen =
    props.activeItem &&
    props.activeItem.includes(props.index) &&
    headerLinkClicked;
  if (
    props.isAdminRoute !== undefined &&
    props.isAdmin !== undefined &&
    props.isAdminRoute &&
    !props.isAdmin
  ) {
    return null;
  } else if (props.currentUser === null) {
    return null;
  } else {
    if (props.currentUser !== null) {
      if (
        props.currentUser.accessLevels.filter(
          (item) => item.resource === props.resourceName
        ).length === 0
      ) {
        return null;
      }
    }

    if (!props.childrenLinks) {
      if (props.isHeader) {
        return (
          <li className={[s.headerLink, props.className].join(" ")}>
            <NavLink
              to={props.link}
              activeClassName={s.headerLinkActive}
              exact={props.exact}
              target={props.target}
            >
              <span className={s.icon}>{props.iconName}</span>
              {props.header}
              {props.label && (
                <sup className={`text-${props.labelColor || "warning"}`}>
                  {props.label}
                </sup>
              )}
              {props.badge && (
                <Badge className={s.badge} color="secondary-red" pill>
                  {props.badge}
                </Badge>
              )}
            </NavLink>
          </li>
        );
      }
      return (
        <li>
          <NavLink
            to={props.link}
            activeClassName={s.headerLinkActive}
            onClick={(e) => {
              if (props.link.includes("menu")) {
                e.preventDefault();
              }
            }}
            exact={props.exact}
          >
            {<i className="fa fa-circle text-primary mr-2" />} {props.header}
          </NavLink>
        </li>
      );
    }

    return (
      <Route
        path={props.link}
        children={(params) => {
          return (
            <li
              className={classnames(
                { [s.headerLink]: props.isHeader },
                props.className
              )}
            >
              <a
                className={classnames("d-flex", { [s.collapsed]: isOpen })}
                onClick={(e) => togglePanelCollapse(props.link, e)}
                href="#top"
              >
                {props.isHeader ? (
                  <span className={s.icon}>{props.iconName}</span>
                ) : null}
                {props.header}{" "}
                {props.label && (
                  <sup className={`text-${props.labelColor || "warning"} ml-1`}>
                    {props.label}
                  </sup>
                )}
                <b className={["fa fa-angle-right", s.caret].join(" ")} />
              </a>
              <Collapse className={s.panel} isOpen={isOpen}>
                <ul>
                  {props.childrenLinks &&
                    props.childrenLinks.map((child, ind) => {
                      if (
                        (child.isAdmin && child.isAdminRoute) ||
                        (child.isAdmin === undefined &&
                          child.isAdminRoute === undefined)
                      ) {
                        return (
                          <LinksGroup
                            onActiveSidebarItemChange={
                              props.onActiveSidebarItemChange
                            }
                            activeItem={props.activeItem}
                            header={child.header}
                            link={child.link}
                            index={child.index}
                            childrenLinks={child.childrenLinks}
                            deep={props.deep + 1}
                            key={ind}
                            resourceName={child.resourceName}
                            currentUser={props.currentUser}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                </ul>
              </Collapse>
            </li>
          );
        }}
      />
    );
  }
};

LinksGroup.propTypes = {
  header: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
  childrenLinks: PropTypes.array,
  iconName: PropTypes.object,
  className: PropTypes.string,
  badge: PropTypes.string,
  label: PropTypes.string,
  activeItem: PropTypes.string,
  isHeader: PropTypes.bool,
  index: PropTypes.string,
  deep: PropTypes.number,
  onActiveSidebarItemChange: PropTypes.func,
  labelColor: PropTypes.string,
  exact: PropTypes.bool,
  isAdmin: PropTypes.bool,
  adminRoute: PropTypes.bool,
  resourceName: PropTypes.string,
  currentUser: PropTypes.object,
};

export default withRouter(LinksGroup);

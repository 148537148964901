import React from "react";
import Widget from "../../components/Widget/Widget.js";
import ChartWidget from "./components/ClientDashboardComponents/ChartWidget.js";
import ChartWidgetTotal from "./components/ClientDashboardComponents/ChartWidgetTotal.js";
import BatteryStatusWidget from "./components/ClientDashboardComponents/BatteryStatusWidget.js";
import BatteryPerformanceWidget from "./components/ClientDashboardComponents/BatteryPerformanceWidget.js";
import ErrorsTimeline from "./components/ClientDashboardComponents/ErrorsTimeline.js";
import styles from "./Monitoring.module.scss";

export default function ClientDashboard() {
  return (
    <div>
      <div className={styles.WidgetContainer}>
        <Widget title="Live Power Consumption">
          <ChartWidget />
        </Widget>
      </div>

      <div className={styles.WidgetContainer}>
        <Widget title="Total Live Power Consumption">
          <ChartWidgetTotal />
        </Widget>
      </div>

      <div className = {styles.WidgetWrapper}>
        <div className={styles.WidgetHalfContainer}>
          <Widget title="Battery Status">
            <BatteryStatusWidget />
          </Widget>
        </div>

        <div className={styles.WidgetHalfContainer}>
          <Widget title="Battery Errors">
            <ErrorsTimeline />
          </Widget>
        </div>
      </div>

      <div className={styles.WidgetContainer}>
        <Widget title="Battery Performance">
          <BatteryPerformanceWidget />
        </Widget>
      </div>


    </div>
  );
}

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Modal_ChargeSettingsFieldGroup__2Km-n {\n  margin-top: 10px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center; }\n", "",{"version":3,"sources":["webpack://src/pages/tables/components/MonitoringComponents/Modals/Modal.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB,EAAA","sourcesContent":[".ChargeSettingsFieldGroup {\n  margin-top: 10px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChargeSettingsFieldGroup": "Modal_ChargeSettingsFieldGroup__2Km-n"
};
export default ___CSS_LOADER_EXPORT___;

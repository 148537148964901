import React, { useEffect, useState } from "react";
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Spinner,
  Label,
} from "reactstrap";
import {
  AreaChart,
  Area,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";
import moment from "moment";

import ApexCharts from "react-apexcharts";
import Widget from "../../../components/Widget/Widget";
import chartsData from "./mock";
import s from "./LineCharts.module.scss";

export default function SimpleLineCharts() {
  // const [activeTab, setActiveTab] = useState(1);
  // const {apexCharts, recharts, highcharts} = chartsData;
  const [refreshing, setRefreshing] = useState(true);
  const [data, setData] = useState([
    {
      name: "000mall",
      data: [
        [Date.UTC(1970, 10, 25), 0],
        [Date.UTC(1971, 6, 4), 0],
      ],
      marker: { symbol: "circle" },
    },
  ]);
  const [endPicker, setEndPicker] = useState(new Date());
  const [startPicker, setStartPicker] = useState(
    moment().subtract(1, "day").toDate()
  );

  const fetchData = async () => {
    setRefreshing(true);
    const response = await axios.get(
      `/buildings/consumption/power/${moment(startPicker).unix()}/${moment(endPicker).unix()}`
    );
    // console.log(response);
    if (response.status === 200) {
      setData(response.data);
      setRefreshing(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const timeData = {
    chart: {
      type: "line",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    xAxis: {
      type: "datetime",
      // dateTimeLabelFormats: { // don't display the dummy year
      //   month: '%e. %b',
      //   year: '%b'
      // },
      title: {
        text: "Time",
      },
    },
    yAxis: {
      title: {
        text: "Power (kW)",
      },
      min: 0,
    },
    tooltip: {
      headerFormat: "<b>{series.name}</b><br>",
      pointFormat: "{point.x:%A, %b %e, %H:%M}: {point.y:.2f} kW",
    },

    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
    },

    colors: ["#95d0fc", "#2F33A7", "#00A5FF", "#4d53e0", "#6b859e"],
    time: {
      useUTC: false,
      timezone: "Asia/Singapore",
    },

    // Define the data points. All series have a dummy year
    // of 1970/71 in order to be compared on the same x axis. Note
    // that in JavaScript, months start at 0 for January, 1 for February etc.
    series: data,

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            plotOptions: {
              series: {
                marker: {
                  radius: 2.5,
                },
              },
            },
          },
        },
      ],
    },
  };

  return (
    <Widget className="charts-tabs-widget" style={{ overflow: "auto" }}>
      <TabContent activeTab={1}>
        <TabPane tabId={1}>
          <Col>
            <Row className="mb-4">
              <Col xs={12} lg={12}>
                <Widget>
                  <div className="headline-2 mb-4">
                    Power Consumptions{" "}
                    {refreshing ? (
                      <Spinner size="sm" className="mr-3">
                        Loading...
                      </Spinner>
                    ) : null}
                  </div>
                  <Row className="mb-4">
                    <Label for="startDate">From</Label>
                    <Flatpickr
                      // required
                      id="startDate"
                      name="startDate"
                      className="col-md-2"
                      onChange={(date) => {
                        setStartPicker(date[0]);
                      }}
                      value={startPicker}
                      options={{
                        enableTime: true,
                        time_24hr: true,
                        dateformat: "Y-m-d H:i",
                      }}
                    />
                    To
                    <Flatpickr
                      // required
                      id="endDate"
                      name="endDate"
                      className="col-md-2"
                      onChange={(date) => setEndPicker(date[0])}
                      value={endPicker}
                      options={{
                        enableTime: true,
                        dateformat: "Y-m-d H:i",
                      }}
                    />
                    <Button
                      className="btn-rounded ml-3"
                      color="primary"
                      onClick={fetchData}
                    >
                      <span className="align-middle">Apply</span>
                    </Button>
                  </Row>
                  <div className={s.rechartsBlock}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={timeData}
                    />
                  </div>
                </Widget>
              </Col>
            </Row>
          </Col>
        </TabPane>
      </TabContent>
    </Widget>
  );
}

import React from "react";
import {
  Table,
  Button,
  Input,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
export default function PowerConsumptionTable(props) {
  const {
    powerConsumptionTableData,
    currentPage,
    handlePageInput,
    totalNumberOfRows,
    handleNextPage,
    maxPage,
    inputPage,
    handlePreviousPage,
    setInputPage,
  } = props;

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Asset Name</th>
            <th>Power Consumption</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {powerConsumptionTableData.map((item, index) => {
            return (
              <tr key={index}>
                <td>The Pulse</td>
                <td>{item.Asset1} kW</td>
                <td>{item.jsTime}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div
        className="pagination-buttons"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
          gap: "10px",
        }}
      >
        <Button
          color="primary"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </Button>

        <Input
          type="number"
          value={inputPage}
          onChange={(e) =>
            setInputPage(
              Math.max(
                1,
                Math.min(Number(e.target.value), maxPage(totalNumberOfRows))
              )
            )
          } // Ensures input stays within bounds
          onKeyPress={handlePageInput}
          min="1"
          max={maxPage(totalNumberOfRows)}
          style={{ width: "60px", margin: "0 10px", textAlign: "center" }}
        />
        <Button color="primary" onClick={handlePageInput}>
          Go
        </Button>

        <Button
          color="primary"
          onClick={handleNextPage}
          disabled={currentPage === maxPage(totalNumberOfRows)}
        >
          Next
        </Button>
      </div>
    </>
  );
}

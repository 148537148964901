import React, { useState } from "react";
import axios from "axios";
import {
  Form,
  FormFeedback,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
} from "reactstrap";
export default function OtpModal(props) {
  const { toggleLoginModal, loginModal, doLogin } = props;
  const [otp, setOtp] = useState("");
  const [loadingVerifyOtp, setLoadingVerifyOtp] = useState(false);
  const [otpValidated, setOtpValidated] = useState(undefined);
  function handleOtpChange(e) {
    setOtp(e.target.value);
  }
  async function handleConfirmOtp(event) {
    if (otp.length != 6) return;
    const response = await axios.post(
      "/multi_factor_authentication/validatePasscode",
      {
        otp: otp,
      }
    );
    if (response.status === 200) {
      if (response.data) {
        doLogin(event);
      } else {
        setOtp("");
      }
      setOtpValidated(response.data);
    }
  }
  return (
    <Modal isOpen={loginModal} toggle={toggleLoginModal}>
      <ModalHeader toggle={toggleLoginModal}>
        Please Enter your 6 digit OTP from Google Authenticator
      </ModalHeader>
      <ModalBody>
        <Input
          id="otp"
          className="input-transparent pl-3"
          value={otp}
          onChange={(event) => handleOtpChange(event)}
          name="otp"
          placeholder="6 Digit OTP"
          invalid={otpValidated === false}
          valid={otpValidated}
        />
        {/* {otpValidated !== undefined && otpValidated === false ? ( */}
        <FormFeedback invalid>Invalid OTP. Please try again.</FormFeedback>
        <FormFeedback valid>OTP Validation Success. Logging In.</FormFeedback>
        {/* ) : null} */}
      </ModalBody>
      <ModalFooter>
        {otp.length === 6 && !otpValidated ? (
          <Button color="secondary" onClick={handleConfirmOtp}>
            Confirm OTP
          </Button>
        ) : null}
      </ModalFooter>
    </Modal>
  );
}

import React, { useState, useEffect } from "react";
import Widget from "../../components/Widget/Widget.js";
import LookaheadComponent from "./components/LookaheadComponents/Lookahead";
import DatePicker from "react-datepicker";
import moment from "moment";

const Lookahead = function () {
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));

  const handleDateChange = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setDate(formattedDate);
  };

  return (
    <div>
      <div className="mb-3" style={{
        width: "30%",
        position: "relative",
        zIndex: 999,
      }}>
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={date ? new Date(date) : null}
          onChange={handleDateChange}
        />
      </div>

      {date && (
        <>
          <div className="mb-3">
            <Widget>
              <LookaheadComponent date={date} key={`date-${date}`} />
            </Widget>
          </div>
        </>
      )}
    </div>
  );
};

export default Lookahead;
import React from "react";
import Select from "react-select";
import { batteryOptions } from "./Constants";

function FilterBatteryGroup(props) {
  const { assetOptions, setAssetOptions } = props;
  return (
    <Select
      defaultValue={assetOptions}
      options={batteryOptions}
      onChange={setAssetOptions}
    />
  );
}

export default FilterBatteryGroup;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ClientComponents_FilterHeaderRow__66Lgk {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  padding: 20px 30px; }\n\n.ClientComponents_HeaderTimeFilter__1CTzu {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  grid-column-gap: 10px;\n  column-gap: 10px;\n  width: 100%; }\n\n.ClientComponents_HeaderBatteryGroupFilter__2La53 {\n  width: 300px; }\n\n.ClientComponents_LineChartContainer__1ganu {\n  height: 25rem; }\n", "",{"version":3,"sources":["webpack://src/pages/tables/components/ClientDashboardComponents/ClientComponents.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB,EAAA;;AAEpB;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,qBAAgB;EAAhB,gBAAgB;EAChB,WAAW,EAAA;;AAEb;EACE,YAAY,EAAA;;AAEd;EACE,aAAa,EAAA","sourcesContent":[".FilterHeaderRow {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  padding: 20px 30px;\n}\n.HeaderTimeFilter {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  column-gap: 10px;\n  width: 100%;\n}\n.HeaderBatteryGroupFilter {\n  width: 300px;\n}\n.LineChartContainer {\n  height: 25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FilterHeaderRow": "ClientComponents_FilterHeaderRow__66Lgk",
	"HeaderTimeFilter": "ClientComponents_HeaderTimeFilter__1CTzu",
	"HeaderBatteryGroupFilter": "ClientComponents_HeaderBatteryGroupFilter__2La53",
	"LineChartContainer": "ClientComponents_LineChartContainer__1ganu"
};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Button,
  Spinner,
  Label,
} from "reactstrap";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";
import moment from "moment";

import Widget from "../../../components/Widget/Widget";
import s from "./LineCharts.module.scss";
import "./chartStyles.css";

// taken from example here: https://codesandbox.io/s/highcharts-react-80g4yf?file=/demo.jsx:1004-3916
import dragPanes from "highcharts/modules/drag-panes";
import indicators from "highcharts/indicators/indicators";
import bollingerBands from "highcharts/indicators/bollinger-bands";
import ema from "highcharts/indicators/ema";
import annotationsAdvanced from "highcharts/modules/annotations-advanced";
import fullscreen from "highcharts/modules/full-screen";
import priceIndicator from "highcharts/modules/price-indicator";
import stockTools from "highcharts/modules/stock-tools";
import accessibility from "highcharts/modules/accessibility";

dragPanes(Highcharts);
indicators(Highcharts);
bollingerBands(Highcharts);
ema(Highcharts);
annotationsAdvanced(Highcharts);
fullscreen(Highcharts);
priceIndicator(Highcharts);
stockTools(Highcharts);
accessibility(Highcharts);

function addPopupEvents(chart) {
  var closePopupButtons = document.getElementsByClassName(
    "highcharts-close-popup"
  );
  // Close popup button:
  Highcharts.addEvent(closePopupButtons[0], "click", function () {
    this.parentNode.style.display = "none";
  });

  Highcharts.addEvent(closePopupButtons[1], "click", function () {
    this.parentNode.style.display = "none";
  });

  // Add an indicator from popup
  Highcharts.addEvent(
    document.querySelectorAll(".highcharts-popup-indicators button")[0],
    "click",
    function () {
      var typeSelect = document.querySelectorAll(
          ".highcharts-popup-indicators select"
        )[0],
        type = typeSelect.options[typeSelect.selectedIndex].value,
        period =
          document.querySelectorAll(".highcharts-popup-indicators input")[0]
            .value || 14;

      chart.addSeries({
        linkedTo: "aapl-ohlc",
        type: type,
        params: {
          period: parseInt(period, 10),
        },
      });

      chart.stockToolbar.indicatorsPopupContainer.style.display = "none";
    }
  );

  // Update an annotaiton from popup
  Highcharts.addEvent(
    document.querySelectorAll(".highcharts-popup-annotations button")[0],
    "click",
    function () {
      var strokeWidth = parseInt(
          document.querySelectorAll(
            '.highcharts-popup-annotations input[name="stroke-width"]'
          )[0].value,
          10
        ),
        strokeColor = document.querySelectorAll(
          '.highcharts-popup-annotations input[name="stroke"]'
        )[0].value;

      // Stock/advanced annotations have common options under typeOptions
      if (chart.currentAnnotation.options.typeOptions) {
        chart.currentAnnotation.update({
          typeOptions: {
            lineColor: strokeColor,
            lineWidth: strokeWidth,
            line: {
              strokeWidth: strokeWidth,
              stroke: strokeColor,
            },
            background: {
              strokeWidth: strokeWidth,
              stroke: strokeColor,
            },
            innerBackground: {
              strokeWidth: strokeWidth,
              stroke: strokeColor,
            },
            outerBackground: {
              strokeWidth: strokeWidth,
              stroke: strokeColor,
            },
            connector: {
              strokeWidth: strokeWidth,
              stroke: strokeColor,
            },
          },
        });
      } else {
        // Basic annotations:
        chart.currentAnnotation.update({
          shapes: [
            {
              "stroke-width": strokeWidth,
              stroke: strokeColor,
            },
          ],
          labels: [
            {
              borderWidth: strokeWidth,
              borderColor: strokeColor,
            },
          ],
        });
      }
      chart.stockToolbar.annotationsPopupContainer.style.display = "none";
    }
  );
}
// end of taken from example

export default function PeriodAverage() {
  const [refreshing, setRefreshing] = useState(true);
  const [data, setData] = useState([
    {
      name: "",
      data: [[0]],
    },
  ]);
  const [endPicker1, setEndPicker1] = useState(new Date());
  const [startPicker1, setStartPicker1] = useState(
    moment().subtract(1, "day").toDate()
  );
  const [days1, setDays1] = useState("");
  const [periodType1, setPeriodType1] = useState("");

  const [endPicker2, setEndPicker2] = useState(new Date());
  const [startPicker2, setStartPicker2] = useState(
    moment().subtract(1, "day").toDate()
  );
  const [days2, setDays2] = useState("");
  const [periodType2, setPeriodType2] = useState("");

  const [endPicker3, setEndPicker3] = useState(new Date());
  const [startPicker3, setStartPicker3] = useState(
    moment().subtract(1, "day").toDate()
  );
  const [days3, setDays3] = useState("");
  const [periodType3, setPeriodType3] = useState("");

  const [endPicker4, setEndPicker4] = useState(new Date());
  const [startPicker4, setStartPicker4] = useState(
    moment().subtract(1, "day").toDate()
  );
  const [days4, setDays4] = useState("");
  const [periodType4, setPeriodType4] = useState("");

  const [endPicker5, setEndPicker5] = useState(new Date());
  const [startPicker5, setStartPicker5] = useState(
    moment().subtract(1, "day").toDate()
  );
  const [days5, setDays5] = useState("");
  const [periodType5, setPeriodType5] = useState("");

  const [visibleLines, setVisibleLines] = useState(1);

  const addGroup = () => {
    if (visibleLines < 5) {
      setVisibleLines(visibleLines + 1);
    }
  };

  const removeGroup = () => {
    if (visibleLines > 1) {
      setVisibleLines(visibleLines - 1);
    }
  };

  const fetchData = async () => {
    let requestValue = "";
    requestValue += `${moment(startPicker1).format("YYYYMMDD")}-${moment(endPicker1).format("YYYYMMDD")}-${days1}-${periodType1}`;

    if (visibleLines >= 2) {
      requestValue += `_${moment(startPicker2).format("YYYYMMDD")}-${moment(endPicker2).format("YYYYMMDD")}-${days2}-${periodType2}`;
    }
    if (visibleLines >= 3) {
      requestValue += `_${moment(startPicker3).format("YYYYMMDD")}-${moment(endPicker3).format("YYYYMMDD")}-${days3}-${periodType3}`;
    }
    if (visibleLines >= 4) {
      requestValue += `_${moment(startPicker4).format("YYYYMMDD")}-${moment(endPicker4).format("YYYYMMDD")}-${days4}-${periodType4}`;
    }
    if (visibleLines >= 5) {
      requestValue += `_${moment(startPicker5).format("YYYYMMDD")}-${moment(endPicker5).format("YYYYMMDD")}-${days5}-${periodType5}`;
    }
    setRefreshing(true);
    const response = await axios.get(
      `/buildings/periodaverage/${requestValue}`
    );
    if (response.status === 200) {
      setData(response.data);
      setRefreshing(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const timeData = {
    chart: {
      type: "line",
      events: {
        // this is taken from example
        load: function () {
          addPopupEvents(this);
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: [
        "0000",
        "0030",
        "0100",
        "0130",
        "0200",
        "0230",
        "0300",
        "0330",
        "0400",
        "0430",
        "0500",
        "0530",
        "0600",
        "0630",
        "0700",
        "0730",
        "0800",
        "0830",
        "0900",
        "0930",
        "1000",
        "1030",
        "1100",
        "1130",
        "1200",
        "1230",
        "1300",
        "1330",
        "1400",
        "1430",
        "1500",
        "1530",
        "1600",
        "1630",
        "1700",
        "1730",
        "1800",
        "1830",
        "1900",
        "1930",
        "2000",
        "2030",
        "2100",
        "2130",
        "2200",
        "2230",
        "2300",
        "2330",
      ],
      title: {
        text: undefined,
      },
    },
    yAxis: {
      title: {
        text: undefined,
      },
      min: 0,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
    },

    colors: ["#95d0fc", "#2F33A7", "#00A5FF", "#4d53e0", "#6b859e"],
    series: data,

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            plotOptions: {
              series: {
                marker: {
                  radius: 2.5,
                },
              },
            },
          },
        },
      ],
    },
    stockTools: {
      gui: {
        enabled: false,
      },
    },
    navigationBindings: {
      // this is taken from example
      events: {
        selectButton: function (event) {
          var newClassName = event.button.className + " highcharts-active",
            topButton = event.button.parentNode.parentNode;

          if (topButton.classList.contains("right")) {
            newClassName += " right";
          }

          // If this is a button with sub buttons,
          // change main icon to the current one:
          if (!topButton.classList.contains("highcharts-menu-wrapper")) {
            topButton.className = newClassName;
          }

          // Store info about active button:
          this.chart.activeButton = event.button;
        },
        deselectButton: function (event) {
          event.button.parentNode.parentNode.classList.remove(
            "highcharts-active"
          );

          // Remove info about active button:
          this.chart.activeButton = null;
        },
        showPopup: function (event) {
          if (!this.indicatorsPopupContainer) {
            this.indicatorsPopupContainer = document.getElementsByClassName(
              "highcharts-popup-indicators"
            )[0];
          }

          if (!this.annotationsPopupContainer) {
            this.annotationsPopupContainer = document.getElementsByClassName(
              "highcharts-popup-annotations"
            )[0];
          }

          if (event.formType === "indicators") {
            this.indicatorsPopupContainer.style.display = "block";
          } else if (event.formType === "annotation-toolbar") {
            // If user is still adding an annotation, don't show popup:
            if (!this.chart.activeButton) {
              this.chart.currentAnnotation = event.annotation;
              this.annotationsPopupContainer.style.display = "block";
            }
          }
        },
        closePopup: function () {
          this.indicatorsPopupContainer.style.display = "none";
          this.annotationsPopupContainer.style.display = "none";
        },
      },
    },
  };

  return (
    <Widget className="charts-tabs-widget" style={{ overflow: "auto" }}>
      <TabContent activeTab={1}>
        <TabPane tabId={1}>
          <Col>
            <Row className="mb-4">
              <Col xs={12} lg={12}>
                <Widget>
                  <div className="headline-2 mb-4">
                    Period Average (Database has the data only till 16-05-2023){" "}
                    {refreshing ? (
                      <Spinner size="sm" className="mr-3">
                        Loading...
                      </Spinner>
                    ) : null}
                  </div>
                  <Row className="mb-4">
                    <Label className="m-2" for="days">
                      Days
                    </Label>
                    <select
                      name="days"
                      id="days"
                      value={days1}
                      onChange={(event) => setDays1(event.target.value)}
                    >
                      <option value=""></option>
                      <option value="Single">Single</option>
                      <option value="Multiple">Multiple</option>
                    </select>
                    <Label className="m-2" for="type">
                      Type
                    </Label>
                    <select
                      name="type"
                      id="type"
                      value={periodType1}
                      onChange={(event) => setPeriodType1(event.target.value)}
                    >
                      <option value=""></option>
                      <option value="contingencyReservePrice">CONRESA</option>
                      <option value="uniformSingaporeEnergyPrice">USEP</option>
                      <option value="counterfactualUniformSingaporeEnergyPrice">
                        CUSEP
                      </option>
                      <option value="loadCurtailmentPrice">LCP</option>
                      <option value="totalLoadMW">Total Load</option>
                      <option value="totalCurtailedLoad">TCL</option>
                    </select>
                    <Label className="m-2" for="startDate">
                      From
                    </Label>
                    <Flatpickr
                      id="startDate"
                      name="startDate"
                      className="col-md-2"
                      onChange={(date) => {
                        setStartPicker1(date[0]);
                      }}
                      value={startPicker1}
                    />
                    <Label className="m-2" for="endDate">
                      To
                    </Label>
                    <Flatpickr
                      id="endDate"
                      name="endDate"
                      className="col-md-2"
                      onChange={(date) => setEndPicker1(date[0])}
                      value={endPicker1}
                    />
                  </Row>
                  {visibleLines >= 2 ? (
                    <Row className="mb-4">
                      <Label className="m-2" for="days">
                        Days
                      </Label>
                      <select
                        name="days"
                        id="days"
                        value={days2}
                        onChange={(event) => setDays2(event.target.value)}
                      >
                        <option value=""></option>
                        <option value="Single">Single</option>
                        <option value="Multiple">Multiple</option>
                      </select>
                      <Label className="m-2" for="type">
                        Type
                      </Label>
                      <select
                        name="type"
                        id="type"
                        value={periodType2}
                        onChange={(event) => setPeriodType2(event.target.value)}
                      >
                        <option value=""></option>
                        <option value="contingencyReservePrice">CONRESA</option>
                        <option value="uniformSingaporeEnergyPrice">
                          USEP
                        </option>
                        <option value="counterfactualUniformSingaporeEnergyPrice">
                          CUSEP
                        </option>
                        <option value="loadCurtailmentPrice">LCP</option>
                        <option value="totalLoadMW">Total Load</option>
                        <option value="totalCurtailedLoad">TCL</option>
                      </select>
                      <Label className="m-2" for="startDate">
                        From
                      </Label>
                      <Flatpickr
                        id="startDate"
                        name="startDate"
                        className="col-md-2"
                        onChange={(date) => {
                          setStartPicker2(date[0]);
                        }}
                        value={startPicker2}
                      />
                      <Label className="m-2" for="endDate">
                        To
                      </Label>
                      <Flatpickr
                        id="endDate"
                        name="endDate"
                        className="col-md-2"
                        onChange={(date) => setEndPicker2(date[0])}
                        value={endPicker2}
                      />
                    </Row>
                  ) : (
                    ""
                  )}
                  {visibleLines >= 3 ? (
                    <Row className="mb-4">
                      <Label className="m-2" for="days">
                        Days
                      </Label>
                      <select
                        name="days"
                        id="days"
                        value={days3}
                        onChange={(event) => setDays3(event.target.value)}
                      >
                        <option value=""></option>
                        <option value="Single">Single</option>
                        <option value="Multiple">Multiple</option>
                      </select>
                      <Label className="m-2" for="type">
                        Type
                      </Label>
                      <select
                        name="type"
                        id="type"
                        value={periodType3}
                        onChange={(event) => setPeriodType3(event.target.value)}
                      >
                        <option value=""></option>
                        <option value="contingencyReservePrice">CONRESA</option>
                        <option value="uniformSingaporeEnergyPrice">
                          USEP
                        </option>
                        <option value="counterfactualUniformSingaporeEnergyPrice">
                          CUSEP
                        </option>
                        <option value="loadCurtailmentPrice">LCP</option>
                        <option value="totalLoadMW">Total Load</option>
                        <option value="totalCurtailedLoad">TCL</option>
                      </select>
                      <Label className="m-2" for="startDate">
                        From
                      </Label>
                      <Flatpickr
                        id="startDate"
                        name="startDate"
                        className="col-md-2"
                        onChange={(date) => {
                          setStartPicker3(date[0]);
                        }}
                        value={startPicker3}
                      />
                      <Label className="m-2" for="endDate">
                        To
                      </Label>
                      <Flatpickr
                        id="endDate"
                        name="endDate"
                        className="col-md-2"
                        onChange={(date) => setEndPicker3(date[0])}
                        value={endPicker3}
                      />
                    </Row>
                  ) : (
                    ""
                  )}
                  {visibleLines >= 4 ? (
                    <Row className="mb-4">
                      <Label className="m-2" for="days">
                        Days
                      </Label>
                      <select
                        name="days"
                        id="days"
                        value={days4}
                        onChange={(event) => setDays4(event.target.value)}
                      >
                        <option value=""></option>
                        <option value="Single">Single</option>
                        <option value="Multiple">Multiple</option>
                      </select>
                      <Label className="m-2" for="type">
                        Type
                      </Label>
                      <select
                        name="type"
                        id="type"
                        value={periodType4}
                        onChange={(event) => setPeriodType4(event.target.value)}
                      >
                        <option value=""></option>
                        <option value="contingencyReservePrice">CONRESA</option>
                        <option value="uniformSingaporeEnergyPrice">
                          USEP
                        </option>
                        <option value="counterfactualUniformSingaporeEnergyPrice">
                          CUSEP
                        </option>
                        <option value="loadCurtailmentPrice">LCP</option>
                        <option value="totalLoadMW">Total Load</option>
                        <option value="totalCurtailedLoad">TCL</option>
                      </select>
                      <Label className="m-2" for="startDate">
                        From
                      </Label>
                      <Flatpickr
                        id="startDate"
                        name="startDate"
                        className="col-md-2"
                        onChange={(date) => {
                          setStartPicker4(date[0]);
                        }}
                        value={startPicker4}
                      />
                      <Label className="m-2" for="endDate">
                        To
                      </Label>
                      <Flatpickr
                        id="endDate"
                        name="endDate"
                        className="col-md-2"
                        onChange={(date) => setEndPicker4(date[0])}
                        value={endPicker4}
                      />
                    </Row>
                  ) : (
                    ""
                  )}
                  {visibleLines >= 5 ? (
                    <Row className="mb-4">
                      <Label className="m-2" for="days">
                        Days
                      </Label>
                      <select
                        name="days"
                        id="days"
                        value={days5}
                        onChange={(event) => setDays5(event.target.value)}
                      >
                        <option value=""></option>
                        <option value="Single">Single</option>
                        <option value="Multiple">Multiple</option>
                      </select>
                      <Label className="m-2" for="type">
                        Type
                      </Label>
                      <select
                        name="type"
                        id="type"
                        value={periodType5}
                        onChange={(event) => setPeriodType5(event.target.value)}
                      >
                        <option value=""></option>
                        <option value="contingencyReservePrice">CONRESA</option>
                        <option value="uniformSingaporeEnergyPrice">
                          USEP
                        </option>
                        <option value="counterfactualUniformSingaporeEnergyPrice">
                          CUSEP
                        </option>
                        <option value="loadCurtailmentPrice">LCP</option>
                        <option value="totalLoadMW">Total Load</option>
                        <option value="totalCurtailedLoad">TCL</option>
                      </select>
                      <Label className="m-2" for="startDate">
                        From
                      </Label>
                      <Flatpickr
                        id="startDate"
                        name="startDate"
                        className="col-md-2"
                        onChange={(date) => {
                          setStartPicker5(date[0]);
                        }}
                        value={startPicker5}
                      />
                      <Label className="m-2" for="endDate">
                        To
                      </Label>
                      <Flatpickr
                        id="endDate"
                        name="endDate"
                        className="col-md-2"
                        onChange={(date) => setEndPicker5(date[0])}
                        value={endPicker5}
                      />
                    </Row>
                  ) : (
                    ""
                  )}
                  <Row className="mb-4">
                    <Button
                      className="btn-rounded m-3"
                      color={visibleLines === 5 ? "danger" : "info"}
                      disabled={visibleLines === 5}
                      onClick={addGroup}
                    >
                      <span className="align-middle">+</span>
                    </Button>
                    <Button
                      className="btn-rounded m-3"
                      color={visibleLines === 1 ? "danger" : "info"}
                      disabled={visibleLines === 1}
                      onClick={removeGroup}
                    >
                      <span className="align-middle">-</span>
                    </Button>
                    <Button
                      className="btn-rounded m-3"
                      color="primary"
                      onClick={fetchData}
                    >
                      <span className="align-middle">Submit</span>
                    </Button>
                  </Row>
                  {/* this is taken from example */}
                  <div
                    class="chart-wrapper"
                    // className={s.rechartsBlock}
                  >
                    <div class="highcharts-popup highcharts-popup-indicators">
                      <span class="highcharts-close-popup">&times;</span>
                      <div class="highcharts-popup-wrapper">
                        <label for="indicator-list">Indicator</label>
                        <select name="indicator-list">
                          <option value="sma">SMA</option>
                          <option value="ema">EMA</option>
                          <option value="bb">Bollinger bands</option>
                        </select>
                        <label for="stroke">Period</label>
                        <input type="text" name="period" value="14" />
                      </div>
                      <button>Add</button>
                    </div>
                    <div class="highcharts-popup highcharts-popup-annotations">
                      <span class="highcharts-close-popup">&times;</span>
                      <div class="highcharts-popup-wrapper">
                        <label for="stroke">Color</label>
                        <input type="text" name="stroke" />
                        <label for="stroke-width">Width</label>
                        <input type="text" name="stroke-width" />
                      </div>
                      <button>Save</button>
                    </div>
                    <div class="highcharts-stocktools-wrapper highcharts-bindings-container highcharts-bindings-wrapper">
                      <div class="highcharts-menu-wrapper">
                        <ul class="highcharts-stocktools-toolbar stocktools-toolbar">
                          {/* <li class="highcharts-indicators" title="Indicators">
                            <span class="highcharts-menu-item-btn"></span>
                            <span class="highcharts-menu-item-title">Indicators</span>
                          </li> */}
                          <li
                            class="highcharts-label-annotation"
                            title="Simple shapes"
                          >
                            <span class="highcharts-menu-item-btn"></span>
                            <span class="highcharts-menu-item-title">
                              Shapes
                            </span>
                            <span class="highcharts-submenu-item-arrow highcharts-arrow-right"></span>
                            <ul class="highcharts-submenu-wrapper">
                              <li
                                class="highcharts-label-annotation"
                                title="Label"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Label
                                </span>
                              </li>
                              <li
                                class="highcharts-circle-annotation"
                                title="Circle"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Circle
                                </span>
                              </li>
                              <li
                                class="highcharts-rectangle-annotation "
                                title="Rectangle"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Rectangle
                                </span>
                              </li>
                              <li
                                class="highcharts-ellipse-annotation"
                                title="Ellipse"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Ellipse
                                </span>
                              </li>
                            </ul>
                          </li>
                          <li class="highcharts-segment" title="Lines">
                            <span class="highcharts-menu-item-btn"></span>
                            <span class="highcharts-menu-item-title">
                              Lines
                            </span>
                            <span class="highcharts-submenu-item-arrow highcharts-arrow-right"></span>
                            <ul class="highcharts-submenu-wrapper">
                              <li class="highcharts-segment" title="Segment">
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Segment
                                </span>
                              </li>
                              <li
                                class="highcharts-arrow-segment"
                                title="Arrow segment"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Arrow segment
                                </span>
                              </li>
                              <li class="highcharts-ray" title="Ray">
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Ray
                                </span>
                              </li>
                              <li
                                class="highcharts-arrow-ray"
                                title="Arrow ray"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Arrow ray
                                </span>
                              </li>
                              <li class="highcharts-infinity-line" title="Line">
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Line
                                </span>
                              </li>
                              <li
                                class="highcharts-arrow-infinity-line"
                                title="Arrow line"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Arrow
                                </span>
                              </li>
                              <li
                                class="highcharts-horizontal-line"
                                title="Horizontal line"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Horizontal
                                </span>
                              </li>
                              <li
                                class="highcharts-vertical-line"
                                title="Vertical line"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Vertical
                                </span>
                              </li>
                            </ul>
                          </li>
                          <li class="highcharts-elliott3" title="Crooked lines">
                            <span class="highcharts-menu-item-btn"></span>
                            <span class="highcharts-menu-item-title">
                              Crooked lines
                            </span>
                            <span class="highcharts-submenu-item-arrow highcharts-arrow-right"></span>
                            <ul class="highcharts-submenu-wrapper">
                              <li
                                class="highcharts-elliott3"
                                title="Elliott 3 line"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Elliot 3
                                </span>
                              </li>
                              <li
                                class="highcharts-elliott5"
                                title="Elliott 5 line"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Elliot 5
                                </span>
                              </li>
                              <li
                                class="highcharts-crooked3"
                                title="Crooked 3 line"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Crooked 3
                                </span>
                              </li>
                              <li
                                class="highcharts-crooked5"
                                title="Crooked 5 line"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Crooked 5
                                </span>
                              </li>
                            </ul>
                          </li>
                          <li class="highcharts-measure-xy" title="Measure">
                            <span class="highcharts-menu-item-btn"></span>
                            <span class="highcharts-menu-item-title">
                              Measure
                            </span>
                            <span class="highcharts-submenu-item-arrow highcharts-arrow-right"></span>
                            <ul class="highcharts-submenu-wrapper">
                              <li
                                class="highcharts-measure-xy"
                                title="Measure XY"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Measure XY
                                </span>
                              </li>
                              <li
                                class="highcharts-measure-x"
                                title="Measure X"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Measure X
                                </span>
                              </li>
                              <li
                                class="highcharts-measure-y"
                                title="Measure Y"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Measure Y
                                </span>
                              </li>
                            </ul>
                          </li>
                          <li class="highcharts-fibonacci" title="Advanced">
                            <span class="highcharts-menu-item-btn"></span>
                            <span class="highcharts-menu-item-title">
                              Advanced
                            </span>
                            <span class="highcharts-submenu-item-arrow highcharts-arrow-right"></span>
                            <ul class="highcharts-submenu-wrapper">
                              <li
                                class="highcharts-fibonacci"
                                title="Fibonacci"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Fibonacci
                                </span>
                              </li>
                              <li
                                class="highcharts-pitchfork"
                                title="Pitchfork"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Pitchfork
                                </span>
                              </li>
                              <li
                                class="highcharts-parallel-channel"
                                title="Parallel channel"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Parallel channel
                                </span>
                              </li>
                            </ul>
                          </li>
                          <li
                            class="highcharts-vertical-counter"
                            title="Vertical labels"
                          >
                            <span class="highcharts-menu-item-btn"></span>
                            <span class="highcharts-menu-item-title">
                              Counters
                            </span>
                            <span class="highcharts-submenu-item-arrow highcharts-arrow-right"></span>
                            <ul class="highcharts-submenu-wrapper">
                              <li
                                class="highcharts-vertical-counter"
                                title="Vertical counter"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Counter
                                </span>
                              </li>
                              <li
                                class="highcharts-vertical-label"
                                title="Vertical label"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Label
                                </span>
                              </li>
                              <li
                                class="highcharts-vertical-arrow"
                                title="Vertical arrow"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Arrow
                                </span>
                              </li>
                            </ul>
                          </li>
                          <li class="highcharts-flag-circlepin" title="Flags">
                            <span class="highcharts-menu-item-btn"></span>
                            <span class="highcharts-menu-item-title">
                              Flags
                            </span>
                            <span class="highcharts-submenu-item-arrow highcharts-arrow-right"></span>
                            <ul class="highcharts-submenu-wrapper">
                              <li
                                class="highcharts-flag-circlepin"
                                title="Flag circle"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Circle
                                </span>
                              </li>
                              <li
                                class="highcharts-flag-diamondpin"
                                title="Flag diamond"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Diamond
                                </span>
                              </li>
                              <li
                                class="highcharts-flag-squarepin"
                                title="Flag square"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Square
                                </span>
                              </li>
                              <li
                                class="highcharts-flag-simplepin"
                                title="Flag simple"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">
                                  Simple
                                </span>
                              </li>
                            </ul>
                          </li>
                          {/* <li class="highcharts-series-type-ohlc" title="Type change">
                            <span class="highcharts-menu-item-btn"></span>
                            <span class="highcharts-menu-item-title">Series type</span>
                            <span class="highcharts-submenu-item-arrow highcharts-arrow-right"></span>
                            <ul class="highcharts-submenu-wrapper">
                              <li class="highcharts-series-type-ohlc" title="OHLC">
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">OHLC</span>
                              </li>
                              <li class="highcharts-series-type-line" title="Line">
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">Line</span>
                              </li>
                              <li
                                class="highcharts-series-type-candlestick"
                                title="Candlestick"
                              >
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">Candlestick</span>
                              </li>
                            </ul>
                          </li> */}
                          {/* <li class="highcharts-save-chart right" title="Save chart">
                            <span class="highcharts-menu-item-btn"></span>
                          </li> */}
                          <li
                            class="highcharts-full-screen right"
                            title="Fullscreen"
                          >
                            <span class="highcharts-menu-item-btn"></span>
                          </li>
                          {/* <li class="highcharts-zoom-x right" title="Zoom change">
                            <span class="highcharts-menu-item-btn"></span>
                            <span class="highcharts-submenu-item-arrow highcharts-arrow-right"></span>
                            <ul class="highcharts-submenu-wrapper">
                              <li class="highcharts-zoom-x" title="Zoom X">
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">Zoom X</span>
                              </li>
                              <li class="highcharts-zoom-y" title="Zoom Y">
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">Zoom Y</span>
                              </li>
                              <li class="highcharts-zoom-xy" title="Zooom XY">
                                <span class="highcharts-menu-item-btn"></span>
                                <span class="highcharts-menu-item-title">Zoom XY</span>
                              </li>
                            </ul>
                          </li> */}
                          {/* <li
                            class="highcharts-current-price-indicator right"
                            title="Current Price Indicators"
                          >
                            <span class="highcharts-menu-item-btn"></span>
                          </li>
                          <li
                            class="highcharts-toggle-annotations right"
                            title="Toggle annotations"
                          >
                            <span class="highcharts-menu-item-btn"></span>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                    {/* </Row> */}
                    <HighchartsReact
                      containerProps={{ className: "chart" }}
                      highcharts={Highcharts}
                      options={timeData}
                    />
                  </div>
                </Widget>
              </Col>
            </Row>
          </Col>
        </TabPane>
      </TabContent>
    </Widget>
  );
}

const usersFields = {
  id: {
    type: "id",
    label: "ID",
  },
  fullName: {
    type: "string",
    label: "Full Name",
  },
  firstName: {
    type: "string",
    label: "First Name",
  },
  lastName: {
    type: "string",
    label: "Last Name",
  },
  phoneNumber: {
    type: "string",
    label: "Phone Number",
  },
  email: {
    type: "string",
    label: "E-mail",
  },
  disabled: {
    type: "boolean",
    label: "Disabled",
  },
  role: {
    type: "enum",
    label: "Role",
    options: [
      { value: "admin", label: "admin" },
      { value: "trader", label: "trader" },
      { value: "iot_engineer", label: "iot_engineer" },
      { value: "read_only_user", label: "read_only_user" },
    ],
  },
  Advisories_acl: {
    type: "enum",
    label: "Advisories",
    options: [
      { value: 0, label: "No access" },
      { value: 1, label: "Read Level" },
      { value: 2, label: "Write Level" },
      { value: 3, label: "Update Level" },
      { value: 4, label: "Delete Level" },
    ],
  },
  MCR_acl: {
    type: "enum",
    label: "MCR",
    options: [
      { value: 0, label: "No access" },
      { value: 1, label: "Read Level" },
      { value: 2, label: "Write Level" },
      { value: 3, label: "Update Level" },
      { value: 4, label: "Delete Level" },
    ],
  },
  MCRAheadRun_acl: {
    type: "enum",
    label: "MCR Ahead Run",
    options: [
      { value: 0, label: "No access" },
      { value: 1, label: "Read Level" },
      { value: 2, label: "Write Level" },
      { value: 3, label: "Update Level" },
      { value: 4, label: "Delete Level" },
    ],
  },
  Offers_acl: {
    type: "enum",
    label: "Offers",
    options: [
      { value: 0, label: "No access" },
      { value: 1, label: "Read Level" },
      { value: 2, label: "Write Level" },
      { value: 3, label: "Update Level" },
      { value: 4, label: "Delete Level" },
    ],
  },
  Bids_acl: {
    type: "enum",
    label: "Bids",
    options: [
      { value: 0, label: "No access" },
      { value: 1, label: "Read Level" },
      { value: 2, label: "Write Level" },
      { value: 3, label: "Update Level" },
      { value: 4, label: "Delete Level" },
    ],
  },
  Units_acl: {
    type: "enum",
    label: "Units",
    options: [
      { value: 0, label: "No access" },
      { value: 1, label: "Read Level" },
      { value: 2, label: "Write Level" },
      { value: 3, label: "Update Level" },
      { value: 4, label: "Delete Level" },
    ],
  },
  Groups_acl: {
    type: "enum",
    label: "Groups",
    options: [
      { value: 0, label: "No access" },
      { value: 1, label: "Read Level" },
      { value: 2, label: "Write Level" },
      { value: 3, label: "Update Level" },
      { value: 4, label: "Delete Level" },
    ],
  },
  Buildings_acl: {
    type: "enum",
    label: "Buildings",
    options: [
      { value: 0, label: "No access" },
      { value: 1, label: "Read Level" },
      { value: 2, label: "Write Level" },
      { value: 3, label: "Update Level" },
      { value: 4, label: "Delete Level" },
    ],
  },
  Facilities_acl: {
    type: "enum",
    label: "Facilities",
    options: [
      { value: 0, label: "No access" },
      { value: 1, label: "Read Level" },
      { value: 2, label: "Write Level" },
      { value: 3, label: "Update Level" },
      { value: 4, label: "Delete Level" },
    ],
  },
  PeriodAverage_acl: {
    type: "enum",
    label: "Period Average",
    options: [
      { value: 0, label: "No access" },
      { value: 1, label: "Read Level" },
      { value: 2, label: "Write Level" },
      { value: 3, label: "Update Level" },
      { value: 4, label: "Delete Level" },
    ],
  },
  Calendar_acl: {
    type: "enum",
    label: "Calendar",
    options: [
      { value: 0, label: "No access" },
      { value: 1, label: "Read Level" },
      { value: 2, label: "Write Level" },
      { value: 3, label: "Update Level" },
      { value: 4, label: "Delete Level" },
    ],
  },
  Usage_acl: {
    type: "enum",
    label: "Usage",
    options: [
      { value: 0, label: "No access" },
      { value: 1, label: "Read Level" },
      { value: 2, label: "Write Level" },
      { value: 3, label: "Update Level" },
      { value: 4, label: "Delete Level" },
    ],
  },
  UserManagement_acl: {
    type: "enum",
    label: "User Management",
    options: [
      { value: 0, label: "No access" },
      { value: 1, label: "Read Level" },
      { value: 2, label: "Write Level" },
      { value: 3, label: "Update Level" },
      { value: 4, label: "Delete Level" },
    ],
  },
  ChangePassword_acl: {
    type: "enum",
    label: "Change Password",
    options: [
      { value: 0, label: "No access" },
      { value: 1, label: "Read Level" },
      { value: 2, label: "Write Level" },
      { value: 3, label: "Update Level" },
      { value: 4, label: "Delete Level" },
    ],
  },
  Maintenances_acl: {
    type: "enum",
    label: "Maintenances",
    options: [
      { value: 0, label: "No access" },
      { value: 1, label: "Read Level" },
      { value: 2, label: "Write Level" },
      { value: 3, label: "Update Level" },
      { value: 4, label: "Delete Level" },
    ],
  },
  avatar: {
    type: "images",
    label: "Avatar",
  },
  password: {
    type: "string",
    label: "Password",
  },
  emailVerified: {
    type: "boolean",
    label: "emailVerified",
  },
  emailVerificationToken: {
    type: "string",
    label: "emailVerificationToken",
  },
  emailVerificationTokenExpiresAt: {
    type: "datetime",
    label: "emailVerificationExpiresAt",
  },
  passwordResetToken: {
    type: "string",
    label: "passwordResetToken",
  },
  passwordResetTokenExpiresAt: {
    type: "datetime",
    label: "passwordResetTokenExpiresAt",
  },
};

export default usersFields;

import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import styles from "./offers.module.scss";
import { returnTimeFormatting } from "../Helpers";
import { Redirect } from "react-router-dom";

export default function OfferModal(props) {
  const {
    offerModalIsOpen,
    toggleOfferModal,
    periodPairs,
    offerType,
    offerDate,
    facility,
    loading,
    setLoading,
  } = props;
  function handleConfirmOffer() {
    document.getElementById("hiddenSubmitOfferButton").click();
  }

  return (
    <Modal
      isOpen={offerModalIsOpen}
      toggle={toggleOfferModal}
      onClosed={() => {
        setLoading(false);
      }}
    >
      <ModalHeader toggle={toggleOfferModal}>Confirm Offer?</ModalHeader>
      <ModalBody className={styles.OffersModalBody}>
        <div className={styles.OffersModalBodyRow}>
          <div>Offer Type:</div>
          <div>{offerType}</div>
        </div>
        <div className={styles.OffersModalBodyRow}>
          <div>Offer Date:</div>
          <div>{returnTimeFormatting(offerDate)}</div>
        </div>
        <div className={styles.OffersModalBodyRow}>
          <div>Facility:</div>
          <div>{facility}</div>
        </div>
        <div className={styles.OffersModalBodyRow}>
          <div>From Period:</div>
          <div>{periodPairs[0].periodFrom}</div>
        </div>
        <div className={styles.OffersModalBodyRow}>
          <div>To Period:</div>
          <div>{periodPairs[0].periodTo}</div>
        </div>
        <div className={styles.OffersModalBodyRow}>
          <div>Quantity 1:</div>
          <div>
            {periodPairs[0].qp[0].q}MW @ ${periodPairs[0].qp[0].p}/MW
          </div>
        </div>
        <div className={styles.OffersModalBodyRow}>
          <div>Quantity 2:</div>
          <div>
            {periodPairs[0].qp[1].q}MW @ ${periodPairs[0].qp[1].p}/MW
          </div>
        </div>
        <div className={styles.OffersModalBodyRow}>
          <div>Quantity 3:</div>
          <div>
            {periodPairs[0].qp[2].q}MW @ ${periodPairs[0].qp[2].p}/MW
          </div>
        </div>
        <div className={styles.OffersModalBodyRow}>
          <div>Quantity 4:</div>
          <div>
            {periodPairs[0].qp[3].q}MW @ ${periodPairs[0].qp[3].p}/MW
          </div>
        </div>
        <div className={styles.OffersModalBodyRow}>
          <div>Quantity 5:</div>
          <div>
            {periodPairs[0].qp[4].q}MW @ ${periodPairs[0].qp[4].p}/MW
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" type="button" onClick={handleConfirmOffer}>
          Confirm Offer{" "}
          {loading ? <Spinner size="sm" className="mr-3 mt-1" /> : null}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

// ** Initial State
const initialState = {
  units: [],
  selectedUnit: {},
  fetching: false,
};

const unit = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_UNITS":
      return { ...state, units: action.units, fetching: action.fetching };
    case "FETCH_UNIT_STATE":
      return { ...state, fetching: action.fetching };
    case "ADD_UNIT":
      return { ...state };
    case "REMOVE_UNIT":
      return { ...state };
    case "UPDATE_UNIT":
      return { ...state };
    case "SELECT_UNIT":
      return { ...state, selectedUnit: action.unit };
    default:
      return state;
  }
};

export default unit;

import React, { useEffect, useState, PureComponent } from "react";
import FilterAssetGroup from "./ChartComponents/FilterAssetGroup.js";
import CustomChart from "./ChartComponents/CustomChart.js";
import styles from "./ClientComponents.module.scss";
import axios from "axios";
import moment from "moment";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { tableNames, months } from "../MonitoringComponents/Constants";
import { Spinner } from "reactstrap";

const getStatusClass = (status) => {
  if (status === -1) return styles.charging;
  if (status === 1) return styles.discharging;
  return styles.idle;
};

function BatteryStatusWidget() {
  const [batteryDetails, setBatteryDetails] = useState({});
  const [currentTime, setCurrentTime] = useState(moment());
  async function getTableDataWrapper() {
    const response = await axios.get(
      `/batteries/getBatteryTableDataClient?batteryTableName=${tableNames[selectedBattery]}`
    );

    if (response.status === 200) {
      setBatteryDetails(convertArrayToObjects(response.data));
    }
  }

  function convertArrayToObjects(array) {
    const objects = {};
    for (const item of array) {
      objects[item.tag] = item.value;
    }

    // convert SOC to actual capacity
    objects["SOC"] = ((objects["Average SOC (%)"] / 100) * 271).toFixed(2);
    objects["PriorityLevel"] = varPriority[objects["varPriority"]];
    return objects;
  }

  let selectedBattery = "Asiagreen Battery 1";

  // allow the page to refresh itself
  useEffect(() => {
    const fn = async () => {
      try {
        await getTableDataWrapper();
        setCurrentTime(moment());
      } catch (error) {
        console.error(error);
      }
    }
    fn();
    const result = setInterval(fn, 30000);
    return () => {
      clearInterval(result);
    };
  }, []);

  let varPriority = ["Non-Critical", "Critical"];

  return (
    <div>
      <div
        className={`${styles.status} ${getStatusClass(batteryDetails["CnD"])}`}
      >
        {Number(batteryDetails["CnD"]) === -1
          ? "Charging"
          : Number(batteryDetails["CnD"]) === 1
          ? "Discharging"
          : "Idle"}
      </div>
      <div className={styles.GaugeContainer}>
        <div className={styles.GaugeProgressWrapper}>
          <div className={styles.GaugeHeader}>SOC</div>
          <div className={styles.GaugeProgressContainer}>
            {batteryDetails["Average SOC (%)"] ? (
              <CircularProgressbar
                value={Number(batteryDetails["Average SOC (%)"])}
                text={`${batteryDetails["Average SOC (%)"]}%`}
              />
            ) : (
              <Spinner />
            )}
          </div>
        </div>
        <div className={styles.GaugeProgressWrapper}>
          <div className={styles.GaugeHeader}>SOH</div>
          <div className={styles.GaugeProgressContainer}>
            {batteryDetails["Average SOH (%)"] ? (
              <CircularProgressbar
                value={Number(batteryDetails["Average SOH (%)"])}
                text={`${batteryDetails["Average SOH (%)"]}%`}
              />
            ) : (
              <Spinner />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BatteryStatusWidget;

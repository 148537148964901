export default {
  tableData: [
    {
      id: 0,
      label: "Primary",
      classExample: "text-primary",
      hexValue: "#4D53E0",
    },
    {
      id: 1,
      label: "Secondary Red",
      classExample: "text-secondary-red",
      hexValue: "#FF5668",
    },
    {
      id: 2,
      label: "Secondary Yellow",
      classExample: "text-secondary-yellow",
      hexValue: "#FFC405",
    },
    {
      id: 3,
      label: "Secondary Cyan",
      classExample: "text-secondary-cyan",
      hexValue: "#41D5E2",
    },
    {
      id: 4,
      label: "Success",
      classExample: "text-success",
      hexValue: "#58CE29",
    },
    {
      id: 5,
      label: "Info",
      classExample: "text-info",
      hexValue: "#00A5FF",
    },
    {
      id: 6,
      label: "Warning",
      classExample: "text-warning",
      hexValue: "#FFA100",
    },
    {
      id: 7,
      label: "Danger",
      classExample: "text-danger",
      hexValue: "#FF4B23",
    },
  ],
};

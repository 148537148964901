import React, { useEffect, useState } from "react";
import styles from "./ClientComponents.module.scss";
import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";
import { tableNames } from "../MonitoringComponents/Constants";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

function BatteryPerformanceWidget() {
  const [batteryDetails, setBatteryDetails] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  async function getTableDataWrapper() {
    const response = await axios.get(
      `/batteries/getPerformanceDataClient?batteryTableName=${tableNames[selectedBattery]}&selectedDate=${selectedDate}`
    );
    if (response.status === 200) {
      setBatteryDetails(response.data);
    }
  }

  let selectedBattery = "Asiagreen Battery 1";
  useEffect(() => {
    getTableDataWrapper();
  }, [selectedDate]);

  return (
    <div className={styles.LineChartContainer}>
      <div className={styles.LineChartDatePickerContainer}>
        <div>Selected Date:</div>
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          onFocus={(e) => (e.target.readOnly = true)}
        />
      </div>
      {batteryDetails.length > 0 ? (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={350}
            data={batteryDetails}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 80,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" angle={-45} textAnchor="end" dy={10} />
            <YAxis unit={"kWh"} />
            <Tooltip />
            <Legend
              verticalAlign="top"
              wrapperStyle={{ paddingTop: 10, paddingBottom: 10 }}
            />
            <Line
              type="monotone"
              dataKey="charge"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
              unit={"kWh"}
            />
            <Line
              type="monotone"
              dataKey="discharge"
              stroke="#82ca9d"
              unit={"kWh"}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : null}
    </div>
  );
}

export default BatteryPerformanceWidget;

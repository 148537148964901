import React, { useEffect, useState } from "react";
import FilterAssetGroup from "./ChartComponents/FilterAssetGroup.js";
import CustomChartTotal from "./ChartComponents/CustomChartTotal.js";
import styles from "./ClientComponents.module.scss";
import axios from "axios";
import { convertUnixToJs } from "./ChartComponents/Helper.js";
import moment from "moment";

function ChartWidgetTotal() {
  // --- Line Chart ---
  // Start and End Time Filter
  const [endPicker, setEndPicker] = useState(moment());

  // Line Chart Data
  const [lineChartData, setLineChartData] = useState([]);

  async function getLineChartDataWrapper() {
    const response = await axios.get(
      "/log_tpc001_ass1/getLineChartData" + "?endTime=" + endPicker.unix()
    );

    if (response.status === 200) {
      const withJsTime = response.data.map((obj) => {
        return {
          total: parseFloat(
            (Number(obj["msb1_value"]) + Number(obj["msb2_value"])).toFixed(2)
          ),
          jsTime: convertUnixToJs(obj.batch),
        };
      });
      setLineChartData(withJsTime);

      return true;
    }
    return false;
  }

  useEffect(() => {
    const result = setInterval(async () => {
      try {
        const powerFetch = await getLineChartDataWrapper();
        if (powerFetch) {
          setEndPicker(moment());
        }
      } catch (error) {
        console.error(error);
      }
    }, 30000);
    return () => {
      clearInterval(result);
    };
  }, [endPicker]);
  useEffect(() => {
    getLineChartDataWrapper();
  }, []);
  // ----- End of Line Chart -----
  return (
    <>
      <div className={styles.FilterHeaderRow}>
        <div className={styles.HeaderTimeFilter}></div>
      </div>
      <div className={styles.LineChartContainer}>
        {lineChartData.length > 0 ? (
          <CustomChartTotal lineChartData={lineChartData} />
        ) : null}
      </div>
    </>
  );
}

export default ChartWidgetTotal;

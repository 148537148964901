import React from "react";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";
import axios from "axios";

export default function DlsResetModal(props) {
  const { toggleDlsResetModal, dlsResetModal } = props;
  async function handleConfirmDlsReset() {
    // Close modal
    toggleDlsResetModal();

    // Send message to AWS IoT Core to charge
    const message = { thingName: "pyc001_bat1" };
    const response = await axios.post(
      "/log_pyc001_bat1/publishDlsResetSettings",
      message
    );
  }
  return (
    <Modal isOpen={dlsResetModal} toggle={toggleDlsResetModal}>
      <ModalHeader toggle={toggleDlsResetModal}>
        Confirm Reset of DLS for The Pulse 001?
      </ModalHeader>

      <ModalFooter>
        <Button color="secondary" onClick={handleConfirmDlsReset}>
          Reset DLS
        </Button>
      </ModalFooter>
    </Modal>
  );
}

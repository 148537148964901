import React, { Fragment } from "react";

import { CardBody, Button } from "reactstrap";

const offer_filters = [
  {
    label: "Pending",
    value: "pending",
    color: "primary",
    className: "styled mb-1",
  },
  {
    label: "Rejected",
    value: "rejected",
    color: "danger",
    className: "styled mb-1",
  },
  {
    label: "Not accepted",
    value: "not-accepted",
    color: "warning",
    className: "styled mb-1",
  },
  {
    label: "Accepted",
    value: "accepted",
    color: "info",
    className: "styled mb-1",
  },
  {
    label: "Validated",
    value: "validated",
    color: "info",
    className: "styled mb-1",
  },
  {
    label: "Not cleared",
    value: "not-cleared",
    color: "info",
    className: "styled mb-1",
  },
  {
    label: "Cleared",
    value: "cleared",
    color: "success",
    className: "styled mb-1",
  },
];

const bid_filters = [
  {
    label: "Pending",
    value: "pending",
    color: "primary",
    className: "styled mb-1",
  },
  {
    label: "Rejected",
    value: "rejected",
    color: "danger",
    className: "styled mb-1",
  },
  {
    label: "Not accepted",
    value: "not-accepted",
    color: "warning",
    className: "styled mb-1",
  },
  {
    label: "Accepted",
    value: "accepted",
    color: "info",
    className: "styled mb-1",
  },
  {
    label: "Validated",
    value: "validated",
    color: "info",
    className: "styled mb-1",
  },
  {
    label: "Not cleared",
    value: "not-cleared",
    color: "info",
    className: "styled mb-1",
  },
  {
    label: "Cleared",
    value: "cleared",
    color: "success",
    className: "styled mb-1",
  },
];

const SidebarRight = (props) => {
  const {
    // handleAddEventSidebar,
    // toggleSidebar,
    updateFilter,
    updateAllFilters,
    offerStore,
    bidStore,
    bidUpdateFilter,
    bidUpdateAllFilters,
    dispatch,
  } = props;

  // const handleAddEventClick = () => {
  //   toggleSidebar(false)
  //   handleAddEventSidebar()
  // }

  return (
    <Fragment>
      <div className="sidebar-wrapper">
        <CardBody>
          <div className="headline-2 text-center my-2">Offer Status</div>
          <div className="form-check checkbox checkbox-success">
            <input
              id="view-all"
              type="checkbox"
              className="styled mb-1"
              label="View All"
              checked={
                offerStore.selectedStatus.length === offer_filters.length
              }
              onChange={(e) => dispatch(updateAllFilters(e.target.checked))}
            />
            <label htmlFor="view-all">View All</label>
          </div>
          <div className="calendar-events-filter">
            {offer_filters.length &&
              offer_filters.map((filter) => {
                return (
                  <div
                    className={`form-check checkbox checkbox-${filter.color}`}
                  >
                    <input
                      id={filter.label}
                      type="checkbox"
                      key={filter.label}
                      label={filter.label}
                      checked={offerStore.selectedStatus.includes(filter.value)}
                      className={filter.className}
                      onChange={(e) => dispatch(updateFilter(filter.value))}
                    />
                    <label htmlFor={filter.label}>{filter.label}</label>
                  </div>
                );
              })}
          </div>
          <div className="headline-2 text-center my-2 mt-4">Bid Status</div>
          <div className="form-check checkbox checkbox-success">
            <input
              id="view-all"
              type="checkbox"
              className="styled mb-1"
              label="View All"
              checked={bidStore.selectedStatus.length === bid_filters.length}
              onChange={(e) => dispatch(bidUpdateAllFilters(e.target.checked))}
            />
            <label htmlFor="view-all">View All</label>
          </div>
          <div className="calendar-events-filter">
            {bid_filters.length &&
              bid_filters.map((filter) => {
                return (
                  <div
                    className={`form-check checkbox checkbox-${filter.color}`}
                  >
                    <input
                      id={filter.label}
                      type="checkbox"
                      key={filter.label}
                      label={filter.label}
                      checked={bidStore.selectedStatus.includes(filter.value)}
                      className={filter.className}
                      onChange={(e) => dispatch(bidUpdateFilter(filter.value))}
                    />
                    <label htmlFor={filter.label}>{filter.label}</label>
                  </div>
                );
              })}
          </div>
        </CardBody>
      </div>
    </Fragment>
  );
};

export default SidebarRight;

import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import moment from "moment";

const Yesterday = () => {
  const [yesterday, setYesterday] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);

  function getCurrentPeriod() {
    const now = moment();
    const startOfDay = now.clone().startOf('day');
    const minutesSinceStartOfDay = now.diff(startOfDay, 'minutes');
    return Math.floor(minutesSinceStartOfDay / 30) + 1;
  }

  async function getYesterday() {
    const response = await axios.get("/prediction/yesterday", {
    });
    if (response.status === 200) {
      let data = response.data.map((d) => {
        let ilMonetised = 0;
        let remainingLoad = parseFloat(d.taken_amount);
        if (remainingLoad > 0) {
          d.offer.forEach((o) => {
            if (remainingLoad === 0) {
              return;
            }
            ilMonetised += o.q * d.Price * 0.5; // d.Price is for MWh; so per period price must be halved
            remainingLoad -= o.q;
          });
        }
        return {
          ...d,
          il_monetised: ilMonetised === 0 ? "-" : "$" + ilMonetised.toFixed(3) + " (" + parseFloat(d.taken_amount) + "MW)",
        };
      });
      setYesterday(data);
      setPage(8 - Math.ceil(getCurrentPeriod() / rowsPerPage));
    }
  }

  useEffect(() => {
    getYesterday();
  }, []);

  const columns = [
    {
      name: "Period",
      label: "Period",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "whether_DR",
      label: "Type Prediction",
      options: {
        filter: false,
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? "DR" : "IL";
        },
      },
    },
    {
      name: "loadCurtailmentPrice",
      label: "Type Actual",
      options: {
        filter: false,
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value > 0 ? "DR" : "IL";
        },
      },
    },
    {
      name: "total_load",
      label: "Load Prediction (MW)",
      options: {
        filter: false,
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (value / 1000).toFixed(4);
        },
      },
    },
    {
      name: "actualLoad",
      label: "Load Actual (MW)",
      options: {
        filter: false,
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (value / 1000).toFixed(4);
        },
      },
    },
    {
      name: "il_monetised",
      label: "IL Monetised",
      options: {
        filter: false,
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value;
        },
      },
    },
    {
      name: "dr_monetised",
      label: "DR Monetised",
      options: {
        filter: false,
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return "-";
        },
      },
    },
  ];

  const options = {
    filter: false,
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [6, 12, 24, 48],
    page: page,
    onChangePage: (currentPage) => {
      setPage(currentPage);
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows);
      setPage(0);
    },
    textLabels: {
      body: {
        noMatch: "Loading...",
      },
    },
  };

  return (
    <div>
      <MUIDataTable
        title={`Yesterday (` + moment().subtract(1, "days").format("DD MMM YYYY") + `)`}
        data={yesterday}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default Yesterday;

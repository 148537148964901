import axios from "axios";

export const fetchFacilities = () => {
  return (dispatch) => {
    axios.get("/facilities").then((response) => {
      dispatch({
        type: "FETCH_FACILITIES",
        facilities: response.data,
      });
    });
  };
};

export const addFacility = (facility) => {
  return (dispatch, getState) => {
    axios.post("/facilities/new", facility).then(() => {
      dispatch({
        type: "ADD_FACILITY",
      });
      dispatch(fetchFacilities());
    });
  };
};

export const updateFacility = (facility) => {
  return (dispatch) => {
    axios.put("/facilities/" + facility.id, facility).then(() => {
      dispatch({
        type: "UPDATE_FACILITY",
      });
      dispatch(fetchFacilities());
    });
  };
};

export const removeFacility = (id) => {
  return (dispatch) => {
    axios.delete("/facilities/" + id).then(() => {
      dispatch({
        type: "REMOVE_FACILITY",
      });
      dispatch(fetchFacilities());
    });
  };
};

export const selectFacility = (facility) => {
  return (dispatch) => {
    dispatch({
      type: "SELECT_FACILITY",
      facility,
    });
  };
};

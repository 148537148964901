import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

function FilterBatteryGroup(props) {
  const { batteryGroups, batteryGroupFilter, setBatteryGroupFilter } = props;
  // Multi Select
  const animatedComponents = makeAnimated();
  // Orders the Fixed options first
  const orderOptions = (values) => {
    return values
      .filter((v) => v.isFixed)
      .concat(values.filter((v) => !v.isFixed));
  };
  // Only Return "All Groups" if "All Groups" is selected
  function returnAllGroups(values) {
    if (values.length <= 1) return values;
    // when you add "All Groups", only want "All Groups" remaining
    if (
      values.some(
        (obj) =>
          obj.label === "All Groups" &&
          values.length > 1 &&
          values[values.length - 1].label === "All Groups"
      )
    ) {
      return values.filter((obj) => obj.label === "All Groups");
    } else {
      // when you add other groups, remove "All Groups"
      return values.filter((obj) => obj.label !== "All Groups");
    }
  }

  function onChangeBatteryGroupFilter(newValue, actionMeta) {
    switch (actionMeta.action) {
      case "remove-value":
      case "pop-value":
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case "clear":
        newValue = batteryGroupFilter.filter((v) => v.isFixed);
        newValue.push({ value: "All Groups", label: "All Groups" });
        break;
    }
    let finalValue = returnAllGroups(newValue);
    setBatteryGroupFilter(finalValue);
  }
  const data = [{ value: "The Pulse", label: "The Pulse" }];
  return (
    <Select
      // onChange={onChangeBatteryGroupFilter}
      closeMenuOnSelect={false}
      // components={animatedComponents}
      defaultValue={data[0]}
      isMulti
      isDisabled
      // options={batteryGroups}
      // value={batteryGroupFilter}
    />
  );
}

export default FilterBatteryGroup;

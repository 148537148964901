import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "reactstrap";
import moment from "moment";

function Revenue() {
  const [estimatedRevenue, setEstimatedRevenue] = useState([]);
  const [selectedDate, setSelectedDate] = useState([]);

  async function getEstimatedRevenueIL() {
    const finalSettlementValues = await axios.get(
      "/revenue/estimatedRevenue?selectedDate=" + moment()
    );

    console.log(finalSettlementValues, "finalSettlementValues");
  }

  useEffect(() => {
    getEstimatedRevenueIL();
  }, []);

  return (
    <div>
      <Button>Get Estimated Returns</Button>
    </div>
  );
}

export default Revenue;

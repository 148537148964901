import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter, Link, Redirect } from "react-router-dom";
import config from "../../../config";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import jwt from "jsonwebtoken";
import { loginUser, receiveToken, doInit } from "../../../actions/auth";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormText,
  Input,
} from "reactstrap";
import Widget from "../../../components/Widget/Widget";
import Footer from "../../../components/Footer/Footer";

import loginImage from "../../../assets/loginImage.svg";
import SofiaLogo from "../../../components/Icons/SofiaLogo";
import GoogleIcon from "../../../components/Icons/AuthIcons/GoogleIcon.js";
import TwitterIcon from "../../../components/Icons/AuthIcons/TwitterIcon.js";
import FacebookIcon from "../../../components/Icons/AuthIcons/FacebookIcon.js";
import GithubIcon from "../../../components/Icons/AuthIcons/GithubIcon.js";
import LinkedinIcon from "../../../components/Icons/AuthIcons/LinkedinIcon.js";
import OtpModal from "./LoginComponents/OtpModal.js";
import axios from "axios";

const Login = (props) => {
  const [state, setState] = useState({
    //email: 'admin@crystalclear.com.sg',
    //password: 'password',
  });

  const [loginModal, setLoginModal] = useState(false);
  function toggleLoginModal() {
    setLoginModal(!loginModal);
  }

  async function handleLoginButton(event) {
    const response = await axios.get("/multi_factor_authentication/checkRole", {
      params: { email: state.email },
    });

    if (response.status === 200 && response.data?.role) {
      if (response.data.role === "admin") {
        toggleLoginModal();
      } else {
        doLogin(event);
      }
    } else {
      alert("Unable to get email");
    }
  }
  const doLogin = (e) => {
    e.preventDefault();
    props.dispatch(loginUser({ password: state.password, email: state.email }));
  };

  const changeCreds = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const token = params.get("token");
    if (token) {
      props.dispatch(receiveToken(token));
      props.dispatch(doInit());
    }
  });

  return (
    <div className="auth-page">
      <Container className="col-12">
        <Row className="d-flex align-items-center">
          <Col xs={12} lg={6} className="left-column">
            <Widget className="widget-auth widget-p-lg">
              <div className="d-flex align-items-center justify-content-between py-3">
                <p className="auth-header mb-0">Login</p>
                <div className="logo-block">
                  <p className="mb-0">Eurieka Platform</p>
                </div>
              </div>
              <form>
                <FormGroup className="my-3">
                  <FormText>Email</FormText>
                  <Input
                    id="email"
                    className="input-transparent pl-3"
                    value={state.email}
                    onChange={(event) => changeCreds(event)}
                    type="email"
                    required
                    name="email"
                    placeholder="Email"
                  />
                </FormGroup>
                <FormGroup className="my-3">
                  <div className="d-flex justify-content-between">
                    <FormText>Password</FormText>
                  </div>
                  <Input
                    id="password"
                    className="input-transparent pl-3"
                    value={state.password}
                    onChange={(event) => changeCreds(event)}
                    type="password"
                    required
                    name="password"
                    placeholder="Password"
                  />
                </FormGroup>
                <div className="bg-widget d-flex justify-content-center">
                  <Button
                    className="rounded-pill my-3"
                    type="button"
                    color="secondary-red"
                    onClick={handleLoginButton}
                  >
                    Login
                  </Button>
                </div>
                {/* <p className="dividing-line my-3">&#8195;Or&#8195;</p>
                <div className="d-flex align-items-center my-3">
                  <p className="social-label mb-0">Login with</p>
                  <div className="socials">
                    <a href="https://flatlogic.com/"><GoogleIcon /></a>
                    <a href="https://flatlogic.com/"><TwitterIcon /></a>
                    <a href="https://flatlogic.com/"><FacebookIcon /></a>
                    <a href="https://flatlogic.com/"><GithubIcon /></a>
                    <a href="https://flatlogic.com/"><LinkedinIcon /></a>
                  </div>
                </div> */}
              </form>
            </Widget>
          </Col>
          <Col xs={0} lg={6} className="right-column">
            <div>
              <img src={loginImage} alt="Error page" />
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
      <OtpModal
        doLogin={doLogin}
        loginModal={loginModal}
        toggleLoginModal={toggleLoginModal}
      />
    </div>
  );
};

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default withRouter(connect(mapStateToProps)(Login));

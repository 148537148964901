import React, { useState, useEffect } from "react";
import { Table, Button, Input } from "reactstrap";
import axios from "axios";
import moment from "moment";
import styles from "../MonitoringComponents/MonitoringComponents.module.scss";
import { thingNames } from "../MonitoringComponents/Constants";

const ErrorsTimeline = () => {
  const [timelineData, setTimelineData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [inputPage, setInputPage] = useState(1);
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(1);
  const rowsPerPage = 5;

  // Get 200 rows, 10 at a time
  async function getActions(currentPage) {
    const response = await axios.get("/actions/getActionsErrorClient", {
      params: {
        page: currentPage,
        batteryTableName: thingNames[selectedBattery],
      },
    });
    if (response.status === 200) {
      setTotalNumberOfRows(response.data.count);
      setTimelineData(response.data.rows);
    }
  }

  let selectedBattery = "Asiagreen Battery 1";
  useEffect(() => {
    getActions(currentPage);
  }, [currentPage, selectedBattery]);

  return (
    <div className={styles.TimelineContainerClient}>
      {timelineData.length > 0 ? (
        <>
          <Table striped>
            <thead>
              <tr>
                <th>Action</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {timelineData
                .slice(
                  (currentPage - 1) * rowsPerPage,
                  currentPage * rowsPerPage
                )
                .map((item, index) => (
                  <tr key={index}>
                    <td className={styles.TextWrap}>{item.event}</td>
                    <td>
                      {moment(item.created_at).format("Do MMMM YYYY, h:mm a")}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      ) : (
        <div className={styles.NoDataMessageClient}>Battery is Healthy!</div>
      )}
    </div>
  );
};

export default ErrorsTimeline;

import React from "react";

const StarIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7077 14.0004C11.6024 14.0004 11.4964 13.9758 11.3991 13.9251L7.99975 12.1491L4.60108 13.9251C4.37575 14.0418 4.10375 14.0218 3.89975 13.8724C3.69442 13.7231 3.59242 13.4704 3.63575 13.2204L4.28308 9.46844L1.53642 6.81177C1.35375 6.6351 1.28775 6.36977 1.36575 6.1271C1.44375 5.88577 1.65242 5.7091 1.90442 5.6731L5.70442 5.1211L7.40308 1.70377C7.62842 1.25044 8.37175 1.25044 8.59708 1.70377L10.2957 5.1211L14.0957 5.6731C14.3477 5.7091 14.5564 5.88577 14.6344 6.1271C14.7124 6.36977 14.6464 6.6351 14.4637 6.81177L11.7171 9.46844L12.3644 13.2204C12.4077 13.4704 12.3051 13.7231 12.1004 13.8724C11.9844 13.9578 11.8464 14.0004 11.7077 14.0004Z"
      fill="white"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="1"
      y="1"
      width="14"
      height="14"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7077 14.0004C11.6024 14.0004 11.4964 13.9758 11.3991 13.9251L7.99975 12.1491L4.60108 13.9251C4.37575 14.0418 4.10375 14.0218 3.89975 13.8724C3.69442 13.7231 3.59242 13.4704 3.63575 13.2204L4.28308 9.46844L1.53642 6.81177C1.35375 6.6351 1.28775 6.36977 1.36575 6.1271C1.44375 5.88577 1.65242 5.7091 1.90442 5.6731L5.70442 5.1211L7.40308 1.70377C7.62842 1.25044 8.37175 1.25044 8.59708 1.70377L10.2957 5.1211L14.0957 5.6731C14.3477 5.7091 14.5564 5.88577 14.6344 6.1271C14.7124 6.36977 14.6464 6.6351 14.4637 6.81177L11.7171 9.46844L12.3644 13.2204C12.4077 13.4704 12.3051 13.7231 12.1004 13.8724C11.9844 13.9578 11.8464 14.0004 11.7077 14.0004Z"
        fill="white"
      />
    </mask>
  </svg>
);

export default StarIcon;

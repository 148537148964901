import moment from "moment";

export function convertUnixToJs(unixTimestamp) {
  return moment.unix(unixTimestamp).format("h:mm:ss a");
}
export function convertUnixToJsWithDate(unixTimestamp) {
  return moment.unix(unixTimestamp).format("dddd, MMMM Do YYYY, h:mm:ss a");
}

export function addTimeToDate(date, duration) {
  const newDate = moment(date);
  newDate.add(duration.toString(), "h").toDate();
  return newDate.format("YYYY MMMM DD HH:mm");
}

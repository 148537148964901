import React, { useEffect, useRef } from "react";
import Flatpickr from "react-flatpickr";
import styles from "./MonitoringComponents.module.scss";

function DateRangePicker(props) {
  const { timePicker, setTimePicker, classes } = props;

  return (
    <Flatpickr
      data-enable-time
      dateformat="Y-m-d H:i"
      className={classes}
      onChange={(date) => setTimePicker(date[0])}
      value={timePicker}
    />
  );
}

export default DateRangePicker;

import React, { useEffect, useState } from "react";
import styles from "./MonitoringComponents.module.scss";
import DateRangePicker from "./DateRangePicker";
import Select from "react-select";
import axios from "axios";
import { Button, Nav, NavItem, NavLink } from "reactstrap";
import ChargeModal from "./Modals/ChargeModal";
import DischargeModal from "./Modals/DischargeModal";
import IdleModal from "./Modals/IdleModal";
import DlsResetModal from "./Modals/DlsResetModal";
import Widget from "../../../../components/Widget/Widget";
import ChargeSettingsTable from "./ChargeSettingsTable";
import moment from "moment";
import {
  chargeBatteryDurationOptions,
  dischargeRateOptions,
} from "./Constants";
function ChargeSettings() {
  // ----- Charge Battery Fields -----
  // Charge Battery Group Dropdown
  const [chargeModal, setChargeModal] = useState(false);
  function toggleChargeModal() {
    if (chargeDuration < 0.5) {
      alert("Charge duration cannot be 0");
      return;
    }
    setChargeModal(!chargeModal);
  }
  // Charge Time Values
  const [startChargeDateTime, setStartChargeDateTime] = useState(
    moment().toDate()
  );
  const [chargeDuration, setChargeDuration] = useState({
    value: 10,
    label: 10,
  });

  // ----- Discharge Battery Fields -----
  // Discharge Battery Group Dropdown
  const [dischargeModal, setDischargeModal] = useState(false);
  function toggleDischargeModal() {
    if (dischargeDuration < 10) {
      alert("Discharge duration cannot be 0");
      return;
    }
    setDischargeModal(!dischargeModal);
  }
  // Discharge Time Values
  const [startDischargeDateTime, setStartDischargeDateTime] = useState(
    moment().toDate()
  );
  const [dischargeDuration, setDischargeDuration] = useState({
    value: 10,
    label: 10,
  });
  const [dischargeRate, setDischargeRate] = useState({
    value: 100,
    label: 100,
  });

  // ----- Idle Battery Fields -----
  // Idle Battery Group Dropdown
  const [idleModal, setIdleModal] = useState(false);
  function toggleIdleModal() {
    setIdleModal(!idleModal);
  }

  // ----- DLS Battery Fields -----
  // DLS Battery Group Dropdown
  const [dlsResetModal, setDlsResetModal] = useState(false);
  function toggleDlsResetModal() {
    setDlsResetModal(!dlsResetModal);
  }

  // ----- Charge Settings Table -----
  const [batteryDetails, setBatteryDetails] = useState([]);

  async function getChargeSettingsTableData() {
    const response = await axios.get("/charge_settings");
    if (response.status === 200) {
      setBatteryDetails(response.data);
    }
  }
  // Tabs
  const [tabIndex, setTabIndex] = useState(0);
  function handleTabIndexChange(index) {
    setTabIndex(index);
  }

  useEffect(() => {
    const result = setInterval(async () => {
      try {
        getChargeSettingsTableData();
      } catch (error) {
        console.error(error);
      }
    }, 30000);
    return () => {
      clearInterval(result);
    };
  }, []);
  return (
    <div className={styles.ChargeSettingsContainer}>
      <div className={styles.ChargeSettingsFieldTitle}>The Pulse Battery 1</div>
      <Nav justified pills>
        <NavItem>
          <NavLink
            className={`${tabIndex == 0 ? "active" : ""}`}
            onClick={() => handleTabIndexChange(0)}
          >
            Charge
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${tabIndex == 1 ? "active" : ""}`}
            onClick={() => handleTabIndexChange(1)}
          >
            Discharge
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${tabIndex == 2 ? "active" : ""}`}
            onClick={() => handleTabIndexChange(2)}
          >
            Idle
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${tabIndex == 3 ? "active" : ""}`}
            onClick={() => handleTabIndexChange(3)}
          >
            Reset DLS
          </NavLink>
        </NavItem>
      </Nav>
      <div className={styles.ChargeSettingsFieldContainer}>
        {tabIndex === 0 ? (
          <Widget title="Charge Battery" className={styles.ChargeBatteryWidget}>
            <div className={styles.ChargeSettingsFieldGroup}>
              <div for="startChargeDateTime">Start Charge Time: </div>
              <DateRangePicker
                classes={"col-md-6"}
                timePicker={startChargeDateTime}
                setTimePicker={setStartChargeDateTime}
              />
            </div>
            <div className={styles.ChargeSettingsFieldGroup}>
              <div for="chargeDuration">Charge Duration (Minutes): </div>
              <Select
                defaultValue={chargeBatteryDurationOptions[0]}
                options={chargeBatteryDurationOptions}
                onChange={setChargeDuration}
                className={styles.ChargeDurationInput}
              />
            </div>
            <div className={styles.ChargeSettingsFieldButtonContainer}>
              <Button onClick={toggleChargeModal}>Charge Battery</Button>
            </div>
          </Widget>
        ) : null}
        {tabIndex === 1 ? (
          <Widget
            title="Discharge Battery"
            className={styles.ChargeBatteryWidget}
          >
            <div className={styles.ChargeSettingsFieldGroup}>
              <div for="startDischargeDateTime">Start Discharge Time: </div>
              <DateRangePicker
                classes={"col-md-6"}
                timePicker={startDischargeDateTime}
                setTimePicker={setStartDischargeDateTime}
              />
            </div>
            <div className={styles.ChargeSettingsFieldGroup}>
              <div for="dischargeDuration">Discharge Duration (Minutes): </div>
              <Select
                defaultValue={chargeBatteryDurationOptions[0]}
                options={chargeBatteryDurationOptions}
                onChange={setDischargeDuration}
                className={styles.ChargeDurationInput}
              />
            </div>
            <div className={styles.ChargeSettingsFieldGroup}>
              <div for="dischargeRate">Discharge Rate (kWh): </div>
              <Select
                defaultValue={dischargeRateOptions[1]}
                options={dischargeRateOptions}
                onChange={setDischargeRate}
                className={styles.ChargeDurationInput}
              />
            </div>
            <div className={styles.ChargeSettingsFieldButtonContainer}>
              <Button onClick={toggleDischargeModal}>Discharge Battery</Button>
            </div>
          </Widget>
        ) : null}
        {tabIndex === 2 ? (
          <Widget title="Idle Battery" className={styles.ChargeBatteryWidget}>
            <div className={styles.ChargeSettingsFieldButtonContainer}>
              <Button onClick={toggleIdleModal}>Idle Mode</Button>
            </div>
          </Widget>
        ) : null}
        {tabIndex === 3 ? (
          <Widget title="Reset DLS" className={styles.ChargeBatteryWidget}>
            <div className={styles.ChargeSettingsFieldButtonContainer}>
              <Button onClick={toggleDlsResetModal}>Reset DLS</Button>
            </div>
          </Widget>
        ) : null}
      </div>
      <div className={styles.ChargeSettingsFieldContainer}>
        <ChargeSettingsTable batteryDetails={batteryDetails} />
      </div>
      <ChargeModal
        chargeModal={chargeModal}
        toggleChargeModal={toggleChargeModal}
        startChargeDateTime={startChargeDateTime}
        chargeDuration={chargeDuration.value}
        setBatteryDetails={setBatteryDetails}
      />
      <DischargeModal
        dischargeModal={dischargeModal}
        toggleDischargeModal={toggleDischargeModal}
        startDischargeDateTime={startDischargeDateTime}
        dischargeDuration={dischargeDuration.value}
        dischargeRate={dischargeRate.value}
        setBatteryDetails={setBatteryDetails}
      />
      <IdleModal
        idleModal={idleModal}
        toggleIdleModal={toggleIdleModal}
        setBatteryDetails={setBatteryDetails}
      />
      <DlsResetModal
        dlsResetModal={dlsResetModal}
        toggleDlsResetModal={toggleDlsResetModal}
      />
    </div>
  );
}

export default ChargeSettings;

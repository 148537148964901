import React, { Fragment, useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import {
  Container,
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

import Widget from "../../components/Widget/Widget.js";
import CustomOnlyModal from "../../components/CustomModal/CustomOnlyModal";
import AddBuildingSidebar from "./components/AddBuildingSidebar";

import {
  fetchBuildings,
  selectBuilding,
  updateBuilding,
  addBuilding,
  removeBuilding,
} from "../../actions/building";

import s from "./Tables.module.scss";

const Buildings = function () {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.buildings);

  const [addSidebarOpen, setAddSidebarOpen] = useState(false);
  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});

  const toggle = () => setModal(!modal);

  useEffect(() => {
    refetchFacilities();
  }, []);

  const refetchFacilities = () => {
    dispatch(fetchBuildings());
  };

  const pageSize = 10;
  const secondTablePagesCount = Math.ceil(store.buildings.length / pageSize);

  const setSecondTablePage = (e, index) => {
    e.preventDefault();
    setSecondTableCurrentPage(index);
  };

  const handleAddFacility = () => {
    setAddSidebarOpen(!addSidebarOpen);
  };

  const itemSelected = (id) => {
    const selectedItem = store.buildings.filter((item) => item.id === id)[0];
    dispatch(selectBuilding(selectedItem));
    handleAddFacility();
  };

  const getItemDetails = async (name, code) => {
    const response = await axios.get("/buildings/" + code);
    if (response.status === 200) {
      setModalData({
        name,
        code,
        ...response.data,
      });
    }
    toggle();
  };

  return (
    <Fragment>
      <div>
        <Row>
          <Col>
            <Row className="mb-4">
              <Col>
                <Widget>
                  <div className={s.tableTitle}>
                    <div className="headline-2">Buildings</div>
                    <div className="d-flex">
                      <Button
                        className="btn-rounded"
                        color="secondary-red"
                        onClick={handleAddFacility}
                      >
                        <span className="align-middle">Add</span>
                      </Button>
                    </div>
                  </div>
                  <div className="widget-table-overflow">
                    <Table
                      className="table-striped table-borderless table-hover"
                      responsive
                    >
                      <thead>
                        <tr>
                          <th>Building ID</th>
                          <th className={s.nameCol}>Name</th>
                          <th>Code</th>
                          <th>Facility ID</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {store.buildings
                          .slice(
                            secondTableCurrentPage * pageSize,
                            (secondTableCurrentPage + 1) * pageSize
                          )
                          .map((item) => (
                            <tr key={uuidv4()}>
                              <td>{item.id}</td>
                              <td>{item.name}</td>
                              <td>{item.code}</td>
                              <td>{item.facility_id}</td>
                              <td>
                                <div className={`d-flex justify-content`}>
                                  <Button
                                    className="btn-rounded mr-4 mb-3"
                                    color="info"
                                    size="xs"
                                    onClick={() =>
                                      getItemDetails(item.name, item.code)
                                    }
                                  >
                                    View
                                  </Button>
                                  <Button
                                    className="btn-rounded mr-4 mb-3"
                                    color="success"
                                    size="xs"
                                    onClick={() => itemSelected(item.id)}
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    className="btn-rounded mr-4 mb-3"
                                    color="danger"
                                    size="xs"
                                    onClick={() =>
                                      dispatch(removeBuilding(item.id))
                                    }
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    <Pagination className="pagination-with-border">
                      <PaginationItem disabled={secondTableCurrentPage <= 0}>
                        <PaginationLink
                          onClick={(e) =>
                            setSecondTablePage(e, secondTableCurrentPage - 1)
                          }
                          previous
                          href="#top"
                        />
                      </PaginationItem>
                      {[...Array(secondTablePagesCount)].map((page, i) => (
                        <PaginationItem
                          active={i === secondTableCurrentPage}
                          key={i}
                        >
                          <PaginationLink
                            onClick={(e) => setSecondTablePage(e, i)}
                            href="#top"
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem
                        disabled={
                          secondTableCurrentPage >= secondTablePagesCount - 1
                        }
                      >
                        <PaginationLink
                          onClick={(e) =>
                            setSecondTablePage(e, secondTableCurrentPage + 1)
                          }
                          next
                          href="#top"
                        />
                      </PaginationItem>
                    </Pagination>
                  </div>
                </Widget>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <AddBuildingSidebar
        store={store}
        dispatch={dispatch}
        open={addSidebarOpen}
        handleAddItem={handleAddFacility}
        selectItem={selectBuilding}
        addItem={addBuilding}
        refetchItems={refetchFacilities}
        updateItem={updateBuilding}
      />
      <CustomOnlyModal
        modalTitle={`Status for ${modalData?.name} (${modalData?.code})`}
        toggle={() => toggle()}
        modalStatus={modal}
      >
        <Container fluid>
          <Row className="mt-sm">
            <Col md={6}>
              <div className={s.column}>Online Status</div>
            </Col>
            <Col md={6}>
              <div className={s.column}>
                {modalData?.data?.relay === 0 ? "Online" : "Offline"}
              </div>
            </Col>
          </Row>
          <Row className="mt-sm">
            <Col md={6}>
              <div className={s.column}>IL Status</div>
            </Col>
            <Col md={6}>
              <div className={s.column}>
                {modalData?.data?.relay === 0 ? "Not triggered" : "Triggered"}
              </div>
            </Col>
          </Row>
          <Row className="mt-sm">
            <Col md={6}>
              <div className={s.column}>Power (kW)</div>
            </Col>
            <Col md={6}>
              <div className={s.column}>
                {modalData?.data?.power_total / 1000}
              </div>
            </Col>
          </Row>
          <Row className="mt-sm">
            <Col md={6}>
              <div className={s.column}>Updated At</div>
            </Col>
            <Col md={6}>
              <div className={s.column}>{modalData?.updated_at}</div>
            </Col>
          </Row>
          <Row className="mt-sm">
            <Col md={6}>
              <div className={s.column}>Retrieved At</div>
            </Col>
            <Col md={6}>
              <div className={s.column}>{modalData?.retrieved_at}</div>
            </Col>
          </Row>
        </Container>
      </CustomOnlyModal>
    </Fragment>
  );
};

export default Buildings;

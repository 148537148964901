import moment from "moment";

const getQueryString = (
  tableState,
  sortColumns,
  isForDownload = false,
  type = null
) => {
  // console.log(tableState.filterList);
  let queryString = "";

  if (isForDownload) {
    queryString = `?page=${tableState.page}&rpg=${tableState.rowsPerPage}&download=1`;
  } else {
    queryString = `?page=${tableState.page}&rpg=${tableState.rowsPerPage}&download=0`;
  }

  if (sortColumns.length > 0) {
    let sortString = "";
    for (let index = 0; index < sortColumns.length; index++) {
      const sortColumn = sortColumns[index];
      sortString += `${sortColumn.name}-${sortColumn.direction},`;
    }

    queryString += `&sort=${sortString}`;
  }

  if (type === "mcr_ahead") {
    if (tableState.filterList[0].length) {
      if (tableState.filterList[0].length === 1) {
        if (tableState.filterList[0][0] !== "") {
          queryString += `&filter_runType=${tableState.filterList[0][0]}`;
        }
      } else {
        queryString += `&filter_runType=${tableState.filterList[0][0] ? tableState.filterList[0][0] : null}-${tableState.filterList[0][1]}`;
      }
    }

    if (tableState.filterList[1].length) {
      if (tableState.filterList[1].length === 1) {
        if (tableState.filterList[1][0] !== "") {
          queryString += `&filter_d=${moment(tableState.filterList[1][0]).format("DDMMYYYY")}`;
        }
      } else {
        queryString += `&filter_d=${tableState.filterList[1][0] ? moment(tableState.filterList[1][0]).format("DDMMYYYY") : null}-${moment(tableState.filterList[1][1]).format("DDMMYYYY")}`;
      }
    }

    if (tableState.filterList[2].length) {
      if (tableState.filterList[2].length === 1) {
        if (tableState.filterList[2][0] !== "") {
          queryString += `&filter_runPeriod=${tableState.filterList[2][0]}`;
        }
      } else {
        queryString += `&filter_runPeriod=${tableState.filterList[2][0] ? tableState.filterList[2][0] : null}-${tableState.filterList[2][1]}`;
      }
    }
  } else {
    if (tableState.filterList[0].length) {
      if (tableState.filterList[0].length === 1) {
        if (tableState.filterList[0][0] !== "") {
          queryString += `&filter_d=${moment(tableState.filterList[0][0]).format("DDMMYYYY")}`;
        }
      } else {
        queryString += `&filter_d=${tableState.filterList[0][0] ? moment(tableState.filterList[0][0]).format("DDMMYYYY") : null}-${moment(tableState.filterList[0][1]).format("DDMMYYYY")}`;
      }
    }

    if (tableState.filterList[2].length) {
      if (tableState.filterList[2].length === 1) {
        if (tableState.filterList[2][0] !== "") {
          queryString += `&filter_usep=${tableState.filterList[2][0]}`;
        }
      } else {
        queryString += `&filter_usep=${tableState.filterList[2][0] ? tableState.filterList[2][0] : null}-${tableState.filterList[2][1]}`;
      }
    }

    if (tableState.filterList[3].length) {
      if (tableState.filterList[3].length === 1) {
        if (tableState.filterList[3][0] !== "") {
          queryString += `&filter_act=${tableState.filterList[3][0]}`;
        }
      }
    }

    // for offers table
    if (tableState.filterList[4].length) {
      if (tableState.filterList[4].length === 1) {
        if (tableState.filterList[4][0] !== "") {
          queryString += `&filter_status=${tableState.filterList[4][0]}`;
        }
      } else if (tableState.filterList[4].length > 1) {
        queryString += `&filter_status=${tableState.filterList[4].join(",")}`;
      }
    }

    if (tableState.filterList[1].length) {
      if (tableState.filterList[1].length === 1) {
        if (tableState.filterList[1][0] !== "") {
          queryString += `&filter_title=${tableState.filterList[1][0]}`;
        }
      }
    }
  }

  // search string for all tables
  if (tableState.searchText) {
    if (tableState.searchText.length) {
      queryString += `&search_query=${tableState.searchText}`;
    }
  }

  return queryString;
};

export default getQueryString;

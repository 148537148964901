import React, { useEffect, useState } from "react";
import FilterBatteryGroup from "./ChartComponents/FilterBatteryGroup.js";
import CustomChart from "./ChartComponents/CustomChart.js";
import styles from "./ClientComponents.module.scss";
import axios from "axios";
import { convertUnixToJs } from "./ChartComponents/Helper.js";
import moment from "moment";

function ChartWidget() {
  // --- Line Chart ---
  // Start and End Time Filter
  const [endPicker, setEndPicker] = useState(moment());

  // Multi Select States
  const [battery, setBattery] = useState([]);
  const [batteryFilter, setBatteryFilter] = useState([]);

  // Line Chart Data
  const [lineChartData, setLineChartData] = useState([]);

  async function getLineChartDataWrapper() {
    const response = await axios.get(
      "/log_tpc001_ass1/getLineChartData" + "?endTime=" + endPicker.unix()
    );

    if (response.status === 200) {
      const withJsTime = response.data.map((obj) => {
        return {
          msb1: obj["msb1_value"],
          msb2: obj["msb2_value"],
          jsTime: convertUnixToJs(obj.batch),
        };
      });
      setLineChartData(withJsTime);

      return true;
    }
    return false;
  }

  useEffect(() => {
    const result = setInterval(async () => {
      try {
        const powerFetch = await getLineChartDataWrapper();
        if (powerFetch) {
          setEndPicker(moment());
        }
      } catch (error) {
        console.error(error);
      }
    }, 30000);
    return () => {
      clearInterval(result);
    };
  }, [endPicker]);
  useEffect(() => {
    getLineChartDataWrapper();
  }, []);
  // ----- End of Line Chart -----
  return (
    <>
      <div className={styles.FilterHeaderRow}>
        <div className={styles.HeaderTimeFilter}>
          <div for="monitorTime">
            Last Updated:{" "}
            <b>{moment(endPicker).format("dddd, MMMM Do YYYY, h:mm:ss a")}</b>
          </div>
        </div>
      </div>
      <div className={styles.LineChartContainer}>
        {lineChartData.length > 0 ? (
          <CustomChart lineChartData={lineChartData} />
        ) : null}
      </div>
    </>
  );
}

export default ChartWidget;

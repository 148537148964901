import React from "react";

import classnames from "classnames";
import moment from "moment";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import "eva-icons/style/eva-icons.css";

import { selectOffer } from "../../../actions/offer";

import { selectBid } from "../../../actions/bid";

const AddEventSidebar = (props) => {
  const { store, bidStore, dispatch, open, handleAddEventSidebar } = props;

  const selectedOffer = store.selectedOffer;
  const selectedBid = bidStore.selectedBid;

  const resetSelectedEvents = () => {
    dispatch(selectOffer([]));
    dispatch(selectBid([]));
  };

  const renderDetail = () => {
    if (Object.keys(selectedOffer).length) {
      return (
        <>
          Facility Name: {selectedOffer.facility_name}
          <br />
          Type: Offer
          <br />
          Date: {moment(selectedOffer.offer_date).format(
            "DD-MM-YYYY"
          )} (Period: {selectedOffer.period})
          <br />
          From: {moment(selectedOffer.start).format("DD-MM-YYYY HH:mm")} to{" "}
          {moment(selectedOffer.end).format("DD-MM-YYYY HH:mm")}
          <br />
          {selectedOffer.offer
            ? selectedOffer.offer.map((pq) =>
                pq.p > 0 && pq.q > 0 ? (
                  <div>
                    Price: {pq.p}, Quantity: {pq.q}MW
                  </div>
                ) : (
                  ""
                )
              )
            : ""}
          <br />
          Cleared amount:{" "}
          {selectedOffer.taken_amount ? selectedOffer.taken_amount : 0}MW
          <br />
          Status: {selectedOffer.offer_status.replace("-", " ")}
          <br />
          <br />
        </>
      );
    } else if (Object.keys(selectedBid).length) {
      return (
        <>
          Facility Name: {selectedBid.facility_name}
          <br />
          Type: Bid
          <br />
          Date: {moment(selectedBid.bid_date).format(
            "DD-MM-YYYY"
          )} (Period: {selectedBid.period})
          <br />
          From: {moment(selectedBid.start).format("DD-MM-YYYY HH:mm")} to{" "}
          {moment(selectedBid.end).format("DD-MM-YYYY HH:mm")}
          <br />
          {selectedBid.bid
            ? selectedBid.bid.map((pq) =>
                pq.p > 0 && pq.q > 0 ? (
                  <div>
                    Price: {pq.p}, Quantity: {pq.q}MW
                  </div>
                ) : (
                  ""
                )
              )
            : ""}
          <br />
          Cleared amount:{" "}
          {selectedBid.taken_amount ? selectedBid.taken_amount : 0}MW
          <br />
          Status: {selectedBid.bid_status.replace("-", " ")}
          <br />
          <br />
        </>
      );
    }
  };

  const CloseBtn = (
    <i
      className="eva eva-close cursor-pointer"
      onClick={handleAddEventSidebar}
    />
  );

  return (
    <Modal
      isOpen={open}
      toggle={handleAddEventSidebar}
      onClosed={resetSelectedEvents}
      className="sidebar-lg"
      contentClassName="p-0"
      modalClassName="modal-slide-in event-sidebar"
    >
      <ModalHeader
        className="mb-1"
        toggle={handleAddEventSidebar}
        close={CloseBtn}
        tag="div"
      >
        <h5 className="modal-title">Details</h5>
      </ModalHeader>
      <ModalBody className="flex-grow-1 pb-sm-0 pb-3">
        {/* {JSON.stringify(selectedOffer)}
        {JSON.stringify(selectedBid)} */}
        {renderDetail()}
        <Button
          className="btn-rounded"
          color="secondary"
          type="reset"
          onClick={handleAddEventSidebar}
          outline
        >
          Close
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default AddEventSidebar;

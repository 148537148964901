// ** Initial State
const initialState = {
  messages: [],
  selectedMessage: {},
};

const messages = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_MESSAGES":
      return { ...state, messages: action.messages };
    case "ADD_MESSAGE":
      return { ...state };
    case "REMOVE_MESSAGE":
      return { ...state };
    case "UPDATE_MESSAGE":
      return { ...state };
    // case 'UPDATE_FILTERS':
    //   // ** Updates Filters based on action filter
    //   const filterIndex = state.selectedCalendars.findIndex(i => i === action.filter)
    //   if (state.selectedCalendars.includes(action.filter)) {
    //     state.selectedCalendars.splice(filterIndex, 1)
    //   } else {
    //     state.selectedCalendars.push(action.filter)
    //   }
    //   if (state.selectedCalendars.length === 0) {
    //     state.events.length = 0
    //   }
    //   return { ...state }
    // case 'UPDATE_ALL_FILTERS':
    // // ** Updates All Filters based on action value
    //   const value = action.value
    //   let selected = []
    //   if (value === true) {
    //     selected = ['Personal', 'Business', 'Flatlogic', 'Holiday']
    //   } else {
    //     selected = []
    //   }
    //   return { ...state, selectedCalendars: selected }
    case "SELECT_MESSAGE":
      return { ...state, selectedMessage: action.message };
    default:
      return state;
  }
};

export default messages;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table } from "reactstrap";
import styles from "./MonitoringComponents.module.scss";
import moment from "moment";

export default function ChargeSettingsTable(props) {
  const { batteryDetails } = props;

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Battery Name</th>
            <th>Desired State</th>
            <th>Desired Rate</th>
            <th>Desired Time</th>
            <th>Processed</th>
            <th>Action By</th>
          </tr>
        </thead>
        <tbody>
          {batteryDetails.length > 0 ? (
            batteryDetails.map((battery, index) => {
              return (
                <tr key={index}>
                  <td>{battery.element.thing_name}</td>
                  <td>{battery.element.desired_state}</td>
                  <td>{battery.element.desired_rate}</td>
                  <td>
                    {moment(battery.element.desired_time).format(
                      "dddd, MMMM Do YYYY, h:mm:ss a"
                    )}
                  </td>
                  <td>{battery.element.processed ? "Yes" : "No"}</td>
                  <td>{battery.username}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td>
                <div>No Charge Settings Queued</div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}

import React from "react";
import Widget from "../../components/Widget/Widget.js";
import ChartWidget from "./components/ClientDashboardComponents/ChartWidget.js";
import styles from "./Monitoring.module.scss";

export default function ClientDashboard() {
  return (
    <div>
      <div className={styles.WidgetContainer}>
        <Widget title="Live Power Consumption">
          <ChartWidget />
        </Widget>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import CustomChart from "./CustomChart";
import styles from "./MonitoringComponents.module.scss";
import axios from "axios";
import { convertUnixToJs } from "./Helper";
import moment from "moment";
import { tableNames } from "./Constants";

function ChartWidget(props) {
  const { assetValue } = props;
  // --- Line Chart ---
  // Start and End Time Filter
  const [endPicker, setEndPicker] = useState(moment());
  // Line Chart Data
  const [lineChartData, setLineChartData] = useState([]);
  const [lineChartTotal, setLineChartTotal] = useState([]);
  async function getLineChartDataWrapper(selectedValue) {
    const currentPower = await axios.get(
      "/assets/getLineChartDataAdmin" +
        "?endTime=" +
        endPicker.unix() +
        "&assetName=" +
        tableNames[assetValue.value]
    );

    if (currentPower.status === 200) {
      let total = [];
      const resultArray = currentPower.data.map((obj) => {
        let o = {};
        for (let key in obj) {
          if (key === "batch") {
            o["jsTime"] = convertUnixToJs(obj[key]);
          } else if (key === "total") {
            total.push({
              total: parseFloat(Number(obj[key] / 1000).toFixed(2)),
              jsTime: convertUnixToJs(obj.batch),
            });
          } else {
            o[key.replace("_value", "")] = parseFloat(Number(obj[key] / 1000).toFixed(2));
          }
        }
        return o;
      });
      setLineChartData(resultArray);
      if (total.length === 0 && resultArray.length > 0 && Object.keys(resultArray[0]).length > 2 && !resultArray[0].total) {
        total = resultArray.map((obj) => {
          return {
            total: Object.values(obj).reduce((acc, curr) => acc + (typeof curr === "number" ? curr : 0), 0),
            jsTime: obj.jsTime,
          };
        });
      }
      setLineChartTotal(total);
      return true;
    }

    return false;
  }

  useEffect(() => {
    const result = setInterval(async () => {
      try {
        const powerFetch = await getLineChartDataWrapper(assetValue.value);
        if (powerFetch) {
          setEndPicker(moment());
        }
      } catch (error) {
        console.error(error);
      }
    }, 30000);
    return () => {
      clearInterval(result);
    };
  }, [endPicker, assetValue.value]);
  useEffect(() => {
    getLineChartDataWrapper(assetValue.value);
  }, [assetValue.value]);

  // ----- End of Line Chart -----
  return (
    <>
      <div className={styles.FilterHeaderRow}>
        <div className={styles.HeaderTimeFilter}>
          <div for="monitorTime">
            Last Updated:{" "}
            <b>{moment(endPicker).format("dddd, MMMM Do YYYY, h:mm:ss a")}</b>
          </div>
        </div>
      </div>
      <div className={styles.LineChartContainer}>
        {lineChartData.length > 0 ? (
          <CustomChart
            lineChartData={lineChartData}
            selectedValue={assetValue.value}
          />
        ) : null}
      </div>
      {lineChartTotal && lineChartTotal.length > 0 && (
      <div className={styles.LineChartContainer}>
        {lineChartTotal.length > 0 ? (
          <CustomChart
            lineChartData={lineChartTotal}
            selectedValue={assetValue.value}
          />
        ) : null}
      </div>
      )}
    </>
  );
}

export default ChartWidget;

import React, { Fragment, useState } from "react";

import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import Select, { components } from "react-select";
import { useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  CustomInput,
  Input,
  Form,
} from "reactstrap";

import "eva-icons/style/eva-icons.css";

const AddEventSidebar = (props) => {
  const {
    store,
    dispatch,
    open,
    handleAddFacility,
    refetchFacilities,
    addFacility,
    selectFacility,
    updateFacility,
  } = props;

  const selectedFacility = store.selectedFacility;
  const { register, errors, handleSubmit } = useForm();

  const [name, setName] = useState("");
  const [emc_zone_id, setZoneID] = useState("");

  const isObjEmpty = (obj) => Object.keys(obj).length === 0;

  const handleAddEvent = () => {
    const obj = {
      name,
      emc_zone_id,
    };
    dispatch(addFacility(obj));
    refetchFacilities();
    handleAddFacility();
  };

  const handleSelectedEvent = () => {
    if (!isObjEmpty(selectedFacility)) {
      setName(selectedFacility.name || name);
      setZoneID(selectedFacility.emc_zone_id || emc_zone_id);
    }
  };

  const handleResetInputValues = () => {
    dispatch(selectFacility({}));

    setName("");
    setZoneID("");
  };

  const handleUpdateEvent = () => {
    const facilityToUpdate = {
      id: selectedFacility.id,
      name,
      emc_zone_id,
    };

    dispatch(updateFacility(facilityToUpdate));
    handleAddFacility();
  };

  const EventActions = () => {
    if (
      isObjEmpty(selectedFacility) ||
      (!isObjEmpty(selectedFacility) && !selectedFacility.name.length)
    ) {
      return (
        <Fragment>
          <Button className="mr-3 btn-rounded" type="submit" color="primary">
            Add
          </Button>
          <Button
            className="btn-rounded"
            color="secondary"
            type="reset"
            onClick={handleAddFacility}
            outline
          >
            Cancel
          </Button>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Button
            className="mr-3 btn-rounded"
            color="primary"
            onClick={handleUpdateEvent}
          >
            Update
          </Button>
          <Button
            className="btn-rounded"
            color="secondary"
            type="reset"
            onClick={handleAddFacility}
            outline
          >
            Cancel
          </Button>
        </Fragment>
      );
    }
  };

  const CloseBtn = (
    <i className="eva eva-close cursor-pointer" onClick={handleAddFacility} />
  );

  return (
    <Modal
      isOpen={open}
      toggle={handleAddFacility}
      onOpened={handleSelectedEvent}
      onClosed={handleResetInputValues}
      className="sidebar-lg"
      contentClassName="p-0"
      modalClassName="modal-slide-in event-sidebar"
    >
      <ModalHeader
        className="mb-1"
        toggle={handleAddFacility}
        close={CloseBtn}
        tag="div"
      >
        <h5 className="modal-title">
          {selectedFacility &&
          selectedFacility.title &&
          selectedFacility.title.length
            ? "Update"
            : "Add"}{" "}
          Facility
        </h5>
      </ModalHeader>
      <ModalBody className="flex-grow-1 pb-sm-0 pb-3">
        <Form
          onSubmit={handleSubmit((data) => {
            if (isObjEmpty(errors)) {
              if (
                isObjEmpty(selectedFacility) ||
                (!isObjEmpty(selectedFacility) &&
                  !selectedFacility.title.length)
              ) {
                handleAddEvent();
              } else {
                handleUpdateEvent();
              }
              handleAddFacility();
            }
          })}
        >
          <FormGroup>
            <Label for="name">
              Title <span className="text-danger">*</span>
            </Label>
            <Input
              id="name"
              name="name"
              placeholder="City Hall"
              value={name}
              onChange={(e) => setName(e.target.value)}
              innerRef={register({
                register: true,
                validate: (value) => value !== "",
              })}
              className={classnames({
                "is-invalid": errors.name,
                // add extended bootstrap class for show invalid field value
              })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="emc_zone_id">
              Zone ID (1 - 32)<span className="text-danger">*</span>
            </Label>
            <Input
              id="emc_zone_id"
              name="emc_zone_id"
              placeholder="18"
              value={emc_zone_id}
              onChange={(e) => setZoneID(e.target.value)}
              innerRef={register({
                register: true,
                validate: (value) => value !== "",
              })}
              className={classnames({
                "is-invalid": errors.emc_zone_id,
                // add extended bootstrap class for show invalid field value
              })}
            />
          </FormGroup>
          <FormGroup className="d-flex">
            <EventActions />
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default AddEventSidebar;

import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import { Col, Row, Input, Label } from "reactstrap";
import MUIDataTable from "mui-datatables";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Widget from "../../components/Widget/Widget.js";

import s from "./Tables.module.scss";
import bc from "../charts/bar/BarCharts.module.scss";
import mock from "./mock.js";
import getQueryString from "./utils.js";

const Tables = function () {
  const [tableData, setTableData] = useState([]);
  const [total, setTotal] = useState(0);
  const [dataOptions, setDataOptions] = useState("?download=0");
  const [downloadDataOptions, setDownloadDataOptions] = useState("?download=1");
  const [sortColumns, setSortColumns] = useState([]);

  const [startPicker, setStartPicker] = useState(
    moment().subtract(1, "month").startOf("month").toDate()
  );
  const [endPicker, setEndPicker] = useState(moment().toDate());

  const [chartData, setChartData] = useState([
    {
      date: "2023-01-01",
      Triggers: 0,
    },
  ]);

  const list = async (dataOptions) => {
    const response = await axios.get(
      "/marketclearing" + dataOptions + "&runType=DPR"
    );
    // console.log(response);
    if (response.status === 200) {
      setTableData(response.data.rows);
      setTotal(response.data.total);
    }
  };

  const getChartData = async (startPicker, endPicker) => {
    let url = `/marketclearing/group_by_date?filter_d=${moment(
      startPicker
    ).format("DDMMYYYY")}-${moment(endPicker).format("DDMMYYYY")}&runType=DPR`;
    const response = await axios.get(url);
    if (response.status === 200) {
      setChartData(response.data.chart);
    }
  };

  useEffect(() => {
    list(dataOptions);
  }, [dataOptions]);

  useEffect(() => {
    getChartData(startPicker, endPicker);
  }, [startPicker, endPicker]);

  const columns = [
    {
      label: "Date",
      name: "resultDate",
      options: {
        filter: true,
        filterType: "custom",
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
            "DD MMM YYYY"
          );
        },
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1]) {
              return [
                `From: ${moment(v[0]).format("DD MMM YYYY")} Till: ${moment(
                  v[1]
                ).format("DD MMM YYYY")}`,
              ];
            } else if (v[0]) {
              return `From: ${moment(v[0]).format("DD MMM YYYY")}`;
            } else if (v[1]) {
              return `Till: ${moment(v[1]).format("DD MMM YYYY")}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
          sortThirdClickReset: true,
        },
        filterOptions: {
          names: [],
          display: (filterList, onChange, index, column) => (
            <div>
              <Label for="startDate">From</Label>
              <Flatpickr
                // required
                id="startDate"
                name="startDate"
                className="col-md-4"
                onChange={(date) => {
                  filterList[index][0] = date[0];
                  onChange(filterList[index], index, column);
                }}
                value={filterList[index][0] || ""}
                options={{
                  enableTime: false,
                  dateformat: "d-M-Y",
                }}
              />
              To
              <Flatpickr
                // required
                id="endDate"
                name="endDate"
                className="col-md-4"
                onChange={(date) => {
                  filterList[index][1] = date[0];
                  onChange(filterList[index], index, column);
                }}
                value={filterList[index][1] || ""}
                options={{
                  enableTime: false,
                  dateformat: "d-M-Y",
                }}
              />
            </div>
          ),
          fullWidth: true,
        },
      },
    },
    {
      name: "resultPeriod",
      label: "Period",
      options: {
        filter: false,
        sortThirdClickReset: true,
        // filterOptions: {
        //   names: ['1', '2', '3'],
        // }
      },
    },
    {
      name: "uniformSingaporeEnergyPrice",
      label: "USEP ($/MWh)",
      options: {
        filter: true,
        filterType: "custom",
        sortThirdClickReset: true,
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1]) {
              return [`USEP between ${v[0]} and ${v[1]}`];
            } else if (v[0]) {
              return `USEP >= ${v[0]}`;
            } else if (v[1]) {
              return `USEP <= ${v[1]}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          display: (filterList, onChange, index, column) => (
            <div>
              <Label for="uniformSingaporeEnergyPrice">USEP</Label>
              <Row>
                <Col>
                  <Input
                    type="text"
                    onChange={(event) => {
                      filterList[index][0] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    value={filterList[index][0] || ""}
                  />
                </Col>
                <Col>
                  <Input
                    type="text"
                    onChange={(event) => {
                      filterList[index][1] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    value={filterList[index][1] || ""}
                  />
                </Col>
              </Row>
            </div>
          ),
        },
      },
    },
    {
      name: "estimatesHourlyEnergyUpliftRebate",
      label: "EHEUR ($/MWh)",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "counterfactualUniformSingaporeEnergyPrice",
      label: "CUSEP ($/MWh)",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "loadCurtailmentPrice",
      label: "LCP ($/MWh)",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "totalLoadMW",
      label: "TL (MW)",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "totalCurtailedLoad",
      label: "TCL (MW)",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "totalTransmissionLossMW",
      label: "TTL (MW)",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "contingencyReservePrice",
      label: "ContingencyReseve Reserve Price ($/MWh)",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "contingencyReserveRequirement",
      label: "ContingencyReseve Reserve Requirement (MW)",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
  ];

  const downloadAllRowsAsCSV = async () => {
    const response = await axios.get(
      "/marketclearing" + downloadDataOptions + "&runType=DPR"
    );
    const data = await response.data.rows;

    const columns = [
      "MCRID",
      "Date",
      "PERIOD",
      "USEP ($/MWh)",
      "EHEUR ($/MWh)",
      "CUSEP ($/MWh)",
      "LCP ($/MWh)",
      "TL (MW)",
      "TCL (MW)",
      "TTL (MW)",
      "ContingencyReseve Reserve Price ($/MWh)",
      "ContingencyReseve Reserve Requirement (MW)",
    ];

    // Convert data to CSV format
    const header = columns.join(",") + "\n";
    const rows = data.map((row) =>
      Object.values(row)
        .map((item) => (typeof item === "string" ? item.trim() : item))
        .join(",")
    );

    const csv = header + rows.join("\n");

    // Download CSV file
    const link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
    );
    link.setAttribute("download", "dataDownload_mcr.csv");
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const options = {
    filterType: "checkbox",
    search: false,
    count: total,
    serverSide: true,
    rowsPerPage: 48,
    rowsPerPageOptions: [48, 100, 200],
    onDownload: (buildHead, buildBody, columns, data) => {
      downloadAllRowsAsCSV();
      return false;
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          setDataOptions(getQueryString(tableState, sortColumns));
          break;
        case "sort":
          if (
            sortColumns.filter(
              (sort) => sort.name === tableState.sortOrder.name
            ).length > 0
          ) {
            // update the existing sortColumn
            let index = sortColumns.findIndex(
              (sort) => sort.name === tableState.sortOrder.name
            );

            if (tableState.sortOrder.direction === "none") {
              let newSortCols = sortColumns.splice(index, 1);
              setSortColumns(newSortCols);
            } else {
              sortColumns[index] = {
                name: tableState.sortOrder.name,
                direction: tableState.sortOrder.direction,
              };
            }

            setSortColumns(sortColumns);
          } else {
            // add a new sortColumn
            sortColumns.push({
              name: tableState.sortOrder.name,
              direction: tableState.sortOrder.direction,
            });

            setSortColumns(sortColumns);
          }

          setDataOptions(getQueryString(tableState, sortColumns));
          setDownloadDataOptions(getQueryString(tableState, sortColumns, true));
          break;
        case "filterChange":
          setDataOptions(getQueryString(tableState, sortColumns));
          setDownloadDataOptions(getQueryString(tableState, sortColumns, true));
          break;
        case "changeRowsPerPage":
          setDataOptions(getQueryString(tableState, sortColumns));
          break;
        default:
          console.log(action, " is not handled.");
      }
    },
  };

  return (
    <div>
      <Widget className="pt-2">
        <Row className="m-4">
          <Label for="startDate">From</Label>
          <Flatpickr
            // required
            id="startDate"
            name="startDate"
            className="col-md-2"
            onChange={(date) => {
              setStartPicker(date[0]);
            }}
            value={startPicker}
            options={{
              enableTime: false,
            }}
          />
          To
          <Flatpickr
            // required
            id="endDate"
            name="endDate"
            className="col-md-2"
            onChange={(date) => setEndPicker(date[0])}
            value={endPicker}
            options={{
              enableTime: false,
            }}
          />
        </Row>
        <div className={bc.rechartsBlock}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={chartData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                dataKey="Triggers (# of non-zero TCL periods)"
                stroke="#FF5668"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Widget>
      <MUIDataTable
        title={`Market Clearing Reports ${
          sortColumns.length > 0
            ? " (sorted by : " +
              sortColumns.map(
                (col) => " " + col.name + " " + col.direction + " "
              ) +
              ")"
            : ""
        }`}
        data={tableData}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default Tables;

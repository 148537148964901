// ** Initial State
const initialState = {
  groups: [],
  selectedGroup: {},
};

const group = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_GROUPS":
      return { ...state, groups: action.groups };
    case "ADD_GROUP":
      return { ...state };
    case "REMOVE_GROUP":
      return { ...state };
    case "UPDATE_GROUP":
      return { ...state };
    case "UPDATE_GROUP_STATE":
      return { ...state };
    case "SELECT_GROUP":
      return { ...state, selectedGroup: action.group };
    default:
      return state;
  }
};

export default group;

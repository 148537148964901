import { Fragment, useState, useEffect } from "react";

import classnames from "classnames";
import { Row, Col } from "reactstrap";

import CalendarBody from "./components/CalendarBody";
import SidebarRight from "./components/SidebarRight";
import AddEventSidebar from "./components/AddEventSidebar";

import { useSelector, useDispatch } from "react-redux";
import {
  fetchAllOffers,
  selectOffer,
  updateOffer,
  updateFilter,
  updateAllFilters,
  addOffer,
  removeOffer,
} from "../../actions/offer";

import {
  fetchAllBids,
  selectBid,
  updateBid,
  updateFilter as bidUpdateFilter,
  updateAllFilters as bidUpdateAllFilters,
} from "../../actions/bid";

const calendarsColor = {
  pending: "primary",
  rejected: "danger",
  "not-accepted": "warning",
  accepted: "info",
  validated: "info",
  "not-cleared": "info",
  cleared: "success",
};

const Calendar = () => {
  const dispatch = useDispatch();
  const offer_store = useSelector((state) => state.offers);
  const bid_store = useSelector((state) => state.bids);

  const [addSidebarOpen, setAddSidebarOpen] = useState(false);
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
  const [calendarApi, setCalendarApi] = useState(null);

  const handleAddEventSidebar = () => setAddSidebarOpen(!addSidebarOpen);
  const toggleSidebar = (val) => setRightSidebarOpen(val);

  const blankEvent = {
    title: "",
    start: "",
    end: "",
    allDay: false,
    url: "",
    extendedProps: {
      calendar: "",
      guests: [],
      location: "",
      description: "",
    },
  };

  const refetchEvents = () => {
    if (calendarApi !== null) {
      calendarApi.refetchEvents();
    }
  };

  useEffect(() => {
    dispatch(fetchAllOffers());
    dispatch(fetchAllBids());
  }, []);

  return (
    <Fragment>
      <div className="app-calendar overflow-hidden">
        <Row noGutters>
          <Col className="position-relative mr-3">
            <CalendarBody
              store={offer_store}
              bidStore={bid_store}
              dispatch={dispatch}
              blankEvent={blankEvent}
              calendarApi={calendarApi}
              setCalendarApi={setCalendarApi}
              calendarsColor={calendarsColor}
              toggleSidebar={toggleSidebar}
              selectOfferEvent={selectOffer}
              selectBidEvent={selectBid}
              updateEvent={updateOffer}
              handleAddEventSidebar={handleAddEventSidebar}
            />
          </Col>
          <Col
            id="app-calendar-sidebar"
            className={classnames(
              "col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column",
              {
                show: rightSidebarOpen,
              }
            )}
          >
            <SidebarRight
              offerStore={offer_store}
              bidStore={bid_store}
              dispatch={dispatch}
              updateFilter={updateFilter}
              updateAllFilters={updateAllFilters}
              bidUpdateFilter={bidUpdateFilter}
              bidUpdateAllFilters={bidUpdateAllFilters}
              // toggleSidebar={toggleSidebar}
              // handleAddEventSidebar={handleAddEventSidebar}
            />
          </Col>
          <div
            className={classnames("body-content-overlay", {
              show: rightSidebarOpen === true,
            })}
            onClick={() => toggleSidebar(false)}
          />
        </Row>
      </div>
      <AddEventSidebar
        store={offer_store}
        bidStore={bid_store}
        dispatch={dispatch}
        open={addSidebarOpen}
        handleAddEventSidebar={handleAddEventSidebar}
        selectEvent={selectOffer}
        addEvent={addOffer}
        removeEvent={removeOffer}
        refetchEvents={refetchEvents}
        updateEvent={updateOffer}
      />
    </Fragment>
  );
};

export default Calendar;

import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import { Row, Label } from "reactstrap";
import MUIDataTable from "mui-datatables";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Widget from "../../components/Widget/Widget.js";

import s from "./Tables.module.scss";
import bc from "../charts/bar/BarCharts.module.scss";
import getQueryString from "./utils.js";

const Tables = function () {
  const [tableData, setTableData] = useState([]);
  const [total, setTotal] = useState(0);
  const [dataOptions, setDataOptions] = useState("?download=0");
  const [downloadDataOptions, setDownloadDataOptions] = useState("?download=1");
  const [sortColumns, setSortColumns] = useState([]);

  const [startPicker, setStartPicker] = useState(
    moment().subtract(2, "month").startOf("month").toDate()
  );
  const [endPicker, setEndPicker] = useState(moment().toDate());

  const [chartData, setChartData] = useState([
    {
      date: "2023-01-01",
      Other: 0,
      "Major Outage": 0,
    },
  ]);

  const list = async (dataOptions) => {
    const response = await axios.get("/advisories" + dataOptions);
    if (response.status === 200) {
      setTableData(response.data.rows);
      setTotal(response.data.total);
    }
  };

  const getChartData = async (startPicker, endPicker) => {
    let url = `/advisories/group_by_date?filter_d=${moment(startPicker).format(
      "DDMMYYYY"
    )}-${moment(endPicker).format("DDMMYYYY")}`;
    const response = await axios.get(url);
    if (response.status === 200) {
      setChartData(response.data.chart);
    }
  };

  useEffect(() => {
    getChartData(startPicker, endPicker);
  }, [startPicker, endPicker]);

  useEffect(() => {
    list(dataOptions);
  }, [dataOptions]);

  const columns = [
    {
      label: "ISSUED DATE",
      name: "statusTime",
      options: {
        filter: true,
        filterType: "custom",
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value, "YYYY-MM-DDTHH:mm:ss.SSSZ")
            .utc()
            .format("DD MMM YYYY HH:mm");
        },
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1]) {
              return [
                `ISSUED DATE From: ${moment(v[0]).format(
                  "DD MMM YYYY"
                )} Till: ${moment(v[1]).format("DD MMM YYYY")}`,
              ];
            } else if (v[0]) {
              return `ISSUED DATE From: ${moment(v[0]).format("DD MMM YYYY")}`;
            } else if (v[1]) {
              return `ISSUED DATE Till: ${moment(v[1]).format("DD MMM YYYY")}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          display: (filterList, onChange, index, column) => (
            <div>
              <Label for="startDate">From</Label>
              <Flatpickr
                // required
                id="startDate"
                name="startDate"
                className="col-md-4"
                onChange={(date) => {
                  filterList[index][0] = date[0];
                  onChange(filterList[index], index, column);
                }}
                value={filterList[index][0] || ""}
                options={{
                  enableTime: false,
                  dateformat: "d-M-Y",
                }}
              />
              To
              <Flatpickr
                // required
                id="endDate"
                name="endDate"
                className="col-md-4"
                onChange={(date) => {
                  filterList[index][1] = date[0];
                  onChange(filterList[index], index, column);
                }}
                value={filterList[index][1] || ""}
                options={{
                  enableTime: false,
                  dateformat: "d-M-Y",
                }}
              />
            </div>
          ),
          fullWidth: true,
        },
      },
    },
    {
      name: "period",
      label: "PERIOD",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "reportedStartTime",
      label: "REPORTED START",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value, "YYYY-MM-DDTHH:mm:ss.SSSZ")
            .utc()
            .format("DD MMM YYYY HH:mm");
        },
        sortThirdClickReset: true,
      },
    },
    {
      name: "reportedEndTime",
      label: "REPORTED END",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value, "YYYY-MM-DDTHH:mm:ss.SSSZ")
            .utc()
            .format("DD MMM YYYY HH:mm");
        },
        sortThirdClickReset: true,
      },
    },
    {
      name: "abnormalConditionType",
      label: "ABNORMAL CONDITION TYPE",
      options: {
        sortThirdClickReset: true,
        filter: true,
        filterType: "checkbox",
        filterOptions: {
          names: ["Major Equipment Outage", "Other"],
        },
        customFilterListOptions: {
          render: (v) => {
            if (v.length > 0) {
              return `CONDITION: ${v}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
      },
    },
    {
      name: "description",
      label: "DESCRIPTION",
      options: {
        filter: false,
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value.replace(/\(([^)]*\)?)/g, "").trim();
        },
      },
    },
    {
      name: "currentStatus",
      label: "STATUS",
      options: {
        filter: false,
        sortThirdClickReset: true,
      },
    },
  ];

  const downloadAllRowsAsCSV = async () => {
    const response = await axios.get("/advisories" + downloadDataOptions);
    const data = await response.data.rows;

    const columns = [
      "UUID",
      "STATUS TIME",
      "PERIOD",
      "REPORTED START",
      "REPORTED END",
      "ABNORMAL CONDITION TYPE",
      "DESCRIPTION",
      "STATUS",
    ];

    // Convert data to CSV format
    const header = columns.join(",") + "\n";
    const rows = data.map((row) =>
      Object.values(row)
        .map((item) => (typeof item === "string" ? item.trim() : item))
        .join(",")
    );

    const csv = header + rows.join("\n");

    // Download CSV file
    const link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
    );
    link.setAttribute("download", "dataDownload.csv");
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const options = {
    filterType: "checkbox",
    count: total,
    downloadOptions: {
      filename: "excel-format.csv",
      filterOptions: {
        useDisplayedColumnsOnly: true,
      },
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      downloadAllRowsAsCSV();
      return false;
    },
    serverSide: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          setDataOptions(getQueryString(tableState, sortColumns));
          break;
        case "sort":
          if (
            sortColumns.filter(
              (sort) => sort.name === tableState.sortOrder.name
            ).length > 0
          ) {
            // update the existing sortColumn
            let index = sortColumns.findIndex(
              (sort) => sort.name === tableState.sortOrder.name
            );
            if (tableState.sortOrder.direction === "none") {
              let newSortCols = sortColumns.splice(index, 1);
              setSortColumns(newSortCols);
            } else {
              sortColumns[index] = {
                name: tableState.sortOrder.name,
                direction: tableState.sortOrder.direction,
              };
            }

            setSortColumns(sortColumns);
          } else {
            // add a new sortColumn
            sortColumns.push({
              name: tableState.sortOrder.name,
              direction: tableState.sortOrder.direction,
            });

            setSortColumns(sortColumns);
          }

          setDataOptions(getQueryString(tableState, sortColumns));
          setDownloadDataOptions(getQueryString(tableState, sortColumns, true));
          break;
        case "filterChange":
          setDataOptions(getQueryString(tableState, sortColumns));
          setDownloadDataOptions(getQueryString(tableState, sortColumns, true));
          break;
        case "changeRowsPerPage":
          setDataOptions(getQueryString(tableState, sortColumns));
          break;
        case "search":
          setDataOptions(getQueryString(tableState, sortColumns));
          break;
        default:
          console.log(action, " is not handled.");
      }
    },
  };

  return (
    <div>
      <Widget className="pt-2">
        <Row className="m-4">
          <Label for="startDate">From</Label>
          <Flatpickr
            // required
            id="startDate"
            name="startDate"
            className="col-md-2"
            onChange={(date) => {
              setStartPicker(date[0]);
            }}
            value={startPicker}
            options={{
              enableTime: false,
            }}
          />
          To
          <Flatpickr
            // required
            id="endDate"
            name="endDate"
            className="col-md-2"
            onChange={(date) => setEndPicker(date[0])}
            value={endPicker}
            options={{
              enableTime: false,
            }}
          />
        </Row>
        <div className={bc.rechartsBlock}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={350}
              data={chartData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="statusDate" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Major Outage" stackId="a" fill="#FF4B23" />
              <Bar dataKey="Other" stackId="a" fill="#C7D0D9" />
              <Bar dataKey="IL Triggers" stackId="a" fill="#fcba03" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Widget>
      <MUIDataTable
        title={`Advisories Retrieval Table ${
          sortColumns.length > 0
            ? " (sorted by : " +
              sortColumns.map(
                (col) => " " + col.name + " " + col.direction + " "
              ) +
              ")"
            : ""
        }`}
        data={tableData}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default Tables;

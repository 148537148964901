import React, { useState, useEffect } from "react";
import Widget from "../../components/Widget/Widget.js";
import Yesterday from "./components/PredictionComponents/Yesterday";
import Today from "./components/PredictionComponents/Today";
import { useSelector, useDispatch } from "react-redux";
import { fetchFacilities } from "../../actions/facility";
import Select from "react-select";

const Prediction = function () {
  const dispatch = useDispatch();
  const facilitiesData = useSelector(state => state.facilities.facilities || []);
  const [selectedFacilityId, setSelectedFacilityId] = useState(0);
  const [selectedFacilityName, setSelectedFacilityName] = useState("");

  useEffect(() => {
    dispatch(fetchFacilities());
  }, [dispatch]);

  useEffect(() => {
    if (facilitiesData.length > 0 && !selectedFacilityId) {
      setSelectedFacilityId(facilitiesData[0].id);
      setSelectedFacilityName(facilitiesData[0].name);
    }
  }, [facilitiesData, selectedFacilityId]);

  const handleFacilityChange = (event) => {
    setSelectedFacilityId(event.value);
    setSelectedFacilityName(facilitiesData.filter((f) => f.id === event.value)[0].name);
  };

  return (
    <div>
      <div className="mb-3" style={{
        width: "30%",
        position: "relative",
        zIndex: 999,
      }}>
        <Select
          value={selectedFacilityId ? { value: selectedFacilityId, label: selectedFacilityName } : null}
          options={facilitiesData.map((facility) => (
            { value: facility.id, label: facility.name }
          ))}
          onChange={handleFacilityChange}
        />
      </div>

      {selectedFacilityId && (
        <>
          <div className="mb-3">
            <Widget>
              <Yesterday facilityId={selectedFacilityId} facilityName={selectedFacilityName} key={`yesterday-${selectedFacilityId}`} />
            </Widget>
          </div>
          <div className="mb-3">
            <Widget>
              <Today facilityId={selectedFacilityId} facilityName={selectedFacilityName} key={`today-${selectedFacilityId}`} />
            </Widget>
          </div>
        </>
      )}
    </div>
  );
};

export default Prediction;
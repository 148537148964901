import axios from "axios";

export const fetchUnits = () => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_UNIT_STATE",
      fetching: true,
    });
    axios.get("/units").then((response) => {
      dispatch({
        type: "FETCH_UNITS",
        units: response.data,
        fetching: false,
      });
    });
  };
};

export const addUnit = (unit) => {
  return (dispatch, getState) => {
    axios.post("/units/new", unit).then(() => {
      dispatch({
        type: "ADD_UNIT",
      });
      dispatch(fetchUnits());
    });
  };
};

export const updateUnit = (unit) => {
  return (dispatch) => {
    axios.put("/units/" + unit.id, unit).then(() => {
      dispatch({
        type: "UPDATE_UNIT",
      });
      dispatch(fetchUnits());
    });
  };
};

export const removeUnit = (id) => {
  return (dispatch) => {
    axios.delete("/units/" + id).then(() => {
      dispatch({
        type: "REMOVE_UNIT",
      });
      dispatch(fetchUnits());
    });
  };
};

export const selectUnit = (unit) => {
  return (dispatch) => {
    dispatch({
      type: "SELECT_UNIT",
      unit,
    });
  };
};

import React, { useEffect, useRef } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function CustomChart(props) {
  const { lineChartData, selectedValue } = props;
  console.log(lineChartData, "lineChartData");
  console.log(selectedValue, "selected value");
  const totalNumData = lineChartData.length;
  let xAxisTime = [
    lineChartData[0]["jsTime"],
    lineChartData[Math.ceil(totalNumData / 6)]["jsTime"],
    lineChartData[Math.ceil((2 * totalNumData) / 6)]["jsTime"],
    lineChartData[Math.ceil((3 * totalNumData) / 6)]["jsTime"],
    lineChartData[Math.ceil((4 * totalNumData) / 6)]["jsTime"],
    lineChartData[Math.ceil((5 * totalNumData) / 6)]["jsTime"],
    lineChartData[totalNumData - 1]["jsTime"],
  ];

  let maxValue = 0;
  if (selectedValue === "The Pulse") {
    maxValue = Math.max(...lineChartData.map((o) => o[selectedValue]));
  } else {
    maxValue = Math.max(
      ...lineChartData.map((o) => o["msb1"]),
      ...lineChartData.map((o) => o["msb2"])
    );
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={lineChartData}
        margin={{
          top: 5,
          right: 55,
          left: 45,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="jsTime" ticks={xAxisTime} />
        <YAxis dataKey={selectedValue} unit={"kW"} domain={[0, maxValue]} />

        <Tooltip />
        <Legend />
        {selectedValue === "Asiagreen" ? (
          <>
            <Line
              type="monotone"
              dataKey={"msb1"}
              stroke="#FF5668"
              dot={false}
            />
            <Line
              type="monotone"
              dataKey={"msb2"}
              stroke="#82ca9d"
              dot={false}
            />
          </>
        ) : (
          <Line
            type="monotone"
            dataKey={selectedValue}
            stroke="#8884d8"
            dot={false}
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
}

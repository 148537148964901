import moment from "moment";

export function convertUnixToJs(unixTimestamp) {
  return moment.unix(unixTimestamp).format("h:mm:ss a");
}
export function convertUnixToJsWithDate(unixTimestamp) {
  return moment.unix(unixTimestamp).format("dddd, MMMM Do YYYY, h:mm:ss a");
}

export function addTimeToDate(date, duration) {
  const newDate = moment(date);
  newDate.add(duration.toString(), "m").toDate();
  return newDate.format("YYYY MMMM DD HH:mm");
}

export function getPastTimeIntervals(hours) {
  const now = moment();
  const startTime = now.clone().subtract(hours, "hours");
  const roundedStartTime = startTime
    .clone()
    .startOf("minute")
    .add(Math.floor(startTime.seconds() / 30) * 30, "seconds");

  const intervals = [];

  while (roundedStartTime <= now) {
    intervals.push({ batch: roundedStartTime.unix() });
    roundedStartTime.add(30, "seconds");
  }

  return intervals;
}

export function processTimestamps(data, key) {
  let timeIntervals = getPastTimeIntervals(6);
  const roundedData = data.map((item) => {
    const roundedTimestamp = Math.floor(item.batch / 30) * 30;
    return { ...item, batch: roundedTimestamp };
  });
  roundedData.map((indivData) => {
    const pos = timeIntervals.map((e) => e.batch).indexOf(indivData.batch);
    if (pos !== -1) {
      const objToAdd = {};
      objToAdd.batch = timeIntervals[pos].batch;
      objToAdd[key] = indivData[key];
      timeIntervals[pos] = objToAdd;
    }
  });
  return timeIntervals;
}

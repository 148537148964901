import React, { useEffect, useRef } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function CustomChart(props) {
  const { lineChartData, selectedValue } = props;
  const totalNumData = lineChartData.length;
  let xAxisTime = [
    lineChartData[0]["jsTime"],
    lineChartData[Math.ceil(totalNumData / 6)]["jsTime"],
    lineChartData[Math.ceil((2 * totalNumData) / 6)]["jsTime"],
    lineChartData[Math.ceil((3 * totalNumData) / 6)]["jsTime"],
    lineChartData[Math.ceil((4 * totalNumData) / 6)]["jsTime"],
    lineChartData[Math.ceil((5 * totalNumData) / 6)]["jsTime"],
    lineChartData[totalNumData - 1]["jsTime"],
  ];

  const lineKeys = Object.keys(lineChartData[0]).filter(key => key !== "jsTime");
  const brightColors = [
    "#FF5733", "#5733FF", "#FFD700", "#FF33A1", "#33A1FF",
    "#A1FF33", "#FF8C33", "#8C33FF", "#FF4500", "#8CFF33"
  ];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={lineChartData}
        margin={{
          top: 5,
          right: 55,
          left: 45,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="jsTime" ticks={xAxisTime} />
        <YAxis unit={"kW"} />

        <Tooltip />
        <Legend />
        {lineKeys.map((key, index) => (
          <Line
            key={index}
            type="monotone"
            dataKey={key}
            stroke={ key === "total" ? "#000000" : brightColors[index % brightColors.length]}
            dot={false}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
}

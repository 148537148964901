import React, { Fragment, useState, useEffect, useCallback } from "react";
import { push } from "connected-react-router";
import moment from "moment";
import { Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import MUIDataTable from "mui-datatables";

import getQueryString from "./utils.js";

import { fetchBids } from "../../actions/bid.js";

import s from "./Tables.module.scss";
import CustomToolbar from "./components/CustomToolbar.js";

const Bids = (props) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.bids);

  const [dataOptions, setDataOptions] = useState("");
  const [sortColumns, setSortColumns] = useState([]);

  const refetchData = useCallback(
    (dataOptions) => {
      dispatch(fetchBids(dataOptions));
    },
    [dispatch]
  );

  useEffect(() => {
    refetchData(dataOptions);
  }, [dataOptions, refetchData]);

  const handleAddItem = () => {
    dispatch(push(`/resources/bids/add`));
  };

  const columns = [
    // {
    //   label: "OFFER ID",
    //   name: 'id',
    //   options: {
    //     filter: false,
    //   }
    // },
    {
      label: "TITLE",
      name: "facility_name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "bid_date",
      label: "BID DATE",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
            "DD MMM YYYY"
          );
        },
      },
    },
    {
      label: "PERIOD",
      name: "period",
      options: {
        filter: false,
        sort: true,
      },
    },
    // {
    //   name: "emc_offer_batch_number",
    //   label: "OFFER BATCH NUMBER",
    //   options: {
    //     filter: false
    //   }
    // },
    {
      name: "bid",
      label: "BID OBJECT",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let returnStrings = [];
          if (value) {
            for (let index = 0; index < value.length; index++) {
              const element = value[index];
              if (element.q > 0) {
                returnStrings.push(`- ${element.q}MW for ${element.p}$/MWh`);
              }
            }
          }

          return (
            <div>
              {returnStrings.map((sentence, index) => (
                <div key={index}>{sentence}</div>
              ))}
            </div>
          );
        },
      },
    },
    {
      name: "bid_status",
      label: "STATUS",
      options: {
        filter: true,
        sort: true,
        filterType: "checkbox",
        filterOptions: {
          names: [
            "pending",
            "validated",
            "accepted",
            "approved",
            "rejected",
            "not-accepted",
            "cleared",
            "not-cleared",
          ],
        },
        customFilterListOptions: {
          render: (v) => {
            if (v.length > 0) {
              return `STATUS: ${v}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
      },
    },
    {
      label: "CLEARED AMOUNT (MW)",
      name: "taken_amount",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value && value > 0 ? value : "-";
        },
      },
    },
    {
      name: "ACTIONS",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div className={`d-flex justify-content`}>
              <Button
                className="btn-rounded mr-4 mb-3"
                color="info"
                size="xs"
                onClick={() =>
                  dispatch(push(`/resources/bids/${store.bids[dataIndex].id}`))
                }
              >
                View
              </Button>
              {/* <Button
                className="btn-rounded mr-4 mb-3"
                color="success"
                size="xs"
                onClick={() => itemSelected(store.offers[dataIndex].id)}
              >
                Edit
              </Button> */}
              {/* <Button
                className="btn-rounded mr-4 mb-3"
                color="danger"
                size="xs"
                onClick={() => dispatch(removeOffer(store.offers[dataIndex].id))}
              >
                Delete
              </Button> */}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    search: false,
    filterType: "checkbox",
    count: store.total_bids,
    serverSide: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          setDataOptions(getQueryString(tableState, sortColumns));
          break;
        case "sort":
          if (
            sortColumns.filter(
              (sort) => sort.name === tableState.sortOrder.name
            ).length > 0
          ) {
            // update the existing sortColumn
            let index = sortColumns.findIndex(
              (sort) => sort.name === tableState.sortOrder.name
            );
            sortColumns[index] = {
              name: tableState.sortOrder.name,
              direction: tableState.sortOrder.direction,
            };

            setSortColumns(sortColumns);
          } else {
            // add a new sortColumn
            sortColumns.push({
              name: tableState.sortOrder.name,
              direction: tableState.sortOrder.direction,
            });

            setSortColumns(sortColumns);
          }

          setDataOptions(getQueryString(tableState, sortColumns));
          break;
        case "filterChange":
          console.log(tableState, sortColumns);
          setDataOptions(getQueryString(tableState, sortColumns));
          break;
        case "changeRowsPerPage":
          setDataOptions(getQueryString(tableState, sortColumns));
          break;
        default:
          console.log(action, " is not handled.");
      }
    },
    customToolbar: () => {
      return <CustomToolbar action={handleAddItem} />;
    },
  };

  return (
    <Fragment>
      <div>
        <MUIDataTable
          title={`Bids ${sortColumns.length > 0 ? " (sorted by : " + sortColumns.map((col) => " " + col.name + " " + col.direction + " ") + ")" : ""}`}
          data={store.bids}
          columns={columns}
          options={options}
        />
      </div>
    </Fragment>
  );
};

export default Bids;

import axios from "axios";

export const fetchMaintenanceMessages = () => {
  return (dispatch) => {
    axios.get("/maintenance_messages").then((response) => {
      dispatch({
        type: "FETCH_MESSAGES",
        messages: response.data,
      });
    });
  };
};

export const addMaintenanceMessage = (message) => {
  return (dispatch, getState) => {
    axios.post("/maintenance_messages/new", message).then(() => {
      dispatch({
        type: "ADD_MESSAGE",
      });
      dispatch(fetchMaintenanceMessages());
    });
  };
};

export const updateMaintenanceMessage = (message) => {
  return (dispatch) => {
    axios.put("/maintenance_messages/" + message.id, message).then(() => {
      dispatch({
        type: "UPDATE_MESSAGE",
      });
      dispatch(fetchMaintenanceMessages());
    });
  };
};

export const removeMaintenanceMessage = (id) => {
  return (dispatch) => {
    axios.delete("/maintenance_messages/" + id).then(() => {
      dispatch({
        type: "REMOVE_MESSAGE",
      });
      dispatch(fetchMaintenanceMessages());
    });
  };
};

export const selectMaintenanceMessage = (message) => {
  return (dispatch) => {
    dispatch({
      type: "SELECT_MESSAGE",
      message,
    });
  };
};

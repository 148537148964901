import { useEffect, useRef, memo } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactinPlugin from "@fullcalendar/interaction";

import { Card, CardBody } from "reactstrap";

const CalendarBody = (props) => {
  const calendarRef = useRef(null);
  const {
    store,
    bidStore,
    dispatch,
    calendarApi,
    setCalendarApi,
    calendarsColor,
    blankEvent,
    selectOfferEvent,
    selectBidEvent,
    updateEvent,
    handleAddEventSidebar,
  } = props;

  // useEffect(() => {
  //   if (calendarApi === null) {
  //     setCalendarApi(calendarRef.current.getApi())
  //   }
  // }, [calendarApi])

  const allOffers = store.all_offers.length ? store.all_offers : [];
  const allBids = bidStore.all_bids.length ? bidStore.all_bids : [];

  const getEventsToShow = () => {
    const offers = allOffers.length
      ? allOffers.filter(
          (offer) =>
            store.selectedStatus.includes(offer.offer_status) &&
            (offer.submission === null || offer.submission === 0)
        )
      : [];
    const bids = allBids.length
      ? allBids.filter(
          (bid) =>
            bidStore.selectedStatus.includes(bid.bid_status) &&
            (bid.submission === null || bid.submission === 0)
        )
      : [];

    return [...offers, ...bids];
  };

  const calendarOptions = {
    events: getEventsToShow(),
    plugins: [interactinPlugin, dayGridPlugin, timeGridPlugin],
    initialValue: "dayGridMonth",
    headerToolbar: {
      start: " prev, next, title",
      end: "sidebarToggle, dayGridMonth, timeGridWeek, timeGridDay",
    },
    // editable: true,
    // eventResizableFromStart: true,
    // dragScroll: true,
    dayMaxEvents: 2,
    // navLinks: true,
    // selectable: true,
    timeZone: "Asia/Singapore",
    nowIndicator: true,
    nowIndicatorPosition: "bottom",
    nowIndicatorColor: "#000",

    eventClassNames({ event: calendarEvent }) {
      if (calendarEvent._def.extendedProps.eventType === "offer") {
        return [
          `bg-light-${
            calendarsColor[calendarEvent._def.extendedProps.offer_status]
          }`,
        ];
      } else {
        return [
          `bg-light-${
            calendarsColor[calendarEvent._def.extendedProps.bid_status]
          }`,
        ];
      }
    },

    eventClick({ event: clickedEvent }) {
      if (clickedEvent._def.extendedProps.eventType === "offer") {
        dispatch(
          selectOfferEvent(
            store.all_offers.filter(
              (offer) => offer.id === parseInt(clickedEvent.id)
            )[0]
          )
        );
      } else {
        dispatch(
          selectBidEvent(
            bidStore.all_bids.filter(
              (bid) => bid.id === parseInt(clickedEvent.id)
            )[0]
          )
        );
      }

      handleAddEventSidebar();
    },
    ref: calendarRef,
  };

  return (
    <Card className="shadow-none border-0 mb-0">
      <CardBody className="pb-0">
        <FullCalendar {...calendarOptions} />{" "}
      </CardBody>
    </Card>
  );
};

export default memo(CalendarBody);

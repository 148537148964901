import React, { Fragment, useState } from "react";

import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import Select, { components } from "react-select";
import { useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  CustomInput,
  Input,
  Form,
} from "reactstrap";

import "eva-icons/style/eva-icons.css";

const AddEventSidebar = (props) => {
  const {
    store,
    dispatch,
    open,
    handleAddMessage,
    refetchMessages,
    addMessage,
    selectMessage,
    updateMessage,
  } = props;

  const selectedMessage = store.selectedMessage;
  const { register, errors, handleSubmit } = useForm();

  const [note, setNote] = useState("");
  const [facility_id, setFacilityID] = useState("");
  const [building_id, setBuildingID] = useState("");
  const [group_id, setGroupID] = useState("");
  const [unit_id, setUnitID] = useState("");
  const [assignedDate, setAssignedDate] = useState(new Date());
  const [assignedTo, setAssignedTo] = useState("");

  const isObjEmpty = (obj) => Object.keys(obj).length === 0;

  const handleAddEvent = () => {
    const obj = {
      note,
      facility_id,
      building_id,
      group_id,
      unit_id,
      assignedDate,
      assignedTo,
    };
    dispatch(addMessage(obj));
    refetchMessages();
    handleAddMessage();
  };

  const handleSelectedEvent = () => {
    if (!isObjEmpty(selectedMessage)) {
      setNote(selectedMessage.note || note);
      setFacilityID(selectedMessage.facility_id || facility_id);
      setBuildingID(selectedMessage.building_id || building_id);
      setGroupID(selectedMessage.group_id || group_id);
      setUnitID(selectedMessage.unit_id || unit_id);
      setAssignedDate(selectedMessage.assignedDate || assignedDate);
      setAssignedTo(selectedMessage.assignedTo || assignedTo);
    }
  };

  const handleResetInputValues = () => {
    dispatch(selectMessage({}));

    setNote("");
    setFacilityID("");
    setBuildingID("");
    setGroupID("");
    setUnitID("");
    setAssignedDate("");
    setAssignedTo("");
  };

  const handleUpdateEvent = () => {
    const messageToUpdate = {
      id: selectedMessage.id,
      note,
      facility_id: facility_id !== "" ? facility_id : null,
      building_id: building_id !== "" ? building_id : null,
      group_id: group_id !== "" ? group_id : null,
      unit_id: unit_id !== "" ? unit_id : null,
      assignedDate,
      assignedTo,
    };

    dispatch(updateMessage(messageToUpdate));
    handleAddMessage();
  };

  const EventActions = () => {
    console.log(selectedMessage);
    if (isObjEmpty(selectedMessage)) {
      return (
        <Fragment>
          <Button className="mr-3 btn-rounded" type="submit" color="primary">
            Add
          </Button>
          <Button
            className="btn-rounded"
            color="secondary"
            type="reset"
            onClick={handleAddMessage}
            outline
          >
            Cancel
          </Button>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Button
            className="mr-3 btn-rounded"
            color="primary"
            onClick={handleUpdateEvent}
          >
            Update
          </Button>
          <Button
            className="btn-rounded"
            color="secondary"
            type="reset"
            onClick={handleAddMessage}
            outline
          >
            Cancel
          </Button>
        </Fragment>
      );
    }
  };

  const CloseBtn = (
    <i className="eva eva-close cursor-pointer" onClick={handleAddMessage} />
  );

  return (
    <Modal
      isOpen={open}
      toggle={handleAddMessage}
      onOpened={handleSelectedEvent}
      onClosed={handleResetInputValues}
      className="sidebar-lg"
      contentClassName="p-0"
      modalClassName="modal-slide-in event-sidebar"
    >
      <ModalHeader
        className="mb-1"
        toggle={handleAddMessage}
        close={CloseBtn}
        tag="div"
      >
        <h5 className="modal-title">
          {selectedMessage && selectedMessage.id ? "Update" : "Add"} Maintenance
          Message
        </h5>
      </ModalHeader>
      <ModalBody className="flex-grow-1 pb-sm-0 pb-3">
        <Form
          onSubmit={handleSubmit((data) => {
            if (isObjEmpty(errors)) {
              if (
                isObjEmpty(selectedMessage) ||
                (!isObjEmpty(selectedMessage) && !selectedMessage.title.length)
              ) {
                handleAddEvent();
              } else {
                handleUpdateEvent();
              }
              handleAddMessage();
            }
          })}
        >
          <FormGroup>
            <Label for="note">Note</Label>
            <Input
              id="note"
              name="note"
              placeholder="Maintenance message"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              innerRef={register({
                register: true,
                validate: (value) => value !== "",
              })}
              className={classnames({
                "is-invalid": errors.note,
                // add extended bootstrap class for show invalid field value
              })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="facility_id">Facility ID</Label>
            <Input
              id="facility_id"
              name="facility_id"
              placeholder="18"
              value={facility_id}
              onChange={(e) => setFacilityID(e.target.value)}
              innerRef={register({
                register: true,
                validate: (value) => value !== "",
              })}
              className={classnames({
                "is-invalid": errors.facility_id,
                // add extended bootstrap class for show invalid field value
              })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="building_id">Building ID</Label>
            <Input
              id="building_id"
              name="building_id"
              placeholder="18"
              value={building_id}
              onChange={(e) => setBuildingID(e.target.value)}
              innerRef={register({
                register: true,
                validate: (value) => value !== "",
              })}
              className={classnames({
                "is-invalid": errors.building_id,
                // add extended bootstrap class for show invalid field value
              })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="group_id">Group ID</Label>
            <Input
              id="group_id"
              name="group_id"
              placeholder="18"
              value={group_id}
              onChange={(e) => setGroupID(e.target.value)}
              innerRef={register({
                register: true,
                validate: (value) => value !== "",
              })}
              className={classnames({
                "is-invalid": errors.group_id,
                // add extended bootstrap class for show invalid field value
              })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="unit_id">Unit ID</Label>
            <Input
              id="unit_id"
              name="unit_id"
              placeholder="18"
              value={unit_id}
              onChange={(e) => setUnitID(e.target.value)}
              innerRef={register({
                register: true,
                validate: (value) => value !== "",
              })}
              className={classnames({
                "is-invalid": errors.unit_id,
                // add extended bootstrap class for show invalid field value
              })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="assignedDate">Assigned Date</Label>
            <Flatpickr
              required
              id="assignedDate"
              name="assignedDate"
              className="form-control"
              onChange={(date) => {
                setAssignedDate(date[0]);
              }}
              value={assignedDate}
              options={{
                enableTime: false,
                time_24hr: true,
                // dateformat: 'Y-m-d H:i'
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="assignedTo">Assigned To</Label>
            <Input
              id="assignedTo"
              name="assignedTo"
              placeholder="Soe Thet"
              value={assignedTo}
              onChange={(e) => setAssignedTo(e.target.value)}
              innerRef={register({
                register: true,
                validate: (value) => value !== "",
              })}
              className={classnames({
                "is-invalid": errors.assignedTo,
                // add extended bootstrap class for show invalid field value
              })}
            />
          </FormGroup>
          <FormGroup className="d-flex">
            <EventActions />
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default AddEventSidebar;

import React from "react";
import Loader from "../../Loader/Loader";
import TextViewItem from "../../FormItems/items/TextViewItem";
import ImagesViewItem from "../../FormItems/items/ImagesViewItem";
import Widget from "../../Widget/Widget";

const OfferView = (props) => {
  const { record } = props;

  if (!record) {
    return <Loader />;
  }

  return (
    <Widget className="widget-p-md">
      <div className="headline-2 mb-4">Offer</div>

      <TextViewItem label={"Start Time"} value={record.start} />

      <TextViewItem label={"End Time"} value={record.end} />

      <TextViewItem label={"Phone number"} value={record.phoneNumber} />

      <TextViewItem label={"Email"} value={record.email} />

      <TextViewItem label={"Disabled"} value={record.disabled} />
    </Widget>
  );
};

export default OfferView;

export default [
  [1167609600000, 0.7537],
  [1167696000000, 0.7537],
  [1167782400000, 0.7559],
  [1167868800000, 0.7631],
  [1167955200000, 0.7644],
  [1168214400000, 0.769],
  [1168300800000, 0.7683],
  [1168387200000, 0.77],
  [1168473600000, 0.7703],
  [1168560000000, 0.7757],
  [1168819200000, 0.7728],
  [1168905600000, 0.7721],
  [1168992000000, 0.7748],
  [1169078400000, 0.774],
  [1169164800000, 0.7718],
  [1169424000000, 0.7731],
  [1169510400000, 0.767],
  [1169596800000, 0.769],
  [1169683200000, 0.7706],
  [1169769600000, 0.7752],
  [1170028800000, 0.774],
  [1170115200000, 0.771],
  [1170201600000, 0.7721],
  [1170288000000, 0.7681],
  [1170374400000, 0.7681],
  [1170633600000, 0.7738],
  [1170720000000, 0.772],
  [1170806400000, 0.7701],
  [1170892800000, 0.7699],
  [1170979200000, 0.7689],
  [1171238400000, 0.7719],
  [1171324800000, 0.768],
  [1171411200000, 0.7645],
  [1171497600000, 0.7613],
  [1171584000000, 0.7624],
  [1171843200000, 0.7616],
  [1171929600000, 0.7608],
  [1172016000000, 0.7608],
  [1172102400000, 0.7631],
  [1172188800000, 0.7615],
  [1172448000000, 0.76],
  [1172534400000, 0.756],
  [1172620800000, 0.757],
  [1172707200000, 0.7562],
  [1172793600000, 0.7598],
  [1173052800000, 0.7645],
  [1173139200000, 0.7635],
  [1173225600000, 0.7614],
  [1173312000000, 0.7604],
  [1173398400000, 0.7603],
  [1173657600000, 0.7602],
  [1173744000000, 0.7566],
  [1173830400000, 0.7587],
  [1173916800000, 0.7562],
  [1174003200000, 0.7506],
  [1174262400000, 0.7518],
  [1174348800000, 0.7522],
  [1174435200000, 0.7524],
  [1174521600000, 0.7491],
  [1174608000000, 0.7505],
  [1174867200000, 0.754],
  [1174953600000, 0.7493],
  [1175040000000, 0.7493],
  [1175126400000, 0.7491],
  [1175212800000, 0.751],
  [1175472000000, 0.7483],
  [1175558400000, 0.7487],
  [1175644800000, 0.7491],
  [1175731200000, 0.7479],
  [1175817600000, 0.7479],
  [1176076800000, 0.7479],
  [1176163200000, 0.7449],
  [1176249600000, 0.7454],
  [1176336000000, 0.7427],
  [1176422400000, 0.7391],
  [1176681600000, 0.7381],
  [1176768000000, 0.7382],
  [1176854400000, 0.7366],
  [1176940800000, 0.7353],
  [1177027200000, 0.7351],
  [1177286400000, 0.7377],
  [1177372800000, 0.7364],
  [1177459200000, 0.7328],
  [1177545600000, 0.7356],
  [1177632000000, 0.7331],
  [1177891200000, 0.7351],
  [1177977600000, 0.7351],
  [1178064000000, 0.736],
  [1178150400000, 0.7347],
  [1178236800000, 0.7375],
  [1178496000000, 0.7346],
  [1178582400000, 0.7377],
  [1178668800000, 0.7389],
  [1178755200000, 0.7394],
  [1178841600000, 0.7416],
  [1179100800000, 0.7382],
  [1179187200000, 0.7388],
  [1179273600000, 0.7368],
  [1179360000000, 0.74],
  [1179446400000, 0.7421],
  [1179705600000, 0.7439],
  [1179792000000, 0.7434],
  [1179878400000, 0.7414],
  [1179964800000, 0.7437],
  [1180051200000, 0.7441],
  [1180310400000, 0.7434],
  [1180396800000, 0.7403],
  [1180483200000, 0.7453],
  [1180569600000, 0.7434],
  [1180656000000, 0.7444],
  [1180915200000, 0.7418],
  [1181001600000, 0.7391],
  [1181088000000, 0.7401],
  [1181174400000, 0.7425],
  [1181260800000, 0.7492],
  [1181520000000, 0.7489],
  [1181606400000, 0.7494],
  [1181692800000, 0.7527],
  [1181779200000, 0.7518],
  [1181865600000, 0.7512],
  [1182124800000, 0.7461],
  [1182211200000, 0.7462],
  [1182297600000, 0.7449],
  [1182384000000, 0.7465],
  [1182470400000, 0.7441],
  [1182729600000, 0.743],
  [1182816000000, 0.743],
  [1182902400000, 0.7443],
  [1182988800000, 0.7427],
];
